import React, { FC } from "react";
import styles from "./redactionSection.module.scss";
import textOriginalOff from "../../../../../../../assets/icons/drawerTeacherInteligent/textOriginalOff.png";
import textOriginalOn from "../../../../../../../assets/icons/drawerTeacherInteligent/textOriginalOn.png";
import editTextOff from "../../../../../../../assets/icons/drawerTeacherInteligent/editTextOff.png";
import editTextOn from "../../../../../../../assets/icons/drawerTeacherInteligent/editTextOn.png";
// import fixedFastOff from "../../../../../../../assets/icons/drawerTeacherInteligent/fixedFastOff.png";
// import fixedFastOn from "../../../../../../../assets/icons/drawerTeacherInteligent/fixedFastOn.png";

interface RedactionProps {
  stateTab: string;
  setStateTab: (e: string) => void;
  isEnglish: boolean | undefined | null;
}
const RedactionSection: FC<RedactionProps> = ({
  stateTab,
  setStateTab,
  isEnglish,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div
          onClick={() => setStateTab("originalText")}
          className={stateTab === "originalText" ? styles.tabOn : styles.tab}
        >
          {stateTab === "originalText" ? (
            <img src={textOriginalOn} alt="" />
          ) : (
            <img src={textOriginalOff} alt="" />
          )}
          <div
            className={
              stateTab === "originalText" ? styles.textTabOn : styles.textTab
            }
          >
            {isEnglish ? "Original text" : " Texto original"}
          </div>
        </div>

        {/* dejar comentado porque se va a usar  */}
        {/* <div  
          onClick={() => setStateTab("fixedFast")}
          className={stateTab === "fixedFast" ? styles.tabOn : styles.tab}
        >
          {stateTab === "fixedFast" ? (
            <img src={fixedFastOn} alt="" />
          ) : (
            <img src={fixedFastOff} alt="" />
          )}
          <div
            className={
              stateTab === "fixedFast" ? styles.textTabOn : styles.textTab
            }
          >
            Corrección rápida
          </div>
        </div> */}
        <div
          className={stateTab === "editText" ? styles.tabOn : styles.tab}
          onClick={() => setStateTab("editText")}
        >
          {stateTab === "editText" ? (
            <img src={editTextOn} alt="" />
          ) : (
            <img src={editTextOff} alt="" />
          )}
          <div
            className={
              stateTab === "editText" ? styles.textTabOn : styles.textTab
            }
          >
            Editor texto
          </div>
        </div>
      </div>
    </div>
  );
};

export default RedactionSection;
