import React, { FC } from "react";
import styles from "./mainInteligentStudent.module.scss";
import bigLogoRubicas from "../../../../../assets/icons/bigLogoRubicas.png";
import { Tabs } from "antd";
import { EditorComponent } from "../../../../Shared/EditorComponent";
import { getRedactionCorrection_getRedactionCorrection } from "../../../../../__generated__/gql-types/getRedactionCorrection";
import { getLessonById_getLessonById } from "../../../../../__generated__/gql-types/getLessonById";
import { Rubrics } from "./Rubrics";
import { ContWords } from "../../../../SingleClass/DetailsHomeworkDrawer/StudentsStatusTable/DrawerInteligentTeacher/MainInteligentTeacher/ContWords";
const { TabPane } = Tabs;

interface PropsMain {
  evaluateStudent: getRedactionCorrection_getRedactionCorrection | null;
  individualLesson: getLessonById_getLessonById | null;
}

const MainInteligentTeacher: FC<PropsMain> = ({
  individualLesson,
  evaluateStudent,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.containerInfo}>
        <div className={styles.responseStudent}>
          <Tabs defaultActiveKey="1" centered className={"tabStyles"}>
            <TabPane tab="Tu respuesta" key="1">
              {evaluateStudent?.redaction_response && (
                <>
                  <EditorComponent
                    text={
                      typeof evaluateStudent?.note === "number" &&
                      evaluateStudent.redaction_gpt_response_correction
                        ? evaluateStudent.redaction_gpt_response_correction
                        : evaluateStudent.redaction_response
                    }
                    readOnly={true}
                  />
                  <ContWords
                    text={
                      typeof evaluateStudent?.note === "number" &&
                      evaluateStudent.redaction_gpt_response_correction
                        ? evaluateStudent.redaction_gpt_response_correction
                        : evaluateStudent.redaction_response
                    }
                  />
                </>
              )}
            </TabPane>
            {individualLesson?.lesson_content?.redaction?.question && (
              <TabPane tab="Tarea" key="2">
                <EditorComponent
                  text={individualLesson?.lesson_content?.redaction?.question}
                  readOnly={true}
                />
                <ContWords
                  text={individualLesson?.lesson_content?.redaction?.question}
                />
              </TabPane>
            )}
          </Tabs>
        </div>
        <div className={styles.qualificationStudent}>
          <div className={styles.containerRubricas}>
            {typeof evaluateStudent?.note !== "number" ? (
              <div className={styles.containerWithOutNote}>
                <img
                  src={bigLogoRubicas}
                  alt=""
                  className={styles.imgWithOutNote}
                />
                <div className={styles.titleWithOutNote}>
                  Tu tarea está siendo revisada. ¡Te avisaremos en la pantalla
                  principal cuando esté lista!{" "}
                </div>
              </div>
            ) : (
              <>
                <div className={styles.containerQualificationStudent}>
                  <div>Calificación</div>
                  <div>{evaluateStudent?.note ?? 0}</div>
                </div>
                <Rubrics evaluateStudent={evaluateStudent} />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainInteligentTeacher;
