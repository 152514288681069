import React, { FC } from "react";
import { getLessonById_getLessonById_lesson_content_quizzes_questions } from "../../../../../../__generated__/gql-types/getLessonById";
import styles from "./fill.module.scss";
interface PropsFill {
  index: number;
  subject_id?: number | null;
  question: getLessonById_getLessonById_lesson_content_quizzes_questions;
}
const Fill: FC<PropsFill> = ({ index, question, subject_id }) => {
  const partes = question.question?.split("__");

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.title}>
          {index + 1}.{" "}
          {subject_id === 16
            ? "Write the missing word(s)."
            : "Escribe la palabra que falta para completar la frase."}
        </div>
        {question.url && (
          <div className={styles.containerImage}>
            <img
              className={styles.imgQuestions}
              src={question.url ?? ""}
              alt="asdas"
            />
          </div>
        )}
        <div className={styles.textFill}>
          {partes![0]}
          <span
            style={{
              background: "#8DCE4F",
              textAlign: "center",
              borderRadius: "4px",
              minWidth: "68px",
              lineHeight: "30px",
              padding: "2px 8px",
            }}
          >
            {question.answers![0].answer}
          </span>

          {partes![1]}
        </div>
      </div>
    </div>
  );
};

export default Fill;
