import React, { FC, useEffect, useRef, useState } from "react";
import styles from "./orderFrase.module.scss";
import alertFalseImg from "../../../../assets/pages/quiz/alertFalseImg.png";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { Column, PropsOrderFrase, Task } from "./utils";

const OrderFrase: FC<PropsOrderFrase> = ({
  setStateColumns,
  stateColumns,
  stateNext,
  alertFalse,
  alertTrue,
  content,
  subject,
}) => {
  const draggableRefs = useRef<(HTMLDivElement | null)[]>([]);
  const firstColumn = useRef<(HTMLDivElement | null)[]>([]);
  const [contPixels, setContPixels] = useState(0);
  const [secondColumn, setSecondColumn] = useState(false);
  const english = subject && subject === 16 ? true : false;

  const taskStyles = (task: Task, index: number) => {
    const contAnswer = Object.getOwnPropertyNames(stateColumns?.tasks).length;

    const tasks = stateColumns?.columns[`column-${contAnswer + 1}`].taskIds
      ?.map((taskId) => stateColumns.tasks[taskId])
      .concat(
        stateColumns?.columns[`column-${contAnswer + 2}`].taskIds?.map(
          (taskId) => stateColumns.tasks[taskId]
        )
      );

    if (tasks) {
      let sortedTasks = [...tasks];

      // Ordenar la copia
      sortedTasks.sort((a, b) => a.order - b.order);

      const indexTask = tasks.findIndex(
        (taskToIndex) => taskToIndex.order === task.order
      );

      return stateNext
        ? indexTask === tasks[indexTask].order
          ? styles.taskContainerTrue
          : styles.taskContainerFalse
        : styles.taskContainer;
    } else {
      return styles.taskContainer;
    }
  };

  const onDragEnd = (result: DropResult) => {
    if (stateColumns) {
      const { destination, source, draggableId } = result;

      if (
        (destination?.droppableId === source.droppableId &&
          destination?.index === source.index) ||
        !destination
      ) {
        return;
      }

      const start = stateColumns.columns[source.droppableId]; //start : columna de donde sacas el elemento
      const finish = stateColumns.columns[destination.droppableId];

      if (start === finish) {
        const newTaskIds = Array.from(start.taskIds);
        newTaskIds.splice(source.index, 1);
        newTaskIds.splice(destination.index, 0, draggableId);

        const newColumn = {
          ...start,
          taskIds: newTaskIds,
        };

        const newState = {
          ...stateColumns,
          columns: {
            ...stateColumns.columns,
            [newColumn.id]: newColumn,
          },
        };

        setStateColumns(newState);
        return;
      }

      // Moving from one column to another
      const startTaskIds = Array.from(start.taskIds);
      const finishTaskIds = Array.from(finish.taskIds);
      startTaskIds.splice(source.index, 1);

      if (
        start.id === `column-${stateColumns.columnOrder.length - 1}` &&
        finish.id === `column-${stateColumns.columnOrder.length}` //ESTOY TRANAJANDO ACA
      ) {
        finishTaskIds.splice(destination.index, 0, draggableId);

        if (finishTaskIds.length < 2) {
          return;
        } else {
          const newFinish = {
            ...finish,
            taskIds: finishTaskIds,
          };

          let firstElement = finishTaskIds.shift();
          const localStartTaskId = firstElement
            ? [...startTaskIds, firstElement]
            : startTaskIds;
          const newStart = {
            ...start,
            taskIds: localStartTaskId,
          };

          const newState = {
            ...stateColumns,
            columns: {
              ...stateColumns.columns,
              [newStart.id]: newStart,
              [newFinish.id]: newFinish,
            },
          };

          setStateColumns(newState);
          return;
        }
      }

      if (
        finishTaskIds.length > 0 && // If there's already a task in the destination column, move it to the start column
        !(finish.id === `column-${stateColumns.columnOrder.length - 1}`) &&
        !(finish.id === `column-${stateColumns.columnOrder.length}`)
      ) {
        const taskToMove = finishTaskIds[0]; // Assuming we are moving the first task
        finishTaskIds.splice(0, 1);
        startTaskIds.splice(source.index, 0, taskToMove);
      }

      if (
        finish.id === `column-${stateColumns.columnOrder.length}` &&
        start.id !== `column-${stateColumns.columnOrder.length - 1}`
      ) {
        let contFirstColum = 0;
        firstColumn.current.forEach((ref) => {
          if (ref) {
            contFirstColum = contFirstColum + ref.offsetWidth;
          }
        });
        const totalScreen = window.innerWidth * 0.85;
        const totalTask =
          stateColumns && firstColumn.current.length
            ? firstColumn.current.length
            : 1;
        const widthTaskWithPadding = contFirstColum + 16 + 16 * (totalTask - 1);

        if (totalScreen - widthTaskWithPadding > 140) {
          const localFinish =
            stateColumns.columns[
              `column-${stateColumns.columnOrder.length - 1}`
            ];
          const localFinishTaskIds = Array.from(localFinish.taskIds);
          localFinishTaskIds.splice(localFinish.taskIds.length, 0, draggableId);
          const newFinish = {
            ...localFinish,
            taskIds: localFinishTaskIds,
          };
          const newStart = {
            ...start,
            taskIds: startTaskIds,
          };

          const newState = {
            ...stateColumns,
            columns: {
              ...stateColumns.columns,
              [newStart.id]: newStart,
              [newFinish.id]: newFinish,
            },
          };

          setStateColumns(newState);
          return;
        }
      }
      finishTaskIds.splice(destination.index, 0, draggableId);

      const newFinish = {
        ...finish,
        taskIds: finishTaskIds,
      };
      const newStart = {
        ...start,
        taskIds: startTaskIds,
      };

      const newState = {
        ...stateColumns,
        columns: {
          ...stateColumns.columns,
          [newStart.id]: newStart,
          [newFinish.id]: newFinish,
        },
      };

      setStateColumns(newState);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      const totalScreen = window.innerWidth * 0.85;
      const totalTask =
        stateColumns && Object.keys(stateColumns?.tasks).length
          ? Object.keys(stateColumns?.tasks).length
          : 1;
      const widthTaskWithPadding = contPixels + 16 + 16 * (totalTask - 1);
      setSecondColumn(totalScreen < widthTaskWithPadding ? true : false);
    };
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [contPixels, stateColumns]);

  useEffect(() => {
    if (stateColumns && contPixels === 0) {
      let localContPixels = 0;
      draggableRefs.current.forEach((ref, index) => {
        if (ref) {
          localContPixels = localContPixels + ref.offsetWidth;
        }
      });

      setContPixels(localContPixels);
    }
  }, [stateColumns]);

  useEffect(() => {
    let contFirstColum = 0;

    if (stateColumns) {
      firstColumn.current.forEach((ref) => {
        if (ref) {
          contFirstColum = contFirstColum + ref.offsetWidth;
        }
      });

      const totalScreen = window.innerWidth * 0.85;
      const totalTask =
        stateColumns && firstColumn.current.length
          ? firstColumn.current.length
          : 1;
      const widthTaskWithPadding = contFirstColum + 16 + 16 * (totalTask - 1);

      if (totalScreen < widthTaskWithPadding) {
        const taskIdsColumn10 =
          stateColumns.columns[
            `column-${stateColumns.columnOrder.length - 1}`
          ].taskIds.pop();

        stateColumns.columns[
          `column-${stateColumns.columnOrder.length - 1}`
        ].taskIds.filter((e) => e !== taskIdsColumn10);

        if (taskIdsColumn10) {
          stateColumns.columns[
            `column-${stateColumns.columnOrder.length}`
          ].taskIds.push(taskIdsColumn10);
        }
        const newColumn = {
          ...stateColumns,
        };
        setStateColumns(newColumn);
      }
    }
  }, [stateColumns]);

  useEffect(() => {
    if (content?.answers) {
      const tasks: { [key: string]: Task } = content?.answers?.reduce(
        (acc, item, index) => {
          acc[`task-${index + 1}`] = {
            id: `task-${index + 1}`,
            order: item.order ?? 0,
            content: item.answer ?? "",
            idTask: item.id,
          };
          return acc;
        },
        {} as { [key: string]: Task }
      );
      let columns: { [key: string]: Column } = content?.answers?.reduce(
        (acc, item, index) => {
          acc[`column-${index + 1}`] = {
            taskIds: [`task-${index + 1}`],
            title: item.answer ?? "asd",
            id: `column-${index + 1}`,
          };
          return acc;
        },
        {} as { [key: string]: Column }
      );

      columns[`column-${content.answers.length + 1}`] = {
        taskIds: [],
        title: "la cola",
        id: `column-${content.answers.length + 1}`,
      };

      columns[`column-${content.answers.length + 2}`] = {
        taskIds: [],
        title: "lrerea",
        id: `column-${content.answers.length + 2}`,
      };

      let columnOrder = content.answers?.map((e, i) => {
        return `column-${i + 1}`;
      });
      columnOrder.push(`column-${content.answers.length + 1}`);
      columnOrder.push(`column-${content.answers.length + 2}`);

      setStateColumns({
        columnOrder: columnOrder,
        columns: columns,
        tasks: tasks,
      });
    }
  }, [content.answers, setStateColumns]);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="all-columns" direction="horizontal" type="column">
        {(provided) => (
          <div
            className={styles.container}
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {english ? (
              <div className={styles.title}>Build the sentence.</div>
            ) : (
              <div className={styles.title}>Ordena la frase</div>
            )}

            <div className={styles.containerLine}>
              {content.answers && stateColumns && (
                <div
                  className={styles.dropBig}
                  key={`column-${content.answers.length + 1}`}
                >
                  <Droppable
                    droppableId={`column-${content.answers.length + 1}`}
                    type="task"
                    direction="horizontal"
                  >
                    {(provided) => {
                      const column =
                        content.answers &&
                        stateColumns.columns[
                          `column-${content.answers.length + 1}`
                        ];

                      const tasks = column?.taskIds?.map(
                        (taskId) => stateColumns.tasks[taskId]
                      );
                      return (
                        <div
                          className={styles.classTitle}
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {tasks?.map((task, indexTask) => (
                            <Draggable
                              key={task.id}
                              draggableId={task.id}
                              isDragDisabled={stateNext}
                              index={indexTask}
                            >
                              {(provided) => (
                                <div
                                  className={taskStyles(task, indexTask)}
                                  ref={(el) => {
                                    firstColumn.current[indexTask] = el;
                                    provided.innerRef(el);
                                  }}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  {task.content}
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      );
                    }}
                  </Droppable>
                </div>
              )}
            </div>
            {secondColumn && (
              <div className={styles.containerLine}>
                {content.answers && stateColumns && (
                  <div
                    className={styles.dropBig}
                    key={`column-${content.answers.length + 2}`}
                  >
                    <Droppable
                      droppableId={`column-${content.answers.length + 2}`}
                      type="task"
                      direction="horizontal"
                    >
                      {(provided) => {
                        const column =
                          content.answers &&
                          stateColumns.columns[
                            `column-${content.answers.length + 2}`
                          ];

                        const tasks = column?.taskIds?.map(
                          (taskId) => stateColumns.tasks[taskId]
                        );

                        return (
                          <div
                            className={styles.classTitle}
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                          >
                            {tasks?.map((task, index) => (
                              <Draggable
                                key={task.id}
                                draggableId={task.id}
                                isDragDisabled={stateNext}
                                index={index}
                              >
                                {(provided) => {
                                  return (
                                    <div
                                      className={taskStyles(task, index)}
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      {task.content}
                                    </div>
                                  );
                                }}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        );
                      }}
                    </Droppable>
                  </div>
                )}
              </div>
            )}
            {alertFalse && (
              <div className={styles.containerAlert}>
                <img src={alertFalseImg} alt="alert" />
                <div className={styles.containerTextAlert}>
                  <div className={styles.titleAlert}>Respuesta correcta:</div>
                  <div className={styles.fraseAlert}>{content.question}</div>
                </div>
              </div>
            )}

            {((stateNext && alertTrue) || !stateNext) && (
              <div className={styles.containerFichas}>
                {stateColumns?.columnOrder.map((columnId, indexArray) => {
                  if (
                    content?.answers &&
                    columnId !== `column-${content?.answers.length + 1}` &&
                    columnId !== `column-${content?.answers.length + 2}`
                  ) {
                    const column = stateColumns.columns[columnId];
                    const tasks = column?.taskIds?.map(
                      (taskId) => stateColumns.tasks[taskId]
                    );

                    return (
                      <div className={styles.columnContainer} key={column.id}>
                        <Droppable droppableId={column.id} type="task">
                          {(provided) => (
                            <div
                              className={styles.classTitle}
                              ref={provided.innerRef}
                              {...provided.droppableProps}
                            >
                              {tasks.map((task, index) => {
                                return (
                                  <Draggable
                                    key={task.id}
                                    draggableId={task.id}
                                    index={index}
                                  >
                                    {(provided) => (
                                      <div
                                        className={styles.taskContainer}
                                        ref={(el) => {
                                          draggableRefs.current[indexArray] =
                                            el;
                                          provided.innerRef(el);
                                        }}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        {task.content}
                                      </div>
                                    )}
                                  </Draggable>
                                );
                              })}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </div>
                    );
                  } else {
                    return <></>;
                  }
                })}
              </div>
            )}

            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default OrderFrase;
