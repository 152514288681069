import React, { FC } from "react";
import { getLessonById_getLessonById_lesson_content_quizzes_questions } from "../../../../../../__generated__/gql-types/getLessonById";
import styles from "./questionAI.module.scss";
interface PropsFill {
  index: number;
  subject_id?: number | null;
  question: getLessonById_getLessonById_lesson_content_quizzes_questions;
}
const QuestionAI: FC<PropsFill> = ({ index, question }) => {
  const questionText = question.question;

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.title}>
          {index + 1}. {questionText}
        </div>
        {question.url && (
          <div className={styles.containerImage}>
            <img
              className={styles.imgQuestions}
              src={question.url ?? ""}
              alt="questionImage"
            />
          </div>
        )}
        {question.paragraph && (
          <div className={styles.containerParagraph}>{question.paragraph}</div>
        )}
      </div>
      <div className={styles.bottomInfoContainer}>
        <div className={styles.containerRM}>
          <div className={styles.columnRM}>R.M.</div>
          <div className={styles.columnResponse}>
            {question.answers && question.answers[0].answer}
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestionAI;
