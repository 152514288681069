import thumbnailClase from "../../../../assets/images/thumbnail/thumbnailOrientacionDidactica.png";
import thumbnailClaseEnglish from "../../../../assets/images/thumbnail/teacherGuide.png";
import thumbnailDynamics from "../../../../assets/images/thumbnail/thumbnailDynamics.png";
import thumbnailDiapositivasEnglish from "../../../../assets/images/thumbnail/slidesEnglish.png";
import thumbnailDiapositivas from "../../../../assets/images/thumbnail/thumbnailDiapositivas.png";
import thumbnailListening from "../../../../assets/images/thumbnail/thumbnailAudio.png";
import thumbnailBookPages from "../../../../assets/images/thumbnail/thumbnailBookPages.png";
import thumbnailBookPagesEnglish from "../../../../assets/images/thumbnail/bookEnglish.png";
import thumbnailCiencias from "../../../../assets/images/thumbnail/thumbnailCiencias.png";
import coverListening from "../../../../assets/images/thumbnail/coverListening.png";
import thumbnailMate from "../../../../assets/images/thumbnail/thumbnailMate.png";
import thumbnailEspañol from "../../../../assets/images/thumbnail/thumbnailEspañol.png";
import ensayoMatematica from "../../../../assets/images/thumbnail/ensayoMatematica.png";
import examenSpain from "../../../../assets/images/thumbnail/coverExamSpain.png";
import ensayoEspañol from "../../../../assets/images/thumbnail/ensayoEspañol.png";
import ensayoCiencias from "../../../../assets/images/thumbnail/ensayoCiencias.png";
import listeningExam from "../../../../assets/images/thumbnail/listening_exam.png";
import writingExam from "../../../../assets/images/thumbnail/writing_exam.png";
import defaultImage from "../../../../assets/images/defaultImage.svg";
import CoverWriting from "../../../../assets/images/thumbnail/CoverWriting.png";
import CoverEvaluation from "../../../../assets/images/thumbnail/CoverEvaluation.png";
import { getLessonById_getLessonById } from "../../../../__generated__/gql-types/getLessonById";

const LESSON_IMAGES: Record<string, Record<number | "default", string>> = {
  classroomguide: {
    16: thumbnailClaseEnglish,
    default: thumbnailClase,
  },
  manipulative_dynamics: {
    default: thumbnailDynamics,
  },
  audio: {
    default: thumbnailListening,
  },
  book_pages: {
    16: thumbnailBookPagesEnglish,
    default: thumbnailBookPages,
  },
  slides: {
    16: thumbnailDiapositivasEnglish,
    default: thumbnailDiapositivas,
  },
  evaluation: {
    1: thumbnailMate,
    4: thumbnailCiencias,
    2: thumbnailEspañol,
    17: thumbnailEspañol,
    16: CoverEvaluation,
    default: defaultImage,
  },
  redaction: {
    1: ensayoMatematica,
    4: ensayoCiencias,
    2: ensayoEspañol,
    17: ensayoEspañol,
    16: CoverWriting,
    default: defaultImage,
  },
  listening: {
    default: coverListening,
  },

  audio_exam: {
    default: listeningExam,
  },
  writing_exam: {
    16: writingExam,
    default: examenSpain,
  },
};

// Función para obtener la imagen de la lección
export const getLessonImage = (lesson: getLessonById_getLessonById | null) => {
  // Prioridad 1: imagen del video
  if (
    lesson?.lesson_content?.videos?.length &&
    lesson.lesson_content.videos[0]?.imageUrl
  ) {
    return lesson.lesson_content.videos[0].imageUrl;
  }

  // Prioridad 2: imagen de 'literacy'
  if (
    lesson?.lesson_type === "literacy" &&
    lesson.lesson_content?.literacy?.cover
  ) {
    return lesson.lesson_content.literacy.cover;
  }

  // Prioridad 3: imagen de 'conversation'
  if (
    lesson?.lesson_type === "conversation" &&
    lesson.lesson_content?.aiconversations?.card_image
  ) {
    return lesson.lesson_content?.aiconversations?.card_image;
  }

  // Obtener la imagen basada en `lesson_type` y `subject_id`
  const lessonTypeImages = LESSON_IMAGES[lesson?.lesson_type ?? ""] || {};
  return (
    lessonTypeImages[lesson?.subject_id ?? "default"] ||
    lessonTypeImages.default ||
    defaultImage
  );
};
