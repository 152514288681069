import React, { ChangeEvent, FC } from "react";
import styles from "./barUsers.module.scss";
import leftArrow from "../../../../../../../assets/icons/arrows/leftArrow.png";
import rightArrow from "../../../../../../../assets/icons/arrows/rightArrow.png";
import { getHomeworkById_getHomeworkById_students_grades as studentsProps } from "../../../../../../../__generated__/gql-types/getHomeworkById";
import { getRedactionCorrection_getRedactionCorrection } from "../../../../../../../__generated__/gql-types/getRedactionCorrection";

interface BarUsersProps {
  handlePrev: () => void;
  handleNext: () => void;
  handleNoteChange: (e: ChangeEvent<HTMLInputElement>) => void;
  isEnglish: boolean | undefined | null;
  evaluateStudent: getRedactionCorrection_getRedactionCorrection | null;
  currentIndex: number;
  alumnsDelivered: studentsProps[] | undefined;
}

const BarUsers: FC<BarUsersProps> = ({
  handlePrev,
  handleNext,
  isEnglish,
  alumnsDelivered,
  currentIndex,
  evaluateStudent,
  handleNoteChange,
}) => {
  return (
    <div className={styles.containerUsers}>
      <div
        className={styles.arrow}
        onClick={() => {
          handlePrev();
        }}
      >
        <img src={leftArrow} alt="left" />
      </div>
      <div className={styles.nameUser}>
        {alumnsDelivered &&
          alumnsDelivered[currentIndex] &&
          alumnsDelivered[currentIndex].student_name}
      </div>
      <div className={styles.containerQualificationStudent}>
        <div>{isEnglish ? "Mark (out of 10)" : "Calificación (de 10)"}</div>
        <input
          className={styles.boxQualification}
          type="number"
          min={0}
          max={10}
          step={1}
          value={evaluateStudent?.note ?? undefined}
          onChange={handleNoteChange}
        />
      </div>
      <div
        className={styles.arrow}
        onClick={() => {
          handleNext();
        }}
      >
        <img src={rightArrow} alt="right" />
      </div>
    </div>
  );
};

export default BarUsers;
