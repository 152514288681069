import React, { FC, useEffect, useState } from "react";
import styles from "./newMatching.module.scss";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { PropsOrderFrase } from "./utils";
import soundActive from "../../../../assets/audios/quiz/iconAudioQuizActive.svg";
import soundNegative from "../../../../assets/audios/quiz/iconAudioQuizNegative.svg";
import {
  arrayColumnsInitialMatching,
  synthesizeLanguageText,
} from "../NewQuizz/utils";

const NewMatching: FC<PropsOrderFrase> = ({
  setColumnsMatching,
  columnsMatching,
  stateNext,
  content,
  subject,
}) => {
  const tasks = content?.dAndDQuestion?.[0].blockItems ?? null;
  const [isSoundActive, setIsSoundActive] = useState<boolean>(false);

  const handleVoice = async () => {
    setIsSoundActive(true);
    subject &&
      content.question &&
      (await synthesizeLanguageText(content.question, subject));
    setIsSoundActive(false);
  };

  const onDragEnd = (result: DropResult) => {
    const { destination, source } = result;
    if (
      (destination?.droppableId === source.droppableId &&
        destination?.index === source.index) ||
      !destination
    ) {
      return;
    }
    const start = columnsMatching[Number(source.droppableId) - 1]; //start : columna de donde sacas el elemento
    const finish = columnsMatching[Number(destination.droppableId) - 1];
    const uniqueValueStart = start.taskIds;
    const uniqueValueFinish = finish.taskIds;
    const newArray = [...columnsMatching];
    newArray[Number(destination.droppableId) - 1].taskIds = uniqueValueStart;
    newArray[Number(source.droppableId) - 1].taskIds = uniqueValueFinish;
    setColumnsMatching(newArray);
  };

  useEffect(() => {
    const newColumn = [...arrayColumnsInitialMatching];
    newColumn.forEach((column, index) => {
      if (index < 4) {
        column.taskIds =
          content?.dAndDQuestion?.[0].blockItems?.[index].text ?? "";
      } else {
        column.taskIds = "";
      }
    });
    setColumnsMatching(newColumn);
  }, [content, setColumnsMatching]);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="all-columns" direction="vertical" type="column">
        {(provided) => (
          <div
            className={styles.container}
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            <div className={styles.titleContainer}>
              {!isSoundActive && (
                <img
                  className={styles.imgSound}
                  src={soundActive}
                  onClick={handleVoice}
                  alt=""
                />
              )}
              {isSoundActive && (
                <img className={styles.imgSound} src={soundNegative} alt="" />
              )}
              {content.question}
            </div>

            <div className={styles.containerFichas}>
              {columnsMatching?.map((column, indexArray) => {
                if (indexArray < 4) {
                  return (
                    <div className={styles.columnContainer} key={column.id}>
                      <Droppable droppableId={`${column.id}`} type="task">
                        {(provided) => {
                          const task = tasks?.find(
                            (e) => e.text === column.taskIds
                          );

                          return (
                            <div
                              className={styles.classTitle}
                              ref={provided.innerRef}
                            >
                              {task && (
                                <Draggable
                                  key={column.taskIds}
                                  draggableId={column.taskIds}
                                  index={indexArray}
                                  isDragDisabled={stateNext}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      className={styles.taskContainer}
                                      {...provided.draggableProps}
                                      ref={provided.innerRef}
                                      {...provided.dragHandleProps}
                                      {...provided.draggableProps}
                                    >
                                      {task?.text}
                                    </div>
                                  )}
                                </Draggable>
                              )}

                              {provided.placeholder}
                            </div>
                          );
                        }}
                      </Droppable>
                    </div>
                  );
                }
              })}
            </div>
            <div className={styles.containerFichas}>
              {columnsMatching?.map((column, indexArray) => {
                if (indexArray >= 4) {
                  return (
                    <div className={styles.columnContainer} key={column.id}>
                      <Droppable
                        droppableId={`${column.id}`}
                        type="task"
                        direction="vertical"
                      >
                        {(provided) => {
                          const task = tasks?.find(
                            (e) => e.text === column.taskIds
                          );

                          const newArray = Array(4).concat(
                            content.dAndDQuestion?.[0].blockContainer
                          );

                          let taskIsTrue = false;
                          if (
                            newArray[indexArray] &&
                            newArray[indexArray].coincidence ===
                              task?.coincidence
                          ) {
                            taskIsTrue = true;
                          }

                          if (task) {
                            return (
                              <div
                                className={styles.classTitle}
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                              >
                                <Draggable
                                  key={column.taskIds}
                                  draggableId={column.taskIds}
                                  index={indexArray}
                                  isDragDisabled={stateNext}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      className={
                                        stateNext
                                          ? taskIsTrue
                                            ? styles.taskContainerTrue
                                            : styles.taskContainerFalse
                                          : styles.taskContainer
                                      }
                                      {...provided.draggableProps}
                                      ref={provided.innerRef}
                                      {...provided.dragHandleProps}
                                    >
                                      {task?.text}
                                    </div>
                                  )}
                                </Draggable>

                                {provided.placeholder}
                              </div>
                            );
                          } else {
                            return (
                              <div
                                className={styles.classTitle}
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                              >
                                <div className={styles.taskContainerImage}>
                                  <img
                                    src={
                                      content?.dAndDQuestion?.[0]
                                        .blockContainer?.[indexArray - 4].url ??
                                      ""
                                    }
                                    alt="esta roto"
                                  />
                                </div>{" "}
                                {provided.placeholder}
                              </div>
                            );
                          }
                        }}
                      </Droppable>
                    </div>
                  );
                }
              })}
            </div>

            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default NewMatching;
