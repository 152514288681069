import React, { FC } from "react";
import styles from "./questionAI.module.scss";
import { getResponsesByHomework_getResponsesByHomework } from "../../../../../__generated__/gql-types/getResponsesByHomework";

interface PropsFill {
  index: number;
  subject_id?: number | null;
  question: getResponsesByHomework_getResponsesByHomework;
}

const QuestionAI: FC<PropsFill> = ({ index, question, subject_id }) => {
  const questionText = question.question;

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.title}>
          {index + 1}. {questionText}
        </div>
        {question.image && (
          <div className={styles.containerImage}>
            <img
              className={styles.imgQuestions}
              src={question.image ?? ""}
              alt="questionImage"
            />
          </div>
        )}
        {question.paragraph && (
          <div className={styles.containerParagraph}>{question.paragraph}</div>
        )}
      </div>
      <div className={styles.bottomInfoContainer}>
        <div className={styles.containerRM}>
          <div className={styles.columnRM}>R.M.</div>
          <div className={styles.columnResponse}>
            {question.answers && question.answers[0].answer}
          </div>
        </div>
        <p className={styles.infoText}>
          *Revisa la respuesta de cada alumno en sus resultados individuales del
          quiz.
        </p>
      </div>
    </div>
  );
};

export default QuestionAI;
