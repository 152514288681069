import { FC } from "react";
import styles from "./filters.module.scss";

// Icons
import matematica from "../../../assets/icons/subjects/matematica.svg";
import matematicaSelected from "../../../assets/icons/subjects/matematica-selected.svg";
import ciencias from "../../../assets/icons/subjects/ciencias.svg";
import cienciasSelected from "../../../assets/icons/subjects/ciencias-selected.svg";
import spanish from "../../../assets/icons/subjects/spanish.svg";
import spanishSelected from "../../../assets/icons/subjects/spanish-selected.svg";
import settingsIcon from "../../../assets/icons/settingsIcon.svg";
import useAuth from "../../../context/useAuth";

import useIsMobile from "../../../hooks/useIsMobile";

interface FiltersProps {
  selectedItem?: string;
  lessonViewSelected: string;
  setSelectedItem: (event: string) => void;
  setOpenFilter: (e: boolean) => void;
  openFilter: boolean;
}

const Filters: FC<FiltersProps> = ({
  selectedItem,
  lessonViewSelected,
  openFilter,
  setSelectedItem,
  setOpenFilter,
}) => {
  const isMobile = useIsMobile();
  const { userToken } = useAuth();

  return isMobile ? (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <div className={styles.containerProgramLucaMobile}>
        <div className={styles.programLucaMobile}>
          {userToken?.role === "teacher" || userToken?.role === "coordinator"
            ? "Programa 2017"
            : "Curso 2017"}{" "}
        </div>
      </div>
      <div className={styles.container}>
        <button
          name="1"
          className={selectedItem === "1" ? styles.itemSelected : styles.items}
          onClick={() => {
            setSelectedItem("1");
          }}
        >
          {selectedItem === "1" ? (
            <img src={matematicaSelected} alt="matematicaSelected" />
          ) : (
            <img src={matematica} alt="matematica" />
          )}
          Matemáticas
        </button>
        <button
          name="2"
          className={selectedItem === "2" ? styles.itemSelected : styles.items}
          onClick={() => {
            setSelectedItem("2");
          }}
        >
          {selectedItem === "2" ? (
            <img src={spanishSelected} alt="spanishSelected" />
          ) : (
            <img src={spanish} alt="spanish" />
          )}
          Español
        </button>
        <button
          name="4"
          className={selectedItem === "4" ? styles.itemSelected : styles.items}
          onClick={() => {
            setSelectedItem("4");
          }}
        >
          {selectedItem === "4" ? (
            <img src={cienciasSelected} alt="cienciasSelected" />
          ) : (
            <img src={ciencias} alt="ciencias" />
          )}
          Ciencias
        </button>
      </div>
    </div>
  ) : (
    <div className={styles.container}>
      {!openFilter && lessonViewSelected === "search" && (
        <div
          className={styles.items}
          onClick={() => {
            setOpenFilter(true);
          }}
        >
          <img src={settingsIcon} alt="" className={styles.filterImg} />
          <div>Filtros</div>
        </div>
      )}
      <button
        name="1"
        className={selectedItem === "1" ? styles.itemSelected : styles.items}
        disabled={userToken?.schools[0].name === "Legionarios" ? true : false}
        onClick={() => {
          setSelectedItem("1");
        }}
      >
        {selectedItem === "1" ? (
          <img src={matematicaSelected} alt="matematicaSelected" />
        ) : (
          <img src={matematica} alt="matematica" />
        )}
        Matemáticas
      </button>
      <button
        name="2"
        className={selectedItem === "2" ? styles.itemSelected : styles.items}
        onClick={() => {
          setSelectedItem("2");
        }}
      >
        {selectedItem === "2" ? (
          <img src={spanishSelected} alt="spanishSelected" />
        ) : (
          <img src={spanish} alt="spanish" />
        )}
        Español
      </button>
      <button
        name="4"
        className={selectedItem === "4" ? styles.itemSelected : styles.items}
        onClick={() => {
          setSelectedItem("4");
        }}
      >
        {selectedItem === "4" ? (
          <img src={cienciasSelected} alt="cienciasSelected" />
        ) : (
          <img src={ciencias} alt="ciencias" />
        )}
        Ciencias
      </button>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          width: "100%",
        }}
      ></div>
    </div>
  );
};

export default Filters;
