import { useState, useEffect } from "react";

const useIsTablet = () => {
  const [isTablet, setIsTablet] = useState(false);

  const handleResize = () => {
    setIsTablet(
      window.innerWidth < 1250 && window.innerWidth < window.innerHeight
        ? true
        : false
    ); // Cambia el valor 1024 según tus necesidades
  };

  useEffect(() => {
    // Llama a la función inicialmente para establecer el estado inicial
    handleResize();

    // Agrega un event listener para escuchar cambios en el tamaño de la pantalla
    window.addEventListener("resize", handleResize);

    // Limpia el event listener cuando el componente se desmonta
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // El array vacío [] asegura que este efecto solo se ejecute una vez

  return isTablet;
};

export default useIsTablet;
