import React, { FC, useEffect, useState } from "react";
import styles from "./drawerInteligentTeacher.module.scss";
import useIsMobile from "../../../../../hooks/useIsMobile";
import { Drawer } from "antd";
import { MainInteligentTeacher } from "./MainInteligentTeacher";
import {
  getHomeworkById_getHomeworkById,
  getHomeworkById_getHomeworkById_students_grades,
} from "../../../../../__generated__/gql-types/getHomeworkById";
import useTeacherResponseRedactionCorrection from "../../../../../api/useTeacherResponseRedactionCorrection";
import UseGetRedactionCorrection from "../../../../../api/useGetRedactionCorrection";
import { getRedactionCorrection_getRedactionCorrection } from "../../../../../__generated__/gql-types/getRedactionCorrection";
import {
  NotificationPositive,
  notificationNegative,
} from "../../../../Shared/Notifacation/NotificationLuca";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { DependencyRefresh } from "../../../../../redux/store/store";
import { setDependencyRefresh } from "../../../../../redux/slices/dependencyRefresh";
import { HeaderInteligentTeacher } from "./HeaderInteligentTeacher";
import { getLessonById_getLessonById } from "../../../../../__generated__/gql-types/getLessonById";
import UseGetLessonsById from "../../../../../api/useGetLessonsById";
import { Loader } from "../../../../Commons";
import htmlToDraft from "html-to-draftjs";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import UseGetHomeworkById from "../../../../../api/useGetHomeworksById";

interface PropsDrawerInteligentTeacher {
  setVisibleDrawer: (e: boolean) => void;
  visibleDrawer: boolean;
  taskId: string;
  userId: number | null;
}
const DrawerInteligentTeacher: FC<PropsDrawerInteligentTeacher> = ({
  setVisibleDrawer,
  visibleDrawer,
  userId,
  taskId,
}) => {
  const isMobile = useIsMobile();
  const dispatch = useAppDispatch();
  const dependencyRefresh = useAppSelector(DependencyRefresh);
  const getHomeworkById = UseGetHomeworkById();
  const teacherResponseRedactionCorrection =
    useTeacherResponseRedactionCorrection();
  const [setTextButton, setSetTextButton] = useState("Guardar");
  const getRedactionCorrection = UseGetRedactionCorrection();
  const [currentIndex, setCurrentIndex] = useState(4);
  const [teacherComments, setTeacherComments] = useState("");
  const [task, setTask] = useState<getHomeworkById_getHomeworkById | null>(
    null
  );
  const [alumnsDelivered, setAlumnsDelivered] = useState<
    getHomeworkById_getHomeworkById_students_grades[]
  >([]);
  const [editorState, setEditorState] = useState<EditorState>(() =>
    EditorState.createEmpty()
  );
  const [editorGtpState, setEditorGtpState] = useState<EditorState>(() =>
    EditorState.createEmpty()
  );
  const [isLoading, setIsLoading] = useState(false);
  const [evaluateStudent, setEvaluateStudent] =
    useState<getRedactionCorrection_getRedactionCorrection | null>(null);
  const getLessonsById = UseGetLessonsById();
  const [individualLesson, setIndividualLesson] =
    useState<getLessonById_getLessonById | null>(null);

  const deb =
    alumnsDelivered &&
    alumnsDelivered[currentIndex] &&
    alumnsDelivered[currentIndex].student_id
      ? `${alumnsDelivered[currentIndex].student_id}`
      : "";

  const onCloseDrawer = () => {
    setVisibleDrawer(false);
  };
  const handleNext = () => {
    if (alumnsDelivered && currentIndex < alumnsDelivered.length - 1) {
      setCurrentIndex(currentIndex + 1);
    } else {
      setCurrentIndex(0);
    }
  };

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    } else {
      alumnsDelivered && setCurrentIndex(alumnsDelivered.length - 1);
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const repsonse_id = evaluateStudent?.response_id
        ? evaluateStudent.response_id.toString()
        : "";

      const finalRubrics = evaluateStudent?.rubrics?.map((rubric) => ({
        order: rubric.order,
        calification: rubric.calification,
      }));
      await teacherResponseRedactionCorrection({
        responseId: repsonse_id,
        redactionResponseCorrectionInput: {
          rubrics_calification: finalRubrics,
          redaction_comments: teacherComments,
          redaction_teacher_response_correction: draftToHtml(
            convertToRaw(editorState.getCurrentContent())
          ),
          redaction_gpt_response_correction: draftToHtml(
            convertToRaw(editorGtpState.getCurrentContent())
          ),
          student_id: Number(deb),
          note: evaluateStudent?.note,
          homework_id: Number(task?.id),
          subject_code: task?.lessons && task?.lessons[0].subject?.code,
        },
      });
      dispatch(setDependencyRefresh(!dependencyRefresh));
      NotificationPositive("Tu corrección fue enviada correctamente.");
      if (alumnsDelivered.length > 1) {
        alumnsDelivered && setSetTextButton("Siguiente");
      } else {
        setVisibleDrawer(false);
      }
    } catch (error) {
      console.log(error);
      notificationNegative("Ocurrió un error en la corrección de la tarea.");
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    (async () => {
      if (task && deb) {
        try {
          const redactionId = task?.lessons
            ? Number(task?.lessons[0].lesson_content.redaction?.id)
            : 0;

          const response = await getRedactionCorrection({
            teacherModalInfoInput: {
              redaction_id: redactionId,
              student_id: Number(deb),
              homework_id: Number(task?.id),
            },
          });
          setEvaluateStudent(response);
          setSetTextButton("Guardar");
          const responseCorrection =
            response?.redaction_teacher_response_correction ??
            response?.redaction_response;
          const responseComments =
            response?.redaction_comments ?? response?.redaction_gpt_comments;

          responseComments && setTeacherComments(responseComments);

          setEditorState(() => {
            if (responseCorrection) {
              const contentBlock = htmlToDraft(responseCorrection);
              const contentState = ContentState.createFromBlockArray(
                contentBlock.contentBlocks
              );
              return EditorState.createWithContent(contentState);
            } else {
              return EditorState.createEmpty();
            }
          });
          setEditorGtpState(() => {
            if (response?.redaction_gpt_response_correction) {
              const contentBlock = htmlToDraft(
                response?.redaction_gpt_response_correction
              );
              const contentState = ContentState.createFromBlockArray(
                contentBlock.contentBlocks
              );
              return EditorState.createWithContent(contentState);
            } else {
              return EditorState.createEmpty();
            }
          });
        } catch (error) {
          console.log(error);
        }
        setIsLoading(false);
      }
    })();
  }, [getRedactionCorrection, deb, task]);

  useEffect(() => {
    (async () => {
      if (taskId) {
        const taskRes = await getHomeworkById({ homeworkId: taskId });
        taskRes && setTask(taskRes);
      }
    })();
  }, [taskId, getHomeworkById]);

  useEffect(() => {
    const alumns =
      task?.students_grades?.filter((user) => user.delivered || user.tries) ??
      [];
    setAlumnsDelivered(alumns);

    const indexPartial = alumns.findIndex(
      (alumn) => alumn.student_id === userId
    );
    setCurrentIndex(indexPartial !== -1 ? indexPartial : 0);
    return () => {
      dispatch(setDependencyRefresh(!dependencyRefresh));
    };
  }, [taskId, setCurrentIndex, setAlumnsDelivered, userId, task]);

  useEffect(() => {
    (async () => {
      try {
        if (task?.lessons) {
          const individualLessonRes = await getLessonsById({
            lessonId:
              task?.lessons && task?.lessons[0].id ? task?.lessons[0].id : "",
          });
          setIndividualLesson(individualLessonRes);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [task?.lessons, getLessonsById]);

  return (
    <Drawer
      placement={isMobile ? "bottom" : "right"}
      onClose={onCloseDrawer}
      open={visibleDrawer}
      className={"drawerInteligentTeacher"}
      height={isMobile ? "500px" : ""}
      styles={{
        header: { padding: "8px 16px" },
        wrapper: {
          width: "1120px",
          maxWidth: "calc(100% - 50px)",
        },
      }}
      closable={false}
      title={
        individualLesson ? (
          <HeaderInteligentTeacher
            individualLesson={individualLesson}
            onCloseDrawer={onCloseDrawer}
          ></HeaderInteligentTeacher>
        ) : (
          false
        )
      }
      footer={
        !isLoading && (
          <div className={styles.footer}>
            <div
              className={
                evaluateStudent?.note !== null
                  ? styles.taskButton
                  : styles.taskButtonDisable
              }
              onClick={() => {
                if (evaluateStudent?.note !== null) {
                  setTextButton === "Siguiente" ? handleNext() : handleSubmit();
                }
              }}
            >
              {setTextButton}
            </div>
          </div>
        )
      }
    >
      {isLoading ? (
        <Loader />
      ) : (
        <MainInteligentTeacher
          handlePrev={handlePrev}
          handleNext={handleNext}
          alumnsDelivered={alumnsDelivered}
          setEditorGtpState={setEditorGtpState}
          currentIndex={currentIndex}
          evaluateStudent={evaluateStudent}
          setEvaluateStudent={setEvaluateStudent}
          teacherComments={teacherComments}
          setTeacherComments={setTeacherComments}
          editorState={editorState}
          editorGtpState={editorGtpState}
          task={task}
          userId={userId}
        />
      )}
    </Drawer>
  );
};

export default DrawerInteligentTeacher;
