import React, { FC } from "react";
import styles from "./contWords.module.scss";
import { EditorState } from "draft-js";

interface ContWordsProps {
  editorGtpState?: EditorState | undefined;
  text?: string;
}
const ContWords: FC<ContWordsProps> = ({ editorGtpState, text }) => {
  const countWords = () => {
    if (editorGtpState) {
      const content =
        editorGtpState?.getCurrentContent().getPlainText(" ") || "";
      const words = content.trim().split(/\s+/);
      return words.filter((word) => word.length > 0).length;
    } else if (text) {
      const words = text.trim().split(/\s+/);
      return words.filter((word) => word.length > 0).length;
    }
  };

  return <div className={styles.container}>Palabras: {countWords()}</div>;
};

export default ContWords;
