// Icons
import defaultImage from "../../../../assets/images/defaultImage.svg";
import thumbnailCiencias from "../../../../assets/images/thumbnail/thumbnailCiencias.png";
import thumbnailMate from "../../../../assets/images/thumbnail/thumbnailMate.png";
import thumbnailEspañol from "../../../../assets/images/thumbnail/thumbnailEspañol.png";
import CoverEvaluation from "../../../../assets/images/thumbnail/CoverEvaluation.png";

import CoverWriting from "../../../../assets/images/thumbnail/CoverWriting.png";
import ensayoMatematica from "../../../../assets/images/thumbnail/ensayoMatematica.png";
import ensayoEspañol from "../../../../assets/images/thumbnail/ensayoEspañol.png";
import ensayoCiencias from "../../../../assets/images/thumbnail/ensayoCiencias.png";
import { getHomeworkByIdForStudent_getHomeworkByIdForStudent } from "../../../../__generated__/gql-types/getHomeworkByIdForStudent";

export const functionReturnImg = (
  homework: getHomeworkByIdForStudent_getHomeworkByIdForStudent | null
) => {
  if (
    homework?.lessons &&
    homework?.lessons?.length >= 0 &&
    homework?.lessons[0].lesson_type === "redaction"
  ) {
    if (homework.lessons[0].subject_id === 1) {
      return ensayoMatematica;
    }
    if (
      homework.lessons[0].subject_id === 2 ||
      (homework?.lessons && homework?.lessons[0].subject_id === 17)
    ) {
      return ensayoEspañol;
    }
    if (homework.lessons[0].subject_id === 4) {
      return ensayoCiencias;
    }
    if (homework.lessons[0].subject_id === 16) {
      return CoverWriting;
    }
  }
  if (
    homework?.lessons &&
    homework?.lessons?.length >= 0 &&
    homework?.lessons[0].lesson_type === "evaluation"
  ) {
    if (homework.lessons[0].subject_id === 1) {
      return thumbnailMate;
    }
    if (homework.lessons[0].subject_id === 4) {
      return thumbnailCiencias;
    }
    if (
      homework.lessons[0].subject_id === 2 ||
      (homework?.lessons && homework?.lessons[0].subject_id === 17)
    ) {
      return thumbnailEspañol;
    }
    if (homework.lessons[0].subject_id === 16) {
      return CoverEvaluation;
    }
  }
  if (
    homework?.lessons &&
    homework?.lessons.length &&
    homework?.lessons[0]?.lesson_content?.videos?.length &&
    homework?.lessons[0]?.lesson_content?.videos[0]?.imageUrl &&
    homework?.lessons[0].lesson_type === "videolesson"
  ) {
    return homework?.lessons[0]?.lesson_content?.videos[0].imageUrl;
  }
  if (
    homework?.lessons &&
    homework?.lessons.length &&
    homework?.lessons[0]?.lesson_content?.literacy &&
    homework?.lessons[0]?.lesson_content?.literacy?.cover
  ) {
    return homework?.lessons[0]?.lesson_content?.literacy?.cover;
  }
  if (
    homework?.lessons &&
    homework?.lessons.length &&
    homework?.lessons[0]?.lesson_content?.aiconversations?.card_image
  ) {
    return homework?.lessons[0]?.lesson_content.aiconversations?.card_image;
  }

  return defaultImage;
};
