import React, { FC } from "react";
import styles from "./mainStudentResponse.module.scss";
import { getResponsesByHomeworkAndUserV2_getResponsesByHomeworkAndUserV2 } from "../../../../../../__generated__/gql-types/getResponsesByHomeworkAndUserV2";
import { NewQuestions } from "./NewQuestions";
import { Relate } from "./Relate";
import { Fill } from "./Fill";
import { FraseDrag } from "./FraseDrag";
import { MatchingTextQuiz } from "./MatchingTextQuiz";
import { OrderFrase } from "./OrderFrase";
import { QuestionAI } from "./QuestionAI";

interface PropsStudentResponse {
  responseByHomeworkAndUser:
    | getResponsesByHomeworkAndUserV2_getResponsesByHomeworkAndUserV2[]
    | null;
  subject_id: string;
}

const MainStudentResponse: FC<PropsStudentResponse> = ({
  subject_id,
  responseByHomeworkAndUser,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.tabsContainer}>
        <div className={styles.studentStatusTable}>
          {responseByHomeworkAndUser?.map((question, index) => {
            if (question.type_id === 2 || question.type_id === 1) {
              return (
                <div className={styles.questionsContainer}>
                  <NewQuestions question={question} index={index} />
                </div>
              );
            }
            if (question.type_id === 6) {
              return (
                <div className={styles.questionsContainer}>
                  <Relate question={question} index={index} />
                </div>
              );
            }
            if (question.type_id === 3) {
              return (
                <div className={styles.questionsContainer}>
                  <QuestionAI question={question} index={index} />
                </div>
              );
            }
            if (question.type_id === 4) {
              return (
                <div className={styles.questionsContainer}>
                  <Fill question={question} index={index} />
                </div>
              );
            }
            if (question.type_id === 7) {
              return (
                <div className={styles.questionsContainer}>
                  <FraseDrag question={question} index={index} />
                </div>
              );
            }
            if (question.type_id === 5) {
              return (
                <div className={styles.questionsContainer}>
                  <MatchingTextQuiz question={question} index={index} />
                </div>
              );
            }
            if (question.type_id === 8) {
              return (
                <div className={styles.questionsContainer}>
                  <OrderFrase
                    question={question}
                    index={index}
                    subject_id={Number(subject_id)}
                  />
                </div>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
};

export default MainStudentResponse;
