import React, { ChangeEvent, FC, useState } from "react";
import styles from "./mainInteligentTeacher.module.scss";
import { Tabs } from "antd";
import { getRedactionCorrection_getRedactionCorrection as RedactionProps } from "../../../../../../__generated__/gql-types/getRedactionCorrection";
import {
  getHomeworkById_getHomeworkById,
  getHomeworkById_getHomeworkById_students_grades as studentsProps,
} from "../../../../../../__generated__/gql-types/getHomeworkById";
import { environment } from "../../../../../../configuration/environment";
import { Editor } from "react-draft-wysiwyg";
import { ContentState, EditorState } from "draft-js";
import BarUsers from "./BarUsers/BarUsers";
import { Rubrics } from "./Rubrics";
import { RedactionSection } from "./RedactionSection";
import { ContWords } from "./ContWords";
import htmlToDraft from "html-to-draftjs";

const { TabPane } = Tabs;

interface PropsMain {
  handlePrev: () => void;
  handleNext: () => void;
  currentIndex: number;
  alumnsDelivered: studentsProps[] | undefined;
  setEditorGtpState: (e: EditorState) => void;
  editorGtpState: EditorState | undefined;
  editorState: EditorState | undefined;
  teacherComments: string;
  setTeacherComments: React.Dispatch<React.SetStateAction<string>>;
  evaluateStudent: RedactionProps | null;
  setEvaluateStudent: React.Dispatch<
    React.SetStateAction<RedactionProps | null>
  >;
  task: getHomeworkById_getHomeworkById | null;
  userId: number | null;
}

const MainInteligentTeacher: FC<PropsMain> = ({
  teacherComments,
  setEditorGtpState,
  editorGtpState,
  handlePrev,
  editorState,
  handleNext,
  setTeacherComments,
  setEvaluateStudent,
  evaluateStudent,
  currentIndex,
  task,
  userId,
  alumnsDelivered,
}) => {
  const [stateTab, setStateTab] = useState("editText");
  const handleNoteChange = (e: ChangeEvent<HTMLInputElement>) => {
    let newValue = parseFloat(e.target.value) || 0;
    if (
      evaluateStudent &&
      Number.isInteger(Number(newValue)) &&
      newValue >= 0 &&
      newValue <= 10
    ) {
      setEvaluateStudent({ ...evaluateStudent, note: Number(newValue) });
      if (e.target.value === undefined) {
        setEvaluateStudent({ ...evaluateStudent, note: null });
      }
    }
  };

  const studentEvidence = task?.students_grades?.find(
    (student) => student.student_id === userId
  );
  const url = `${environment.awsBucketVideos}${studentEvidence?.studentEvidenceUrl}`;
  const isEnglish =
    task?.lessons &&
    task?.lessons?.length > 0 &&
    task?.lessons[0].subject?.id === "16";

  return (
    <div className={styles.container}>
      <BarUsers
        isEnglish={isEnglish}
        handleNext={handleNext}
        handlePrev={handlePrev}
        alumnsDelivered={alumnsDelivered}
        currentIndex={currentIndex}
        evaluateStudent={evaluateStudent}
        handleNoteChange={handleNoteChange}
      />
      <div className={styles.containerInfo}>
        <div className={styles.responseStudent}>
          <Tabs defaultActiveKey="1" centered className={"tabStylesTeacher"}>
            <TabPane tab={isEnglish ? "Redaction" : "Redacción"} key="1">
              <RedactionSection
                stateTab={stateTab}
                setStateTab={setStateTab}
                isEnglish={isEnglish}
              />
              {stateTab === "originalText" && (
                <>
                  <Editor
                    editorState={editorState}
                    readOnly={true}
                    placeholder="Escribe aquí tu respuesta"
                    editorStyle={{
                      backgroundColor: "white",
                      minHeight: "36vh",
                      maxHeight: "40vh",
                      borderRadius: "0px 0px",
                      padding: 10,
                    }}
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    toolbarClassName="toolbar-class"
                    toolbarHidden={true}
                    localization={{
                      locale: "es",
                    }}
                  />
                  <ContWords editorGtpState={editorState} />
                </>
              )}
              {stateTab === "editText" && (
                <>
                  <Editor
                    editorState={editorGtpState}
                    readOnly={false}
                    placeholder="Escribe aquí tu respuesta"
                    editorStyle={{
                      fontSize: "16px",
                      backgroundColor: "white",
                      borderRadius: " 0px 0px",
                      padding: 10,
                      minHeight: "35vh",
                      maxHeight: "43vh",
                      overflowY: "auto",
                    }}
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    toolbarClassName="toolbar-class"
                    toolbarHidden={false}
                    localization={{
                      locale: "es",
                    }}
                    onEditorStateChange={setEditorGtpState}
                  />
                  <ContWords editorGtpState={editorGtpState} />
                </>
              )}
            </TabPane>
            {task?.needs_file_evidence && (
              <TabPane tab={isEnglish ? "Evidence" : "Evidencia"} key="3">
                <div className={styles.containerImg}>
                  {studentEvidence?.studentEvidenceUrl ? (
                    <>
                      <img
                        src={url}
                        alt={
                          "Imágen de evidencia. Si ve esto es probable que el alumno haya subido un archivo que no es una imágen."
                        }
                      />
                      <a
                        href={url}
                        target="_blank"
                        rel="noreferrer"
                        className={styles.downloadEvidence}
                      >
                        <span>Descargar evidencia</span>
                      </a>
                    </>
                  ) : (
                    <div className={styles.evidenceText}>
                      El alumno no ha subido evidencia.
                    </div>
                  )}
                </div>
              </TabPane>
            )}

            <TabPane tab={"Enunciado"} key="2">
              <Editor
                editorState={
                  task?.lessons?.[0].lesson_content.redaction?.question
                    ? EditorState.createWithContent(
                        ContentState.createFromBlockArray(
                          htmlToDraft(
                            task?.lessons?.[0].lesson_content.redaction
                              ?.question
                          ).contentBlocks
                        )
                      )
                    : EditorState.createEmpty()
                }
                readOnly={true}
                editorStyle={{
                  backgroundColor: "white",
                  height: "60vh",
                  borderRadius: 12,
                  padding: 10,
                }}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
                toolbarHidden={true}
                localization={{
                  locale: "es",
                }}
              />
            </TabPane>
          </Tabs>
        </div>
        <Rubrics
          evaluateStudent={evaluateStudent}
          isEnglish={isEnglish}
          setEvaluateStudent={setEvaluateStudent}
          setTeacherComments={setTeacherComments}
          teacherComments={teacherComments}
        />
      </div>
    </div>
  );
};

export default MainInteligentTeacher;
