import { FC, useEffect, useState } from "react";
import { Tabs } from "antd";
import styles from "./taskDescription.module.scss";

import requireEvidence from "../../../../assets/icons/require-evidence.svg";
import defaultImage from "../../../../assets/images/defaultImage.svg";
import nextArrow from "../../../../assets/icons/teacher/next_arrow_blue.svg";
import fileGreen from "../../../../assets/icons/teacher/file_green.svg";
import Quiz from "../../../../assets/icons/students/homeworks/Tumer.svg";
import Video from "../../../../assets/icons/students/homeworks/Video_light.svg";

import { StudentStatusTable } from "../StudentsStatusTable";
import { getHomeworkById_getHomeworkById } from "../../../../__generated__/gql-types/getHomeworkById";
import { StudentsAnswersAverageStatus } from "./StudentsAnswersAverageStatus";
import useIsMobile from "../../../../hooks/useIsMobile";
import { NewQuestions } from "./NewQuestions";
import { getResponsesByHomework_getResponsesByHomework } from "../../../../__generated__/gql-types/getResponsesByHomework";
import useGetResponsesByHomework from "../../../../api/useGetResponseByHomework";
import { Relate } from "./Relate";
import { Fill } from "./Fill";
import { FraseDrag } from "./FraseDrag";
import { MatchingTextQuiz } from "./MatchingTextQuiz";
import useGetStudentsAnswersAverageStatus from "../../../../api/useGetStudentsAnswersAverageStatus";
import { getStudentsAnswersAverageStatus_getStudentsAnswersAverageStatus } from "../../../../__generated__/gql-types/getStudentsAnswersAverageStatus";
import { QuestionAI } from "./QuestionAI";
import { OrderFrase } from "./OrderFrase";

interface Props {
  task?: getHomeworkById_getHomeworkById | null;
  setVisibleDrawerConversation: (e: boolean) => void;
  setUserIdSetted: (e: number | null) => void;
  setVisibleDrawer: (e: boolean) => void;
  showModalLesson: () => void;
  setVisibleModalStudentResponse: React.Dispatch<React.SetStateAction<boolean>>;
}

const TaskDescription: FC<Props> = ({
  task,
  showModalLesson,
  setUserIdSetted,
  setVisibleDrawer,
  setVisibleDrawerConversation,
  setVisibleModalStudentResponse,
}) => {
  const isMobile = useIsMobile();
  const getResponse = useGetResponsesByHomework();
  const getStudentsAnswersAverageStatus = useGetStudentsAnswersAverageStatus();
  const [showKeyLearnings, setShowKeyLearnings] = useState(false);
  const [responsesHomeworks, setResponsesHomeworks] = useState<
    getResponsesByHomework_getResponsesByHomework[] | null
  >(null);
  const [averageStatus, setAverageStatus] = useState<
    getStudentsAnswersAverageStatus_getStudentsAnswersAverageStatus[] | null
  >([]);
  const taskLessonType = task?.lessons && task?.lessons[0].lesson_type;
  const individualHomework: boolean = task?.individualHomework
    ? task.individualHomework
    : false;

  let approvedStudents: number = 0;
  let studentsParticipation: number = 0;

  const finalStudentsToRender = () => {
    let individualStudents = task?.users?.map((user) =>
      Number(user.id)
    ) as unknown as number[];
    let finalIndividualStudents: any[] = [];
    if (individualHomework) {
      task?.students_grades?.forEach((student) => {
        return individualStudents?.includes(
          student.student_id ? student.student_id : 0
        )
          ? finalIndividualStudents.push(student)
          : finalIndividualStudents.push({});
      });

      return finalIndividualStudents;
    }
    return task?.students_grades as unknown as any[];
  };
  //student participation & aproved percentage calc
  finalStudentsToRender().forEach((studentGrade) => {
    return studentGrade.high_score !== null && studentGrade.high_score >= 6
      ? (approvedStudents += 1) & (studentsParticipation += 1)
      : studentGrade.high_score !== null && studentGrade.delivered
      ? (studentsParticipation += 1)
      : studentGrade.delivered &&
        task?.lessons &&
        task?.lessons[0].lesson_type === "redaction"
      ? (studentsParticipation += 1)
      : undefined;
  });

  //returns items tags according to lesson_content
  const lessonContentItems = () => {
    const lessonContent =
      task?.lessons && task?.lessons[0] && task?.lessons[0]?.lesson_content
        ? task?.lessons[0]?.lesson_content
        : {};

    const lessonContentKeys = Object.keys(lessonContent);
    return lessonContentKeys.map((key, i) => {
      if (key[0] !== "_") {
        return key === "quizzes" ? (
          <div className={styles.item}>
            <img src={Quiz} alt="" />
            <p key={i}>Quiz </p>
          </div>
        ) : key === "videos" ? (
          <div className={styles.item}>
            <img src={Video} alt="" />
            <p key={i}>Video </p>
          </div>
        ) : undefined;
      }
    });
  };

  useEffect(() => {
    (async () => {
      if (taskLessonType !== "redaction" && taskLessonType !== "conversation") {
        const response = await getResponse({ homeworkId: Number(task?.id) });
        setResponsesHomeworks(response);
      }
    })();
  }, [task?.id, getResponse]);

  useEffect(() => {
    (async () => {
      if (taskLessonType !== "redaction" && taskLessonType !== "conversation") {
        const response = await getStudentsAnswersAverageStatus({
          homeworkId: Number(task?.id),
        });
        setAverageStatus(response);
      }
    })();
  }, [task?.id, getStudentsAnswersAverageStatus]);

  return (
    <div className={styles.container}>
      <div className={styles.tabsContainer}>
        <Tabs
          defaultActiveKey="1"
          tabBarStyle={{
            background: "white",
          }}
        >
          <Tabs.TabPane tab="Resultados" key="1">
            <div className={styles.studentStatusTable}>
              <StudentStatusTable
                task={task}
                setUserIdSetted={setUserIdSetted}
                setVisibleDrawer={setVisibleDrawer}
                setVisibleDrawerConversation={setVisibleDrawerConversation}
                setVisibleModalStudentResponse={setVisibleModalStudentResponse}
              />
            </div>
          </Tabs.TabPane>
          {task?.lessons && task?.lessons[0].lesson_type !== "redaction" && (
            <Tabs.TabPane tab="Quiz" key="5">
              <div className={styles.studentStatusTable}>
                {isMobile ? (
                  <div className={styles.paddingContainer}>
                    <div className={styles.containerMessage}>
                      Accede a plataforma web para ver los resultados que han
                      obtenido tus alumnos en cada pregunta de la evaluación.
                    </div>
                  </div>
                ) : (
                  responsesHomeworks?.map((question, index) => {
                    if (
                      question.question_type === 2 ||
                      question.question_type === 1
                    ) {
                      return (
                        <div className={styles.questionsContainer}>
                          <NewQuestions question={question} index={index} />
                          <StudentsAnswersAverageStatus
                            question={question}
                            averageStatus={averageStatus}
                          />
                        </div>
                      );
                    }
                    if (question.question_type === 6) {
                      return (
                        <div className={styles.questionsContainer}>
                          <Relate
                            question={question}
                            index={index}
                            subject_id={Number(task?.lessons?.[0].subject?.id)}
                          />
                          <StudentsAnswersAverageStatus
                            question={question}
                            averageStatus={averageStatus}
                          />
                        </div>
                      );
                    }
                    if (question.question_type === 3) {
                      return (
                        <div className={styles.questionsContainer}>
                          <QuestionAI question={question} index={index} />
                          <StudentsAnswersAverageStatus
                            question={question}
                            averageStatus={averageStatus}
                          />
                        </div>
                      );
                    }
                    if (question.question_type === 4) {
                      return (
                        <div className={styles.questionsContainer}>
                          <Fill
                            question={question}
                            index={index}
                            subject_id={Number(task?.lessons?.[0].subject?.id)}
                          />
                          <StudentsAnswersAverageStatus
                            question={question}
                            averageStatus={averageStatus}
                          />
                        </div>
                      );
                    }
                    if (question.question_type === 7) {
                      return (
                        <div className={styles.questionsContainer}>
                          <FraseDrag
                            question={question}
                            index={index}
                            subject_id={Number(task?.lessons?.[0].subject?.id)}
                          />
                          <StudentsAnswersAverageStatus
                            question={question}
                            averageStatus={averageStatus}
                          />
                        </div>
                      );
                    }
                    if (question.question_type === 5) {
                      return (
                        <div className={styles.questionsContainer}>
                          <MatchingTextQuiz question={question} index={index} />
                          <StudentsAnswersAverageStatus
                            question={question}
                            averageStatus={averageStatus}
                          />
                        </div>
                      );
                    }
                    if (question.question_type === 8 && averageStatus) {
                      return (
                        <OrderFrase
                          subject_id={
                            task.lessons && task.lessons[0].subject?.id
                              ? task.lessons[0].subject?.id
                              : ""
                          }
                          question={question}
                          averageStatus={averageStatus}
                          index={index}
                        />
                      );
                    }
                  })
                )}
              </div>
            </Tabs.TabPane>
          )}
          <Tabs.TabPane tab="Detalles" key="2">
            <div className={styles.studentStatusTable}>
              {task?.description && (
                <div className={styles.descriptionContainer}>
                  <h1>instrucciones</h1>
                  <p>{task?.description} </p>
                </div>
              )}

              {task?.needs_file_evidence && (
                <div className={styles.requiredEvidence}>
                  <img src={requireEvidence} alt="requireEvidence" />
                  <h1>Requiere evidencia</h1>
                </div>
              )}

              <div className={styles.lessonContentContainer}>
                <div className={styles.leftImage}>
                  <img src={defaultImage} alt="" />
                </div>

                <div className={styles.rightContent}>
                  <div className={styles.contentContainer}>
                    <h1>{task?.lessons && task?.lessons[0]?.title}</h1>
                    <div className={styles.itemsContainer}>
                      {lessonContentItems()}
                    </div>
                  </div>

                  <img
                    src={nextArrow}
                    alt=""
                    className={styles.arrow}
                    onClick={showModalLesson}
                  />
                </div>
              </div>

              {task?.lessons &&
                task?.lessons[0]?.lesson_content?.videos &&
                task?.lessons[0]?.lesson_content?.videos[0] &&
                task?.lessons[0]?.lesson_content?.videos[0].microcontent && (
                  <div className={styles.containerContent}>
                    <h3 className={styles.descriptionTask}>MICROCONTENIDOS</h3>

                    <div className={styles.detailsSuggestedContentContainer}>
                      {task?.lessons[0]?.lesson_content?.videos[0].microcontent}
                    </div>
                  </div>
                )}
              {task?.lessons &&
                task?.lessons[0]?.lesson_content?.videos &&
                task?.lessons[0]?.lesson_content?.videos[0] &&
                task?.lessons[0]?.lesson_content?.videos[0].key_learnings && (
                  <div className={styles.containerContent}>
                    <h3 className={styles.descriptionTask}>
                      APRENDIZAJES CLAVES
                    </h3>

                    <div className={styles.detailsSuggestedContentContainer}>
                      {showKeyLearnings ? (
                        <>
                          <ul className={styles.descriptionLesson}>
                            {task?.lessons[0]?.lesson_content?.videos[0].key_learnings
                              ?.split("\n")
                              .filter((item) => item.length > 0)
                              .map((learning) => {
                                return <li>{learning}</li>;
                              })}
                          </ul>
                          <div
                            onClick={() => setShowKeyLearnings(false)}
                            className={styles.showKeyLearnings}
                          >
                            ver menos
                          </div>
                        </>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <ul className={styles.descriptionLesson}>
                            {task?.lessons[0]?.lesson_content?.videos[0].key_learnings
                              ?.split("\n")
                              .filter((item) => item.length > 0)
                              .slice(0, 3)
                              .map((learning, index) => {
                                return <li>{learning}</li>;
                              })}
                          </ul>
                          <div
                            onClick={() => setShowKeyLearnings(true)}
                            className={styles.showKeyLearnings}
                          >
                            ver más
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}

              {task?.teacher_file_url && (
                <div className={styles.fileContainer}>
                  <div className={styles.fileContent}>
                    <img src={fileGreen} alt="fileGreen" />
                  </div>

                  <div className={styles.rightContent}>
                    <div className={styles.contentContainer}>
                      <h1>Titulodelarchivo.pdf</h1>
                      <h2>PDF</h2>
                    </div>
                    <img src={nextArrow} alt="" className={styles.arrow} />
                  </div>
                </div>
              )}
            </div>
          </Tabs.TabPane>

          {task?.lessons && task?.lessons[0].lesson_type === "redaction" && (
            <Tabs.TabPane tab="Tarea" key="3">
              <div className={styles.studentStatusTable}>
                <div className={styles.paddingContainer}>
                  <div className={styles.detailsLeccionSepContainer}>
                    <h3 className={styles.descriptionTask}>Enunciado</h3>
                  </div>

                  <div className={styles.detailsContentContainer}>
                    <div className={styles.detailsTitleContent}>
                      {task?.lessons[0].lesson_content.redaction?.question.includes(
                        "Evidencia"
                      ) ? (
                        <>
                          <div className={styles.descriptionLesson}>
                            {
                              task?.lessons[0].lesson_content.redaction?.question.split(
                                "Evidencia:"
                              )[0]
                            }
                          </div>
                          <br />
                          <div className={styles.descriptionLesson}>
                            Evidencia:
                            {
                              task?.lessons[0].lesson_content.redaction?.question.split(
                                "Evidencia:"
                              )[1]
                            }
                          </div>
                        </>
                      ) : (
                        <div className={styles.descriptionLesson}>
                          {task?.lessons[0].lesson_content.redaction?.question}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={styles.detailsLeccionSepContainer}>
                    <h3 className={styles.descriptionTask}>Rúbricas</h3>
                  </div>

                  <div className={styles.detailsContentContainer}>
                    <div className={styles.detailsTitleContent}>
                      {task?.lessons[0].lesson_content.redaction?.rubrics.map(
                        (e, index) => {
                          return (
                            <>
                              <div className={styles.descriptionLesson}>
                                Rúbrica {index + 1}: {e.rubric}
                              </div>
                            </>
                          );
                        }
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Tabs.TabPane>
          )}
        </Tabs>
      </div>
    </div>
  );
};

export default TaskDescription;
