import React, { FC, useEffect, useState } from "react";
import styles from "./conversation.module.scss";
import { PropsConversation, PropsMessage, apiKeyChatGpt } from "./utils";
import axios from "axios";
import { QuizHeader } from "../Header";
import { ChatConversation } from "./ChatConversation";
import {
  handleAudioNegativeFinish,
  handleAudioPositiveFinish,
  useFetchLesson,
} from "../utils";
import useAuth from "../../../../../context/useAuth";
import { environment } from "../../../../../configuration/environment";
import { useLocation, useNavigate } from "react-router-dom";
import ResultConversation from "./ResultConversation/ResultConversation";
import UseCreateChat from "../../../../../api/useCreateChat";

const Conversation: FC<PropsConversation> = ({
  individualLesson,
  handleInstructions,
  statusHomework,
}) => {
  let questions =
    individualLesson.lesson_content.aiconversations?.section_1 &&
    individualLesson.lesson_content.aiconversations?.section_2
      ? individualLesson.lesson_content.aiconversations?.section_1.concat(
          individualLesson.lesson_content.aiconversations?.section_2
        )
      : [];
  const { userToken, token } = useAuth();
  const navigate = useNavigate();
  const location: any = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const homeworkParams = queryParams.get("homework");
  const [contQuestion, setContQuestion] = useState(1);
  const [messages, setMessages] = useState<PropsMessage[]>([]);
  const [isFinished, setIssFinished] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [result, setResult] = useState<any | null>(null);
  const [contResult, setContResult] = useState<number>(0);
  const createChatLog = UseCreateChat();
  const { sound, setSound } = useFetchLesson();

  const functionUnderstoodIsFalse = async (userMessage: string) => {
    const propmtsAlumn = [
      {
        role: "user",
        content: `You asked this: ${questions[contQuestion - 1].question}.
        Student responded this: ${userMessage}
        Explain to the student, en B2 español, qué quería decir la pregunta y darles una sugerencia de una respuesta ejemplar en inglés (write this suggestion in english, but the rest of your comment in Spanish). Por ejemplo “Te pregunté sobre tu edad, ejemplo de respuesta “I am 6 years old.”” Solo 25 palabras maximo.`,
      },
    ];
    console.log({
      propmt: `You asked this: ${questions[contQuestion - 1].question}.
        Student responded this: ${userMessage}
        Explain to the student, en B2 español, qué quería decir la pregunta y darles una sugerencia de una respuesta ejemplar en inglés (write this suggestion in english, but the rest of your comment in Spanish). Por ejemplo “Te pregunté sobre tu edad, ejemplo de respuesta “I am 6 years old.”” Solo 25 palabras maximo.`,
    });

    const payload = {
      model: "gpt-4o",
      messages: propmtsAlumn,
    };

    const response = await axios.post(
      "https://api.openai.com/v1/chat/completions",
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${apiKeyChatGpt}`,
        },
      }
    );
    setMessages([
      ...messages,
      {
        message: userMessage,
        sender: "sender",
        messageUser: response.data.choices[0].message.content,
      },
      {
        message: response.data.choices[0].message.content,
        notUnderstood: true,
        sender: "LUCA",
        messageUser: response.data.choices[0].message.content,
      },
    ]);
  };

  const functionGoodBye = async () => {
    const propmtsAlumn = [
      {
        role: "user",
        content: `You are in the middle of english practice with a spanish child  in ${
          individualLesson.grade?.grade
        } grade at ${
          individualLesson.lesson_content.aiconversations?.level_cerf
        } CEFR level. 
        You previously asked this question: "${questions[contQuestion - 1]}"
        Now say goodbye to the student, in your role, Make sure you only use a maximum of 20 words.`,
      },
    ];
    console.log({
      propmt: `You are in the middle of english practice with a spanish child  in ${
        individualLesson.grade?.grade
      } grade at ${
        individualLesson.lesson_content.aiconversations?.level_cerf
      } CEFR level. 
        You previously asked this question: "${questions[contQuestion - 1]}"
        Now say goodbye to the student, in your role, Make sure you only use a maximum of 20 words.`,
    });

    const payload = {
      model: "gpt-4o",
      messages: propmtsAlumn,
    };

    const response = await axios.post(
      "https://api.openai.com/v1/chat/completions",
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${apiKeyChatGpt}`,
        },
      }
    );

    return response.data.choices[0].message.content as string;
  };

  const functionFixedError = async (
    userMessage: string,
    maxIntentos: number,
    intentoActual = 1
  ) => {
    const propmtsAlumn = [
      {
        role: "user",
        content: `Error Correction Prompt:
1. You are reviewing chat texts written by a non-native English child.
2. IMPORTANT: Your task is to IDENTIFY AND CORRECT ALL grammar, spelling, tense, and agreement errors in the text provided, IN EVERY TEXT provided by the child.
3. DO NOT MISS ANY ERRORS. Ensure every mistake is corrected.**IMPORTANT:** Do not count capitalization errors (such as wrong use of upper or lower case) as errors and do not count missing a full stop.
4. IMPORTANT: If any words are written in Spanish, REPLACE them with their English translations and mark those corrections in green as well.
5. REQUIRED: Transform the whole text into HTML, where ONLY THE CORRECTIONS are marked in green. Follow this HTML example structure for corrections: ‘Errors:<p>She liked to go to the grocery <span style="color: green;">store</span> and buy things for the <span style="color: green;">poor</span>, she was very <span style="color: green;">generous</span>.</p>’(It is COMPULSORY you respect the structure so the function doesn't break.)
6. **DO NOT** include any explanations or text outside of the HTML structure provided in the example.
8. Respond with only the corrected HTML version of the text, starting with "Errors:" and following the exact HTML example structure.

For this text: ${userMessage}`,
      },
    ];
    console.log({
      propmt: `Error Correction Prompt:
1. You are reviewing chat texts written by a non-native English child.
2. IMPORTANT: Your task is to IDENTIFY AND CORRECT ALL grammar, spelling, tense, and agreement errors in the text provided, IN EVERY TEXT provided by the child.
3. DO NOT MISS ANY ERRORS. Ensure every mistake is corrected.**IMPORTANT:** Do not count capitalization errors (such as wrong use of upper or lower case) as errors and do not count missing a full stop.
4. IMPORTANT: If any words are written in Spanish, REPLACE them with their English translations and mark those corrections in green as well.
5. REQUIRED: Transform the whole text into HTML, where ONLY THE CORRECTIONS are marked in green. Follow this HTML example structure for corrections: ‘Errors:<p>She liked to go to the grocery <span style="color: green;">store</span> and buy things for the <span style="color: green;">poor</span>, she was very <span style="color: green;">generous</span>.</p>’(It is COMPULSORY you respect the structure so the function doesn't break.)
6. **DO NOT** include any explanations or text outside of the HTML structure provided in the example.
8. Respond with only the corrected HTML version of the text, starting with "Errors:" and following the exact HTML example structure.

For this text: ${userMessage}`,
    });

    const payload = {
      model: "gpt-4o",
      messages: propmtsAlumn,
    };

    const response = await axios.post(
      "https://api.openai.com/v1/chat/completions",
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${apiKeyChatGpt}`,
        },
      }
    );

    if (response.data.choices[0].message.content.includes("Errors:")) {
      return response.data.choices[0].message.content.replace(
        /```/g,
        ""
      ) as string;
    } else if (intentoActual < maxIntentos) {
      functionFixedError(userMessage, maxIntentos, intentoActual + 1);
    } else {
      console.log("Se alcanzó el máximo de intentos");
    }
  };

  const functionNextQuestions = async (userMessage: string) => {
    const propmtsAlumn =
      contQuestion === questions.length
        ? [
            {
              role: "user",
              content: `You are in the middle of an English speaking practice (i.e. in English) to a spanish child in ${
                individualLesson.grade?.grade
              } grade at ${
                individualLesson.lesson_content.aiconversations?.level_cerf
              } CEFR level in English:  You asked this: ${
                questions[contQuestion - 1].question
              }. Student responded this: "${userMessage}". Respond to their answer without make a questions (response must be in english), staying in character and remembering the CEFR level and age. Respond to their answer in 10 words maximum. Remember, you are pretending to be ${
                individualLesson.lesson_content.aiconversations
                  ?.character_for_role_play
              }.`,
            },
          ]
        : [
            {
              role: "user",
              content: `You are in the middle of an English speaking practice (i.e. in English) to a spanish child in ${
                individualLesson.grade?.grade
              } grade at ${
                individualLesson.lesson_content.aiconversations?.level_cerf
              } CEFR level in English:  You asked this: ${
                questions[contQuestion - 1].question
              }. Student responded this: "${userMessage}".
              ***IMPORTANT***: Respond to their answer **without asking any questions**.
              Respond to their answer with 10 words max (response must be in english), staying in character. Do not ask any questions within your response. Make sure your vocabulary, language, and sentence structure is appropriate to CEFR level and age. And then ask the following new question (do not add or change this question or ask any other question in addition to it):${
                questions[contQuestion].question
              }. Remember, you are pretending to be ${
                individualLesson.lesson_content.aiconversations
                  ?.character_for_role_play
              }.`,
            },
          ];
    console.log({
      propmt:
        contQuestion === questions.length
          ? `You are in the middle of an English speaking practice (i.e. in English) to a spanish child in ${
              individualLesson.grade?.grade
            } grade at ${
              individualLesson.lesson_content.aiconversations?.level_cerf
            } CEFR level in English:  You asked this: ${
              questions[contQuestion - 1].question
            }. Student responded this: "${userMessage}". Respond to their answer without make a questions (response must be in english), staying in character and remembering the CEFR level and age. Respond to their answer in 10 words maximum. Remember, you are pretending to be ${
              individualLesson.lesson_content.aiconversations
                ?.character_for_role_play
            }.`
          : `You are in the middle of an English speaking practice (i.e. in English) to a spanish child in ${
              individualLesson.grade?.grade
            } grade at ${
              individualLesson.lesson_content.aiconversations?.level_cerf
            } CEFR level in English:  You asked this: ${
              questions[contQuestion - 1].question
            }. Student responded this: "${userMessage}".
              ***IMPORTANT***: Respond to their answer **without asking any questions**.
              Respond to their answer with 10 words max (response must be in english), staying in character. Do not ask any questions within your response. Make sure your vocabulary, language, and sentence structure is appropriate to CEFR level and age. And then ask the following new question (do not add or change this question or ask any other question in addition to it):${
                questions[contQuestion].question
              }. Remember, you are pretending to be ${
              individualLesson.lesson_content.aiconversations
                ?.character_for_role_play
            }.`,
    });

    const payload = {
      model: "gpt-4o",
      messages: propmtsAlumn,
    };

    const response = await axios.post(
      "https://api.openai.com/v1/chat/completions",
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${apiKeyChatGpt}`,
        },
      }
    );

    return response.data.choices[0].message.content as string;
  };

  const handleSendAlumn = async (userMessage: string) => {
    setIsTyping(true);
    setMessages([
      ...messages,
      {
        message: userMessage,
        sender: "sender",
      },
    ]);

    const propmtsAlumn = [
      {
        role: "user",
        content: `You are in the middle of english practice (you must only speak in english) with a Spanish child in ${
          individualLesson.grade?.grade
        } grade at ${
          individualLesson.lesson_content.aiconversations?.level_cerf
        } CEFR level. You previously asked this question: ${
          questions[contQuestion - 1].question
        } Their response was: "${userMessage}". Execute step by step the following:

          1. Understanding Check: First: Verify if the response is in English. If it is in Spanish, mark Understood[0], with Errors[NO].
            Second: If the response is in English, check if it is related to the topic. If the response is relevant (even if incomplete), mark Understood[1]. If the response has no relation to the question, mark Understood[0].

          2. Error Check: Carefully review the response for ALL grammar, spelling, tense, and vocabulary errors. Pay special attention to the verb tense used in the question ${
            questions[contQuestion - 1].question
          }. Ensure the student's response uses the correct verb tense based on the question asked.  **IMPORTANT:** Do not count capitalization errors (such as wrong use of upper or lower case) as errors and do not count missing a full stop. ONLY mark a tense as incorrect if it does not align with the tense used in the question. ENSURE NO ERRORS ARE MISSED. If there are other errors, return Errors[YES]; if there are none, return Errors[NO].

          3. Give the student’s answer a score out of 10. If they have answered with complete sentences and longer answers (4+ words), and if they spelled and used grammar correctly. Be generous and kind with your scorin:  an answer more than 4 words and correct should definitely receive a 10. these are primary school students with a low ability in English! Put your response as Pregunta[score]. 

          Respond with the following, with nothing else. No introduction or explanation.
          Pregunta[X]
          Understood[X]
          Errors[X]
`,
      },
    ];
    console.log({
      propmt: `You are in the middle of english practice (you must only speak in english) with a Spanish child in ${
        individualLesson.grade?.grade
      } grade at ${
        individualLesson.lesson_content.aiconversations?.level_cerf
      } CEFR level. You previously asked this question: ${
        questions[contQuestion - 1].question
      } Their response was "${userMessage}". Execute step by step the following:

          1. Understanding Check: First: Verify if the response is in English. If it is in Spanish, mark Understood[0], with Errors[NO].
            Second: If the response is in English, check if it is related to the topic. If the response is relevant (even if incomplete), mark Understood[1]. If the response has no relation to the question, mark Understood[0].

          2. Error Check: 
                  1. Scope of Review: Carefully review the student's entire response for the following types of errors: 
                  * Grammar 
                  * Spelling 
                  * Verb tense: Ensure alignment with the verb tense used in the question ${
                    questions[contQuestion - 1].question
                  } 
                  * Vocabulary

                  2. What to Ignore:
                    The following types of issues should not be counted as errors:
                    * Capitalization: Ignore incorrect use of uppercase or lowercase letters.
                      *Example: "the dog is running" should not be marked wrong, even though it should be "The dog is running."
                      *Example: "my curtains are Brown" should not be marked wrong, even though it should be "my curtains are brown"
                    * Punctuation: Ignore missing or incorrect punctuation marks, such as full stops, or commas.
                      *Examples:"She went to the park" (missing period) should not be marked as incorrect.
                      *Examples:Spaces after commas: "I like cake,chocolate and apple" (missing space after comma) should not be marked as incorrect, even though the correct form is "I like cake, chocolate and apple."
                        
                    3. Error Detection:
                    Only mark an error if it fits one or more of the following criteria:
                      *Grammar: Mistakes that affect sentence structure or correctness (excluding capitalization and punctuation).
                      *Spelling: Incorrect spelling of words.
                      *Verb tense: If the student's response uses a verb tense that does not align with the verb tense in the question ${
                        questions[contQuestion - 1].question
                      }.
                      *Vocabulary: Incorrect or inappropriate word choice that changes the meaning or clarity of the sentence.
                    4. Response Format:
                      *If any of the above errors (related to grammar, spelling, verb tense, or vocabulary) are found, return Errors[YES].
                      *If no such errors are found, return Errors[NO].

          3. Give the student’s answer a score out of 10. If they have answered with complete sentences and longer answers (4+ words), and if they spelled and used grammar correctly. Be generous and kind with your scorin:  an answer more than 4 words and correct should definitely receive a 10. these are primary school students with a low ability in English! Put your response as Pregunta[score]. 

          Respond with the following, with nothing else. No introduction or explanation.
          Pregunta[X]
          Understood[X]
          Errors[X]
`,
    });

    const payload = {
      model: "gpt-4o",
      messages: propmtsAlumn,
    };

    try {
      const response = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiKeyChatGpt}`,
          },
        }
      );

      if (
        response.data.choices[0].message.content.includes("Understood[0]") ||
        response.data.choices[0].message.content.includes("understood[0]") ||
        response.data.choices[0].message.content.includes("Understood [0]") ||
        response.data.choices[0].message.content.includes("Understood: [0]") ||
        response.data.choices[0].message.content.includes("Understood:[0]")
      ) {
        await functionUnderstoodIsFalse(userMessage);
      } else {
        const resultado =
          response.data.choices[0].message.content.match(/\[(.*?)\]/);
        const valorEncerrado = resultado ? resultado[1] : null;
        console.log({ notaRespuestaChatGPT: valorEncerrado });
        console.log(
          "si esto no da un numero es porque no anda",
          Number(valorEncerrado)
        );
        if (Number(valorEncerrado)) {
          setContResult(
            messages[messages.length - 1].notUnderstood === true
              ? contResult
              : contResult + Number(valorEncerrado) / questions.length
          );
          console.log({
            notaAcumulada:
              messages[messages.length - 1].notUnderstood === true
                ? contResult
                : contResult + Number(valorEncerrado) / questions.length,
          });
        }

        let errorUser =
          response.data.choices[0].message.content.includes("[YES]") ||
          response.data.choices[0].message.content.includes("YES")
            ? await functionFixedError(userMessage, 3)
            : null;

        let nextQuestions = await functionNextQuestions(userMessage);

        if (contQuestion === questions.length) {
          const responseGoodBye = await functionGoodBye();
          let localMessages = [
            ...messages,
            {
              message: userMessage,
              sender: "sender",
              messageUser: response.data.choices[0].message.content,
              errorUser: errorUser,
            },
            {
              message: nextQuestions,
              sender: "LUCA",
              position: "second_to_last",
              notUnderstood: false,
              messageUser: response.data.choices[0].message.content,
            },
            {
              message: responseGoodBye,
              sender: "LUCA",
              position: "last",
              notUnderstood: false,
              messageUser: response.data.choices[0].message.content,
            },
          ];
          setIssFinished(true);
          setMessages(localMessages);
        } else {
          let localMessages = [
            ...messages,
            {
              message: userMessage,
              sender: "sender",
              messageUser: response.data.choices[0].message.content,
              errorUser: errorUser,
            },
            {
              message: nextQuestions,
              sender: "LUCA",
              notUnderstood: false,
              messageUser: response.data.choices[0].message.content,
            },
          ];
          setMessages(localMessages);
        }
        setContQuestion(contQuestion + 1);
      }
    } catch (error) {
      setMessages([
        {
          message: "Tenemos un error, por favor reintentá más tarde.",
          sender: "LUCA",
        },
      ]);
    }
    setIsTyping(false);
  };

  const handleResult = async () => {
    Math.ceil(contResult) > 5
      ? sound && handleAudioPositiveFinish()
      : sound && handleAudioNegativeFinish();
    if (
      userToken?.role === "teacher" ||
      userToken?.role === "coordinator" ||
      userToken?.role === "admin"
    ) {
      setResult({
        __typename: "EvaluateLessonResponse",
        firstTryWith10: null,
        isCourseApproved: Math.ceil(contResult) > 6 ? true : false,
        result: Math.ceil(contResult),
        userWonMedal: null,
        homework_tries: null,
      });
    } else {
      try {
        const newMessages = messages.map(({ position, ...rest }) => rest);
        await createChatLog({
          aiChatLogInput: {
            homework_id: homeworkParams ? Number(homeworkParams) : null,
            user_id: Number(userToken?.id),
            chat_log: newMessages,
          },
        });

        const response = await axios.post(
          `${environment.backendUrl}/evaluateLessonV3`,
          {
            evaluateLessonInputV3: {
              quiz_result: statusHomework?.post_homework
                ? null
                : Math.ceil(contResult),
              homework_id: homeworkParams ? Number(homeworkParams) : null,
              lesson_id: Number(individualLesson?.id),
              post_homework_result: statusHomework?.post_homework
                ? Math.ceil(contResult)
                : null,
            },
          },
          {
            headers: {
              token,
            },
          }
        );
        setResult(response?.data);
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    (async () => {
      setIsTyping(true);
      const payload = {
        model: "gpt-4o", // Asegúrate de usar el modelo correcto disponible en ese momento
        messages: [
          {
            role: "assistant",
            content: `I want to give speaking English practice (i.e. in English) to a spanish child in ${individualLesson.grade?.grade} grade at ${individualLesson.lesson_content.aiconversations?.level_cerf} CEFR level in English. You need to pretend to be ${individualLesson.lesson_content.aiconversations?.character_for_role_play}. Make sure your vocabulary, language, and sentence structure is appropriate to CEFR level and age. Introduce yourself in less than 10 words, create a segue to the following question, and ask the following question: ${questions[0].question}`,
          },
        ],
      };

      console.log({
        propmt: `I want to give speaking English practice (i.e. in English) to a spanish child in ${individualLesson.grade?.grade} grade at ${individualLesson.lesson_content.aiconversations?.level_cerf} CEFR level in English. You need to pretend to be ${individualLesson.lesson_content.aiconversations?.character_for_role_play}. Make sure your vocabulary, language, and sentence structure is appropriate to CEFR level and age. Introduce yourself in less than 10 words, create a segue to the following question, and ask the following question: ${questions[0].question}`,
      });

      try {
        const response = await axios.post(
          "https://api.openai.com/v1/chat/completions",
          payload,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${apiKeyChatGpt}`,
            },
          }
        );
        setMessages([
          {
            message: response.data.choices[0].message.content,
            sender: "LUCA",
          },
        ]);
        setContQuestion(1);
      } catch (error) {
        setMessages([
          {
            message: "Tenemos un error, por favor reintentá más tarde.",
            sender: "LUCA",
          },
        ]);
      }
      setIsTyping(false);
    })();
  }, [individualLesson]);

  return (
    <div className={styles.container}>
      {result ? (
        <ResultConversation
          result={result}
          individualLesson={individualLesson}
          activeSound={sound}
          messages={messages}
        />
      ) : (
        <>
          <QuizHeader
            individualLesson={individualLesson}
            selectedKeyLiteracy={"1"}
            total={questions.length}
            contProgress={contQuestion}
            handleInstructions={handleInstructions}
            sound={sound}
            setSound={setSound}
          />

          <ChatConversation
            isFinished={isFinished}
            messages={messages}
            isTyping={isTyping}
            activeSound={sound}
            individualLesson={individualLesson}
            handleSendAlumn={handleSendAlumn}
          />
        </>
      )}

      <div
        className={isFinished ? styles.botonNormal : styles.botonBloqued}
        onClick={() => {
          if (isFinished) {
            result ? navigate(-1) : handleResult();
          }
        }}
      >
        {result ? "CONTINUE" : "FINISH "}
      </div>
    </div>
  );
};

export default Conversation;
