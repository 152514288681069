import React, { FC, useState } from "react";
import styles from "./tableCourse.module.scss";
import { getPDACourses_getPDACourses } from "../../../../../__generated__/gql-types/getPDACourses";
import { ModalMissProject } from "../../../../Shared/ModalMissProject";
import { NotificationPositive } from "../../../../Shared/Notifacation/NotificationLuca";
import matematicaSelected from "../../../../../assets/icons/subjects/matematica-selected.svg";
import cienciasSelected from "../../../../../assets/icons/subjects/ciencias-selected.svg";
interface PropsTableCourse {
  setCheckedCourse: (e: getPDACourses_getPDACourses[] | null) => void;
  checkedCourse: getPDACourses_getPDACourses[] | null;
  totalCourse: getPDACourses_getPDACourses[] | null;
  field: string[];
}

const TableCourse: FC<PropsTableCourse> = ({
  checkedCourse,
  setCheckedCourse,
  totalCourse,
  field,
}) => {
  const [openPanel, setOpenPanel] = useState<number | null>(null);
  const [openModal, setOpenModal] = useState(false);
  const isSaberes = field.includes("Saberes");

  const addCourse = (course: getPDACourses_getPDACourses) => {
    if (checkedCourse) {
      if (checkedCourse.length < 4) {
        if (!checkedCourse.some((e) => e.id === course.id)) {
          setCheckedCourse([...checkedCourse, course]);
        }
      }
    } else {
      setCheckedCourse([course]);
    }
  };

  const cutText = (e: string) => {
    if (e === "Ética, Naturaleza y Sociedades") return "Ética";
    if (e === "De lo Humano y lo Comunitario") return "De lo Humano";
    return e;
  };

  const onCloseModalDelete = () => {
    setOpenModal(false);
  };

  const handlePanel = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    e: getPDACourses_getPDACourses
  ) => {
    event.isPropagationStopped();
    event.preventDefault();
    event.stopPropagation();
    if (checkedCourse?.find((checked) => checked.id === e.id)) {
      setCheckedCourse(checkedCourse.filter((course) => e.id !== course.id));
    } else if (checkedCourse && checkedCourse?.length > 3) {
      setOpenModal(true);
    } else {
      addCourse(e);
      NotificationPositive("Se ha añadido el tema con éxito a tu proyecto");
    }
  };

  return (
    <div>
      <div className={styles.headerList}>
        <div className={styles.grade}>GRADO</div>
        <div className={styles.subject}>CAMPO</div>
        {isSaberes && <div className={styles.subject}>MATERIA</div>}
        <div className={styles.titleCourse}>TITULO</div>
      </div>
      <div className={styles.coursesContainer}>
        {totalCourse?.map((e, i) => (
          <div
            className={isSaberes ? styles.panelSaberes : styles.panel}
            onClick={() => {
              if (i === openPanel) {
                setOpenPanel(null);
              } else {
                setOpenPanel(i);
              }
            }}
          >
            <div className={styles.containerClosePanel}>
              <div className={styles.grade}> {e.grade_id}°</div>
              <div className={styles.subject}>
                {e.subject && cutText(e.subject)}
              </div>
              {isSaberes && (
                <div className={styles.saberesIcon}>
                  <img src={e.math ? matematicaSelected : cienciasSelected} />
                </div>
              )}
              <div className={styles.panelTitle}>{e.title}</div>
              <div
                className={styles.textDelete}
                onClick={(event) => {
                  handlePanel(event, e);
                }}
              >
                {checkedCourse?.find((checked) => checked.id === e.id)
                  ? "Eliminar"
                  : "Añadir"}
              </div>
            </div>
            {openPanel === i && (
              <>
                <div className={styles.lineBorder}></div>
                <div className={styles.containerOpenPanel}>
                  <div className={styles.titlePDA}>PDA</div>
                  <div className={styles.descriptionPDA}>
                    {e.pda?.split("\n").map((text) => {
                      return text.length > 2 ? <li>{text}</li> : <></>;
                    })}
                  </div>
                  <div className={styles.titlePDA}>CONTENIDOS ESPECIFICOS</div>
                  <div className={styles.descriptionPDA}>
                    {e.content?.split("\n").map((text) => {
                      return text.length > 2 ? <li>{text}</li> : <></>;
                    })}
                  </div>
                </div>
              </>
            )}
          </div>
        ))}
      </div>
      <ModalMissProject
        text={
          "Ya has seleccionado el máximo de 4 temas a incluir en tu proyecto. Si quieres agregar más, elimina alguno de tu lista."
        }
        modalDelete={openModal}
        onCloseModalDelete={onCloseModalDelete}
      />
    </div>
  );
};

export default TableCourse;
