import React, { FC, useState } from "react";
import styles from "./rubrics.module.scss";
import { Input, Select } from "antd";
import { getRedactionCorrection_getRedactionCorrection } from "../../../../../../../__generated__/gql-types/getRedactionCorrection";
import downArrow from "../../../../../../../assets/icons/arrows/downArrow.png";
const { Option } = Select;
const { TextArea } = Input;

interface RubricsProps {
  isEnglish: boolean | undefined | null;
  evaluateStudent: getRedactionCorrection_getRedactionCorrection | null;
  setEvaluateStudent: React.Dispatch<
    React.SetStateAction<getRedactionCorrection_getRedactionCorrection | null>
  >;
  teacherComments: string;
  setTeacherComments: React.Dispatch<React.SetStateAction<string>>;
}
const Rubrics: FC<RubricsProps> = ({
  isEnglish,
  evaluateStudent,
  setEvaluateStudent,
  setTeacherComments,
  teacherComments,
}) => {
  const [toolTip, setToolTip] = useState(
    evaluateStudent?.rubrics?.map((obj) => ({
      isToolTip: false, // Puedes establecer el valor que desees, aquí como ejemplo lo dejo en true
    }))
  );

  return (
    <div className={styles.qualificationStudent}>
      <div className={styles.containerRubricas}>
        <div className={styles.containerColumns}>
          <div className={styles.firstColumn}>
            <div className={styles.titleRubricas}>
              {isEnglish ? "Rubrics" : "Rúbricas"}
            </div>
            {evaluateStudent?.rubrics?.map((rubric, index) => {
              const isLong =
                rubric?.rubric && rubric?.rubric.length > 115 ? true : false;
              return (
                <div key={index} className={styles.textAndInput}>
                  <div
                    className={styles.containerToolTip}
                    onClick={() => {
                      const newArray = toolTip?.map((e, indexTool) => {
                        return { ...e, isToolTip: false };
                      });
                      setToolTip(newArray);
                    }}
                    style={{
                      visibility: toolTip?.[index].isToolTip
                        ? "visible"
                        : "hidden",
                    }}
                  >
                    {rubric.rubric}
                  </div>
                  <div
                    className={styles.subtitleRubricas}
                    style={{
                      cursor: isLong ? "pointer" : "auto",
                    }}
                    onClick={() => {
                      if (isLong) {
                        const newArray = toolTip?.map((e, indexTool) => {
                          if (index === indexTool) {
                            return { ...e, isToolTip: true };
                          } else {
                            return { ...e, isToolTip: false };
                          }
                        });
                        setToolTip(newArray);
                      }
                    }}
                  >
                    <span className={styles.beginRubrics}>
                      {isEnglish ? "Rubric" : "Rúbrica"} {index + 1}:{" "}
                    </span>
                    {rubric.rubric}
                  </div>
                  <Select
                    suffixIcon={
                      <img
                        src={downArrow}
                        alt="arrow"
                        style={{ width: "13px" }}
                      />
                    } // Puedes cambiar este icono a uno personalizado
                    style={{
                      minWidth: "170px",
                      fontSize: "14px",
                      border: "1px solid #3843D0",
                      borderRadius: "8px",
                    }}
                    placeholder="Selecciona una opción"
                    value={rubric.calification}
                    onChange={(e) => {
                      let newObj = evaluateStudent.rubrics;
                      if (newObj) {
                        newObj[index].calification = e;
                      }
                      evaluateStudent &&
                        setEvaluateStudent({
                          ...evaluateStudent,
                          rubrics: newObj,
                        });
                    }}
                  >
                    <Option value={1} style={{ fontSize: "14px" }}>
                      {isEnglish ? "Excellent" : "Excelente"}
                    </Option>
                    <Option value={2} style={{ fontSize: "14px" }}>
                      {isEnglish ? "Good" : "Bien"}
                    </Option>
                    <Option value={3} style={{ fontSize: "14px" }}>
                      {isEnglish ? "OK" : "Regular"}
                    </Option>
                    <Option value={4} style={{ fontSize: "14px" }}>
                      {isEnglish ? "Needs Improvement" : "Por mejorar"}
                    </Option>
                  </Select>
                </div>
              );
            })}
          </div>
        </div>
        <div className={styles.commentsRubricas}>
          {isEnglish ? "Comments" : "Comentarios"}
        </div>
        <TextArea
          value={teacherComments}
          onChange={(e) => {
            setTeacherComments(e.target.value);
          }}
          style={{
            width: "100%",
            height: "194px",
            maxHeight: "194px",
            resize: "none",
            border: "1px solid #3843D0",
            borderRadius: "8px",
            overflowY: "auto",
          }}
        />
      </div>
    </div>
  );
};

export default Rubrics;
