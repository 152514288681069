import React, { FC, useEffect, useState } from "react";
import {
  getHomeworkById_getHomeworkById,
  getHomeworkById_getHomeworkById_students_grades,
} from "../../../../__generated__/gql-types/getHomeworkById";
import { Drawer } from "antd";
import styles from "./drawerConversation.module.scss";
import { getLessonById_getLessonById } from "../../../../__generated__/gql-types/getLessonById";
import UseGetLessonsById from "../../../../api/useGetLessonsById";
import { HeaderConversation } from "./HeaderConversation";
import { MainConversation } from "./MainConversation";
import UseGetChatLogForTeacherCorrection from "../../../../api/useGetChatLogForTeacherCorrection";
import { GetChatLogForTeacherCorrection_getChatLogForTeacherCorrection } from "../../../../__generated__/gql-types/GetChatLogForTeacherCorrection";
import UseGetHomeworkById from "../../../../api/useGetHomeworksById";
interface PropsDrawerConversation {
  setVisibleDrawerConversation: (e: boolean) => void;
  visibleDrawerConversation: boolean;
  taskId: string;
  userId: number | null;
}
const DrawerConversation: FC<PropsDrawerConversation> = ({
  setVisibleDrawerConversation,
  taskId,
  userId,
  visibleDrawerConversation,
}) => {
  const [task, setTask] = useState<getHomeworkById_getHomeworkById | null>(
    null
  );

  const getLessonsById = UseGetLessonsById();
  const getHomeworkById = UseGetHomeworkById();
  const getChatLogCorrection = UseGetChatLogForTeacherCorrection();
  const [currentIndex, setCurrentIndex] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const [deb, setDeb] =
    useState<getHomeworkById_getHomeworkById_students_grades>();
  const [individualLesson, setIndividualLesson] =
    useState<getLessonById_getLessonById | null>(null);
  const [chatLog, setChatLog] =
    useState<GetChatLogForTeacherCorrection_getChatLogForTeacherCorrection | null>(
      null
    );
  const [alumns, setAlumns] = useState<
    getHomeworkById_getHomeworkById_students_grades[]
  >([]);

  const onClose = () => {
    setVisibleDrawerConversation(false);
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      if (taskId) {
        const taskRes = await getHomeworkById({ homeworkId: taskId });
        taskRes && setTask(taskRes);
      }
      setIsLoading(false);
    })();
  }, [taskId, getHomeworkById]);
  useEffect(() => {
    setCurrentIndex(
      alumns.findIndex((alumn) => alumn.student_id === userId) ?? 0
    );
  }, [alumns, userId]);

  useEffect(() => {
    const alumnsLocal =
      task?.students_grades?.filter((user) => user.delivered) ?? [];
    setAlumns(alumnsLocal);
  }, [task]);

  useEffect(() => {
    setDeb(alumns[currentIndex]);
  }, [alumns, currentIndex]);

  useEffect(() => {
    (async () => {
      try {
        if (task?.id && deb) {
          const responseChatLog = await getChatLogCorrection({
            homeworkId: Number(task.id),

            userId: Number(deb.student_id),
          });
          setChatLog(responseChatLog);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [task?.id, deb, getChatLogCorrection]);

  useEffect(() => {
    (async () => {
      try {
        if (task?.lessons) {
          const individualLessonRes = await getLessonsById({
            lessonId:
              task?.lessons && task?.lessons[0].id ? task?.lessons[0].id : "",
          });
          setIndividualLesson(individualLessonRes);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [task?.lessons, getLessonsById]);
  return (
    <Drawer
      placement={"right"}
      width={500}
      onClose={onClose}
      open={visibleDrawerConversation}
      closable={false}
      styles={{
        wrapper: {
          width: "calc(100% - 300px)",
          minWidth: "768px",
          maxWidth: "915px",
        },
      }}
      title={
        individualLesson && (
          <HeaderConversation
            individualLesson={individualLesson}
            onCloseDrawer={onClose}
          />
        )
      }
      footer={
        !isLoading && (
          <div className={styles.footer}>
            <div className={styles.taskButton} onClick={onClose}>
              volver
            </div>
          </div>
        )
      }
    >
      <MainConversation
        isLoading={isLoading}
        alumns={alumns}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
        chatLog={chatLog}
        individualLesson={individualLesson}
      />
    </Drawer>
  );
};

export default DrawerConversation;
