import React, { FC, useState } from "react";
import { getResponsesByHomework_getResponsesByHomework } from "../../../../../__generated__/gql-types/getResponsesByHomework";
import styles from "./orderFrase.module.scss";
import { getStudentsAnswersAverageStatus_getStudentsAnswersAverageStatus } from "../../../../../__generated__/gql-types/getStudentsAnswersAverageStatus";
import { StudentsAnswersAverageStatus } from "../StudentsAnswersAverageStatus";
interface PropsOrderFrase {
  index: number;
  question: getResponsesByHomework_getResponsesByHomework;
  averageStatus: getStudentsAnswersAverageStatus_getStudentsAnswersAverageStatus[];
  subject_id?: string | null;
}

const OrderFrase: FC<PropsOrderFrase> = ({
  question,
  index,
  averageStatus,
  subject_id,
}) => {
  const [showResponse, setShowResponse] = useState(false);
  return (
    <div className={styles.totalContainer}>
      <div className={styles.questionsContainer}>
        <div className={styles.container}>
          <div className={styles.content}>
            <div className={styles.title}>
              {index + 1}.{" "}
              {subject_id === "16"
                ? "Build the sentence."
                : "Ordenar la frase."}
            </div>
            <div className={styles.containerBlock}>
              {question.answers?.map((answers) => (
                <div className={styles.containerAnswer}>{answers.answer}</div>
              ))}
            </div>
          </div>
        </div>
        <StudentsAnswersAverageStatus
          question={question}
          averageStatus={averageStatus}
        />
      </div>
      <div className={styles.containerShowResponse}>
        {showResponse && (
          <div className={styles.containerResponse}>
            {question.student_order_phrase?.map(
              (
                phrase,
                indexPhrase // con css se hace reverse y cambia la direcion a rtl
              ) => (
                <div className={styles.containerPhrase} key={indexPhrase}>
                  {phrase.map((answer, indexAnswer) => (
                    <p
                      key={indexAnswer}
                      className={
                        answer.correct ? styles.wordTrue : styles.wordFalse
                      }
                    >
                      {answer.answer}
                    </p>
                  ))}
                </div>
              )
            )}
          </div>
        )}

        {question.student_order_phrase &&
          question.student_order_phrase?.length > 0 && (
            <div className={styles.showResponse}>
              <div
                className={styles.butonResponse}
                onClick={() => setShowResponse(!showResponse)}
              >
                {showResponse ? "Ocultar respuestas" : "Ver respuestas"}
              </div>
            </div>
          )}
      </div>
    </div>
  );
};

export default OrderFrase;
