import React, { FC, useEffect, useRef, useState } from "react";
import styles from "./tabAudio.module.scss";
import audio from "../../../../../assets/icons/teacher/audio.png";
import music from "../../../../../assets/icons/teacher/music.png";
import play from "../../../../../assets/icons/teacher/play.png";
import pause from "../../../../../assets/icons/teacher/pause.png";
import volume from "../../../../../assets/icons/teacher/volume.png";
import { getLessonById_getLessonById_lesson_content_listening_song as SongProps } from "../../../../../__generated__/gql-types/getLessonById";

interface TabAudioProps {
  selectPanel: string;
  song: SongProps;
}
const TabAudio: FC<TabAudioProps> = ({ song, selectPanel }) => {
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const handleTimeUpdate = () => {
    if (audioRef.current) {
      setProgress(audioRef.current.currentTime);
    }
  };

  const handleProgressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newProgress = parseFloat(event.target.value);
    if (audioRef.current) {
      audioRef.current.currentTime = newProgress;
    }
    setProgress(newProgress);
  };

  const togglePlay = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleLoadedMetadata = () => {
    if (audioRef.current) {
      setDuration(audioRef.current.duration);
    }
  };

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
  };

  useEffect(() => {
    const audioElement = audioRef.current;
    if (audioElement) {
      audioElement.addEventListener("timeupdate", handleTimeUpdate);
      audioElement.addEventListener("loadedmetadata", handleLoadedMetadata);
    }
    return () => {
      if (audioElement) {
        audioElement.removeEventListener("timeupdate", handleTimeUpdate);
        audioElement.removeEventListener(
          "loadedmetadata",
          handleLoadedMetadata
        );
      }
    };
  }, []);
  return (
    <div className={styles.container}>
      <div className={styles.containerAudio}>
        <img src={selectPanel === "22" ? audio : music} alt="" />
        <div>Activity {song.activity_number}</div>
      </div>
      <div className={styles.playerContainer}>
        <div className={styles.player}>
          <audio ref={audioRef} src={song.audio_file ?? ""} />

          <img src={isPlaying ? pause : play} alt="" onClick={togglePlay} />

          <div className={styles.timeStyles}>
            {formatTime(Math.floor(progress))} /{" "}
            {formatTime(Math.floor(duration))}
          </div>

          <input
            type="range"
            min="0"
            max={duration}
            value={progress}
            onChange={handleProgressChange}
            style={{ width: "100%" }}
          />
          <img src={volume} alt="" />
        </div>
      </div>
      {song.text && (
        <React.Fragment>
          <div className={styles.transciption}> TRANSCRIPTION</div>
          <div className={styles.containerTransciption}>{song.text}</div>
        </React.Fragment>
      )}
    </div>
  );
};

export default TabAudio;
