import React, { FC, useState } from "react";
import styles from "./rubrics.module.scss";
import { getRedactionCorrection_getRedactionCorrection } from "../../../../../../__generated__/gql-types/getRedactionCorrection";
import useIsMobile from "../../../../../../hooks/useIsMobile";

interface RubricsProps {
  evaluateStudent: getRedactionCorrection_getRedactionCorrection | null;
}

const Rubrics: FC<RubricsProps> = ({ evaluateStudent }) => {
  const isMobile = useIsMobile();
  const [toolTip, setToolTip] = useState(
    evaluateStudent?.rubrics?.map((obj) => ({
      isToolTip: false, // Puedes establecer el valor que desees, aquí como ejemplo lo dejo en true
    }))
  );
  return (
    <div>
      <div className={styles.containerColumns}>
        <div className={styles.firstColumn}>
          {evaluateStudent?.rubrics?.map((rubric, index) => {
            const isLong =
              rubric?.rubric && rubric?.rubric.length > 115 ? true : false;
            return (
              <div key={index} className={styles.containerFakeInput}>
                <div
                  className={styles.containerToolTip}
                  onClick={() => {
                    const newArray = toolTip?.map((e) => {
                      return { ...e, isToolTip: false };
                    });
                    !isMobile && setToolTip(newArray);
                  }}
                  style={
                    !isMobile
                      ? {
                          visibility: toolTip?.[index].isToolTip
                            ? "visible"
                            : "hidden",
                        }
                      : {}
                  }
                >
                  {rubric.rubric}
                </div>
                <div
                  className={styles.subtitleRubricas}
                  style={
                    !isMobile
                      ? {
                          cursor: isLong ? "pointer" : "auto",
                        }
                      : {}
                  }
                  onClick={() => {
                    if (isLong) {
                      const newArray = toolTip?.map((e, indexTool) => {
                        if (index === indexTool) {
                          return { ...e, isToolTip: true };
                        } else {
                          return { ...e, isToolTip: false };
                        }
                      });
                      !isMobile && setToolTip(newArray);
                    }
                  }}
                >
                  {rubric.rubric}
                </div>

                <div className={styles.boxFakeInput}>
                  {rubric.calification === 1
                    ? "Excelente"
                    : rubric.calification === 2
                    ? "Bien"
                    : rubric.calification === 3
                    ? "Regular"
                    : "Por mejorar"}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className={styles.commentsRubricas}>Comentarios</div>
      <div className={styles.contaienrComments}>
        {evaluateStudent?.redaction_comments ?? ""}
      </div>
    </div>
  );
};

export default Rubrics;
