import React, { FC, useEffect, useState } from "react";
import styles from "./headerStudentResponse.module.scss";
import closeIcon from "../../../../../../assets/icons/others/close-icon.svg";
import { getHomeworkById_getHomeworkById } from "../../../../../../__generated__/gql-types/getHomeworkById";
import {
  resolveAvatar,
  resolveColorAvatar,
} from "../../../../../../utils/functions";
import { environment } from "../../../../../../configuration/environment";
import download from "../../../../../../assets/icons/teacher/download_table.svg";
import noDownloader from "../../../../../../assets/icons/teacher/noDownloader.svg";
import UseGetHomeworkById from "../../../../../../api/useGetHomeworksById";

interface PropsHeader {
  onCloseDrawer: () => void;
  task: getHomeworkById_getHomeworkById;
  userId: number | null;
}

const HeaderStudentResponse: FC<PropsHeader> = ({
  onCloseDrawer,
  task,
  userId,
}) => {
  const student = task?.students_grades?.find(
    (user) => user.student_id === userId
  );
  const url = `${environment.awsBucketVideos}${student?.studentEvidenceUrl}`;

  const checkStatus = (status: string) => {
    return status === "finished"
      ? "Completada"
      : status === "inCourse"
      ? "En Curso"
      : "No entregada";
  };

  return (
    <div className={styles.task}>
      <div className={styles.userContainer}>
        <span
          className={styles.studentAvatar}
          style={{
            background: `${resolveColorAvatar(
              student?.student_avatar ?? "polux"
            )}`,
          }}
        >
          <img
            src={resolveAvatar(student?.student_avatar ?? "polux", "5")}
            alt="avatar"
          />
        </span>
        <h1 className={styles.nameContainer}> {student?.student_name}</h1>
        <div className={styles.deliveredContent}>
          <p
            className={
              checkStatus(student?.status!) === "No entregada"
                ? styles.notDelivered
                : checkStatus(student?.status!) === "Completada"
                ? styles.delivered
                : styles.inCourse
            }
          >
            {checkStatus(student?.status!)}
          </p>
        </div>
        <div className={styles.triesContainer}>
          {!task?.tries || !student?.tries ? (
            <div className={styles.textStyles}>-</div>
          ) : (
            <div className={styles.tries}>
              {student?.tries?.toString()}/{task?.tries}
            </div>
          )}
        </div>
        <div className={styles.tabScoreContainer}>
          {student?.high_score || student?.high_score === 0 ? (
            student?.high_score > 5 ? (
              <div className={styles.tableHighScore}>{student?.high_score}</div>
            ) : (
              <div className={styles.tableLowScore}>{student?.high_score}</div>
            )
          ) : (
            <div className={styles.textStyles}>-</div>
          )}
        </div>
        <div className={styles.downloadContainer}>
          {task?.needs_file_evidence ? (
            student?.studentEvidenceUrl ? (
              <a
                href={url}
                target="_blank"
                rel="noreferrer"
                style={{ cursor: "pointer" }}
              >
                <img src={download} alt="download" />
              </a>
            ) : (
              <div className={styles.noDownloaderDisable}>
                <img src={noDownloader} alt="download" />
              </div>
            )
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className={styles.thirdyTaskColumn}>
        <img
          src={closeIcon}
          alt=""
          className={styles.crossImg}
          onClick={onCloseDrawer}
        />
      </div>
    </div>
  );
};

export default HeaderStudentResponse;
