import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { studentFinishHomeworkActionEventVariables } from "../__generated__/gql-types/studentFinishHomeworkActionEvent";

const useStudentFinishHomeworkActionEvent = () => {
  const client = useApolloClient();

  return useCallback(
    async (origin: studentFinishHomeworkActionEventVariables) => {
      return await client.mutate({
        mutation: STUDENT_FINISH_HOMEWORK_ACTION_EVENT,
        fetchPolicy: "no-cache",
        variables: origin,
      });
    },
    [client]
  );
};
export default useStudentFinishHomeworkActionEvent;

const STUDENT_FINISH_HOMEWORK_ACTION_EVENT = gql`
  mutation studentFinishHomeworkActionEvent(
    $origin: String
    $postHomework: Boolean
    $itemId: Int
    $type: String
  ) {
    studentFinishHomeworkActionEvent(
      origin: $origin
      postHomework: $postHomework
      item_id: $itemId
      type: $type
    )
  }
`;
