import styles from "./evidence.module.scss";
import attach from "../../../../../../../../assets/icons/dock.svg";
import cross from "../../../../../../../../assets/icons/close.svg";
import UseDeleteStudentEvidence from "../../../../../../../../api/useDeleteEvidence";
import { getHomeworkByIdForStudent_getHomeworkByIdForStudent as PropsHomework } from "../../../../../../../../__generated__/gql-types/getHomeworkByIdForStudent";
import { FC } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../redux/hooks";
import { DependencyRefresh } from "../../../../../../../../redux/store/store";
import { setDependencyRefresh } from "../../../../../../../../redux/slices/dependencyRefresh";

interface PropsEvidence {
  homework: PropsHomework;
  nameFile: string | null;
}
const Evidence: FC<PropsEvidence> = ({ homework, nameFile }) => {
  const deleteEvidece = UseDeleteStudentEvidence();
  const dispatch = useAppDispatch();
  const dependencyRefresh = useAppSelector(DependencyRefresh);
  const functionDeleteEvidence = async () => {
    homework?.id && deleteEvidece({ homeworkId: Number(homework?.id) });
    dispatch(setDependencyRefresh(!dependencyRefresh));
  };

  return nameFile ? (
    <div className={styles.container}>
      <div className={styles.containerFile}>
        <div className={styles.firstColumnContainer}>
          <img src={attach} alt="" />
        </div>
        <div className={styles.secondColumnContainer}>
          <div className={styles.containerText}>
            <div className={styles.titleFile}>{nameFile ?? ""}</div>{" "}
          </div>
          <div
            className={styles.crossInstructions}
            onClick={functionDeleteEvidence}
          >
            <img src={cross} alt="cross" />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default Evidence;
