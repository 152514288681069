import { FC } from "react";
import { Collapse, DatePicker } from "antd";
import styles from "./studentCalificationFilters.module.scss";
import finished from "../../../../assets/images/finished.png";
import inCourse from "../../../../assets/images/inCourse.png";
import programmed from "../../../../assets/images/programmed.png";
import CollapsePanel from "antd/es/collapse/CollapsePanel";
import dayjs from "dayjs";
import { getClassroomWithHomeworks_getClassroomWithHomeworks } from "../../../../__generated__/gql-types/getClassroomWithHomeworks";

interface ActiveFiltersProps {
  selectedStatusHomework: string[];
  selectedFromDate: string;
  setSelectedFromDate: (date: string) => void;
  selectedToDate: string;
  setSelectedToDate: (date: string) => void;
  getCalificationsData: (
    classRoomId: number,
    status: string[],
    startDate: string,
    endDate: string
  ) => Promise<void>;
  individualClassroom: getClassroomWithHomeworks_getClassroomWithHomeworks;
  setSelectedStatusHomework: (e: string[]) => void;
}

const StudentCalificationsFilter: FC<ActiveFiltersProps> = ({
  selectedStatusHomework,
  selectedFromDate,
  setSelectedFromDate,
  selectedToDate,
  setSelectedToDate,
  getCalificationsData,
  individualClassroom,
  setSelectedStatusHomework,
}) => {
  const STATUS_OPTIONS: Record<
    string,
    { label: string; img: string; value: string }
  > = {
    programed: { label: "Programadas", img: programmed, value: "programed" },
    inCourse: { label: "En Curso", img: inCourse, value: "inCourse" },
    finished: { label: "Finalizadas", img: finished, value: "finished" },
  };

  const returnStatusSpanish = (e: string) => STATUS_OPTIONS[e]?.label || "";

  const getStringDate = (date: dayjs.Dayjs | null | undefined) => {
    let myDate: String = dayjs(date).format("YYYY-MM-DD");
    let finalDate = `${myDate}T00:00:00Z`;
    return finalDate;
  };

  const addStatusArray = (name: string) => {
    if (!selectedStatusHomework.includes(name)) {
      setSelectedStatusHomework([...selectedStatusHomework, name]);
    } else {
      setSelectedStatusHomework(
        selectedStatusHomework.filter((status: string) => {
          return status !== name;
        })
      );
    }
  };
  const deleteStatusArray = (e: string) => {
    setSelectedStatusHomework(
      selectedStatusHomework.filter((status: string) => {
        return status !== e;
      })
    );
  };
  const deleteAll = () => {
    setSelectedStatusHomework([]);
    setSelectedToDate("");
    setSelectedFromDate("");
  };
  const deleteOnlyDate = () => {
    setSelectedToDate("");
    setSelectedFromDate("");
  };

  return (
    <div className={styles.container}>
      <div className={styles.containerAppliedFilters}>
        <div className={styles.appliedTextContainer}>
          <div className={styles.appliedFilterText}>Filtros aplicados</div>
          <div className={styles.cleanText} onClick={deleteAll}>
            Borrar todo
          </div>
        </div>
        <div className={styles.appliedBubbleContainer}>
          {selectedStatusHomework.map((e) => (
            <div
              className={styles.appliedBubble}
              onClick={() => deleteStatusArray(e)}
            >
              <div> {returnStatusSpanish(e)}</div>
              <div>x</div>
            </div>
          ))}

          {selectedFromDate && selectedToDate && (
            <div className={styles.appliedBubble} onClick={deleteOnlyDate}>
              <div>
                Del {dayjs(selectedFromDate).format("DD/MM/YYYY")} al{" "}
                {dayjs(selectedToDate).format("DD/MM/YYYY")}
              </div>
              <div>x</div>
            </div>
          )}
        </div>
      </div>
      <div className={styles.filterContent}>
        <Collapse
          defaultActiveKey={["1"]}
          ghost={true}
          expandIconPosition="right"
        >
          <CollapsePanel key={""} header={"Fecha"} className={styles.textPanel}>
            <div className={styles.bubblesContainer}>
              <h1>Desde (Inclusive)</h1>
              <DatePicker
                value={selectedFromDate ? dayjs(selectedFromDate) : null}
                className={styles.bubbleSelected}
                onChange={(date) => {
                  if (date !== null) {
                    setSelectedFromDate(getStringDate(date));
                  }
                }}
                format={"DD-MM-YYYY"}
                allowClear={false}
              ></DatePicker>
              <h1>Hasta (Inclusive)</h1>
              <DatePicker
                allowClear={false}
                value={selectedToDate ? dayjs(selectedToDate) : null}
                className={styles.bubbleSelected}
                onChange={(date) => {
                  if (date !== null) {
                    setSelectedToDate(getStringDate(date));
                  }
                }}
                format={"DD-MM-YYYY"}
              ></DatePicker>
            </div>
          </CollapsePanel>
          <CollapsePanel
            key={"1"}
            header={"Estado Tarea"}
            className={styles.textPanel}
          >
            <div className={styles.bubblesContainer}>
              {Object.entries(STATUS_OPTIONS).map(
                ([key, { label, img, value }]) => (
                  <div
                    onClick={() => addStatusArray(value)}
                    className={
                      selectedStatusHomework.includes(value)
                        ? styles.bubbleSelected
                        : styles.bubble
                    }
                  >
                    {label}
                    <img src={img} alt={label} />
                  </div>
                )
              )}
            </div>
          </CollapsePanel>
        </Collapse>
      </div>
      <div className={styles.addFiltersButtonContainer}>
        <button
          className={styles.addFiltersButton}
          onClick={async () => {
            await getCalificationsData(
              Number(individualClassroom?.classroom?.id),
              selectedStatusHomework,
              selectedFromDate,
              selectedToDate
            );
          }}
        >
          Aplicar Filtros
        </button>
      </div>
    </div>
  );
};

export default StudentCalificationsFilter;
