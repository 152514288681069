import { useState } from "react";
import soundActive from "../../../../assets/audios/quiz/iconAudioQuizActive.svg";
import soundNegative from "../../../../assets/audios/quiz/iconAudioQuizNegative.svg";
import vectorImage from "../../../../assets/icons/vector_rm.png";
import styles from "./questionAI.module.scss";
import { synthesizeLanguageText } from "../NewQuizz/utils";
import { ModalImg } from "../NewQuestions/ModalImg";
import useIsMobile from "../../../../hooks/useIsMobile";
import { DrawerAnswer } from "./DrawerAnswer";

interface PropsQuestionAI {
  setTemporalInputFill: (e: string) => void;
  temporalInputFill: string;
  stateNext: boolean;
  alertFalse: boolean;
  alertTrue: boolean;
  alertMid: boolean;
  content: any;
  subject_id: number | null;
}
const QuestionAI = ({
  setTemporalInputFill,
  temporalInputFill,
  stateNext,
  alertFalse,
  alertTrue,
  alertMid,
  content,
  subject_id,
}: PropsQuestionAI) => {
  const [isSoundActive, setIsSoundActive] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [openAnswer, setOpenAnswer] = useState<boolean>(false);

  const closeAnswer = () => {
    setOpenAnswer(false);
  };
  const handleVoice = async () => {
    const questionToSynth =
      content.question && content.question.replace(/__/g, " ");
    setIsSoundActive(true);
    subject_id &&
      questionToSynth &&
      (await synthesizeLanguageText(questionToSynth, subject_id));
    setIsSoundActive(false);
  };

  return (
    <div className={styles.content}>
      <div className={styles.titleQuiz}>
        {!isSoundActive && (
          <img
            className={styles.imgSound}
            src={soundActive}
            onClick={handleVoice}
            alt=""
          />
        )}
        {isSoundActive && (
          <img className={styles.imgSound} src={soundNegative} alt="" />
        )}
        {
          <div className={styles.textContainer}>
            <p>{content.question}</p>
          </div>
        }
      </div>
      {content.url && (
        <img
          className={styles.imgQuiz}
          alt="img"
          src={content.url ?? ""}
          onClick={() => {
            setOpen(true);
          }}
        />
      )}
      {content.paragraph && (
        <div className={styles.containerParagrah}>
          <div className={styles.textParagrah}>{content.paragraph}</div>
        </div>
      )}
      <div className={styles.containerInput}>
        {(alertFalse || alertMid) && (
          <div
            className={styles.popUpContainerMobile}
            onClick={() => setOpenAnswer(true)}
          >
            <div className={styles.columnRM}>Ver Respuesta Modelo</div>
          </div>
        )}
        {(alertFalse || alertMid) && (
          <div className={styles.popUpContainer}>
            <div className={styles.columnRM}>R.M.</div>
            <div className={styles.columnResponse}>
              {content.answers[0].answer}
            </div>
          </div>
        )}
        {(alertFalse || alertMid) && (
          <img src={vectorImage} alt="vector" className={styles.vectorImg} />
        )}
        <input
          className={
            alertTrue
              ? styles.inputQuizTrue
              : alertFalse
              ? styles.inputQuizFalse
              : alertMid
              ? styles.inputQuizMid
              : styles.inputQuizNormal
          }
          placeholder="Escribe tu respuesta"
          disabled={stateNext}
          value={temporalInputFill}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
          onChange={(e) => {
            setTemporalInputFill(e.currentTarget.value);
          }}
        />
      </div>
      <ModalImg
        open={open}
        setOpen={setOpen}
        urlImg={content.url ?? ""}
      ></ModalImg>

      <DrawerAnswer
        openAnswer={openAnswer}
        closeAnswer={closeAnswer}
        answer={content.answers[0].answer}
      />
    </div>
  );
};

export default QuestionAI;
