import React, { FC, useEffect, useState } from "react";
import { getHomeworkById_getHomeworkById } from "../../../../../__generated__/gql-types/getHomeworkById";
import { Drawer, Skeleton } from "antd";
import { HeaderStudentResponse } from "./HeaderStudentResponse";
import { MainStudentResponse } from "./MainStudentResponse";
import useIsMobile from "../../../../../hooks/useIsMobile";
import UseGetResponseByHomeworkAndUserV2 from "../../../../../api/useGetResponseByUserAndHomeworkV2";
import { getResponsesByHomeworkAndUserV2_getResponsesByHomeworkAndUserV2 } from "../../../../../__generated__/gql-types/getResponsesByHomeworkAndUserV2";
import UseGetHomeworkById from "../../../../../api/useGetHomeworksById";

interface PropsDrawerConversation {
  setVisibleModalStudentResponse: React.Dispatch<React.SetStateAction<boolean>>;
  visibleModalStudentResponse: boolean;
  taskId: number;
  userId: number;
}

const DrawerStudentResponse: FC<PropsDrawerConversation> = ({
  setVisibleModalStudentResponse,
  taskId,
  userId,
  visibleModalStudentResponse,
}) => {
  const isMobile = useIsMobile();
  const getResponseByHomeworkAndUserV2 = UseGetResponseByHomeworkAndUserV2();
  const getHomeworkById = UseGetHomeworkById();
  const onClose = () => {
    setVisibleModalStudentResponse(false);
  };

  const [task, setTask] = useState<getHomeworkById_getHomeworkById | null>(
    null
  );
  const [responseByHomeworkAndUser, setResponseByHomeworkAndUser] = useState<
    getResponsesByHomeworkAndUserV2_getResponsesByHomeworkAndUserV2[] | null
  >(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const response = await getResponseByHomeworkAndUserV2({
          homeworkId: taskId,
          userId: userId,
        });
        setResponseByHomeworkAndUser(response);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [taskId, userId, getResponseByHomeworkAndUserV2]);
  useEffect(() => {
    (async () => {
      const taskRes = await getHomeworkById({ homeworkId: taskId.toString() });
      setTask(taskRes);
    })();
  }, [taskId, getHomeworkById]);
  return (
    <Drawer
      width={920}
      placement={isMobile ? "bottom" : "right"}
      height={isMobile ? "500px" : ""}
      onClose={onClose}
      open={visibleModalStudentResponse}
      closable={false}
      title={
        !isLoading && task ? (
          <HeaderStudentResponse
            task={task}
            onCloseDrawer={onClose}
            userId={userId}
          />
        ) : (
          <Skeleton paragraph={{ rows: 1 }} />
        )
      }
    >
      {isLoading ? (
        <Skeleton />
      ) : (
        <MainStudentResponse
          subject_id={
            task?.lessons && task?.lessons[0].subject
              ? task?.lessons[0].subject.id
              : ""
          }
          responseByHomeworkAndUser={responseByHomeworkAndUser}
        />
      )}
    </Drawer>
  );
};

export default DrawerStudentResponse;
