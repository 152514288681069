import React, { FC, useState } from "react";
import styles from "./finishRedaction.module.scss";
import closeRedaction from "../../../../../assets/icons/others/closeRedaction.png";
import logoRedaction from "../../../../../assets/icons/logoRedaction.png";
import gema from "../../../../../assets/icons/gemaMatematica.png";
import download from "../../../../../assets/pages/quiz/download.png";
import { useNavigate } from "react-router-dom";
import { getHomeworkById_getHomeworkById } from "../../../../../__generated__/gql-types/getHomeworkById";
import { DrawerHomework } from "../../DrawerHomeworkStudent";
interface PropsHeaderRedaction {
  homework: getHomeworkById_getHomeworkById | null;
}
const FinishRedaction: FC<PropsHeaderRedaction> = ({ homework }) => {
  const navigate = useNavigate();
  const [visibleLesson, setVisibleLesson] = useState(false);
  const onCloseLesson = () => {
    setVisibleLesson(false);
  };
  return (
    <div className={styles.container}>
      <div
        className={styles.containerImg}
        onClick={() => {
          navigate(-1);
        }}
      >
        <img src={closeRedaction} alt="" />
      </div>
      <div className={styles.content}>
        <img src={logoRedaction} alt="" />
        <div className={styles.title}>Misión finalizada</div>
        {
          <div className={styles.subTitle}>
            {`${
              homework?.redaction_homework_autocorrect
                ? "Nuestra IA"
                : "El profesor"
            } revisará tu misión, y recibirás los resultados en cuanto
          estén listos.`}
          </div>
        }
        <div className={styles.containerGema}>
          Puedes conseguir hasta 100 <img src={gema} alt="gem" />
        </div>
      </div>
      <div className={styles.containerButton}>
        {homework?.needs_file_evidence && (
          <>
            <div className={styles.descriptionEvidence}>
              Recuerda que para completar la misión tu profesor te ha pedido que
              subas una evidencia. Si no lo has hecho, puedes hacerlo ahora.
            </div>
            <button
              className={styles.buttonEvidence}
              onClick={() => setVisibleLesson(true)}
            >
              <div>ADJUNTAR EVIDENCIA</div>
              <img src={download} alt="download" />
            </button>
          </>
        )}

        <div
          className={styles.buttonFinsh}
          onClick={() => {
            navigate(-1);
          }}
        >
          CONTINUAR
        </div>
      </div>
      {visibleLesson && (
        <DrawerHomework
          visibleLesson={visibleLesson}
          onCloseLesson={onCloseLesson}
          homework_id={homework?.id}
          finishedLesson={true}
        />
      )}
    </div>
  );
};

export default FinishRedaction;
