import React, { ChangeEvent, useState } from "react";
import styles from "./buttonHomeworkEvidence.module.scss";
import axios from "axios";
import aws from "aws-sdk";
import useCreateStudentHomeworkEvidenceEvent from "../../../../../api/useCreateStudentHomeworkEvidenceEvent";
import useAuth from "../../../../../context/useAuth";
import { environment } from "../../../../../configuration/environment";
import downloadIcon from "../../../../../assets/icons/downloadIcon.svg";
import { getClassroomsByStudentId_getClassroomsByStudentId_homeworks } from "../../../../../__generated__/gql-types/getClassroomsByStudentId";
import { ModalSave } from "./ModalSave";
import {
  NotificationPositive,
  notificationNegative,
} from "../../../../Shared/Notifacation/NotificationLuca";
import { getHomeworkByIdForStudent_getHomeworkByIdForStudent } from "../../../../../__generated__/gql-types/getHomeworkByIdForStudent";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { DependencyRefresh } from "../../../../../redux/store/store";
import { setDependencyRefresh } from "../../../../../redux/slices/dependencyRefresh";

aws.config = new aws.Config();
aws.config.update({
  region: "us-west-2",
  apiVersion: "2006-03-01",
  credentials: {
    accessKeyId: "AKIAQSPAY2Y4YJBSLTUD",
    secretAccessKey: "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",
  },
  signatureVersion: "v4",
});

interface PropsButtonEvidence {
  homework:
    | getClassroomsByStudentId_getClassroomsByStudentId_homeworks
    | getHomeworkByIdForStudent_getHomeworkByIdForStudent
    | null;
  nameFile: string | null;
  setNameFile: (e: string | null) => void;
}
const ButtonHomeworkEvidence = ({
  homework,
  setNameFile,
}: PropsButtonEvidence) => {
  const folder = "luca2-backend";
  const [file, setFile] = useState<File | null>(null);
  const dispatch = useAppDispatch();
  const dependencyRefresh = useAppSelector(DependencyRefresh);
  const { token } = useAuth();
  const [saveActive, setSaverActive] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const createEvidence = useCreateStudentHomeworkEvidenceEvent();
  // Temporalmente guardamos en el localstorage, despues habria que hacer un get

  const handleCancel = () => {
    setOpenModal(false);
  };
  //get presigned url to put video on s3 bucket
  const getPresignedUrl = async (file: File) => {
    try {
      const presignedUrl = await axios.post(
        `${environment.backendUrl}/urlvideo`,
        {
          folder,
          extension: file.type.split("/")[1],
        },
        {
          headers: {
            token,
          },
        }
      );
      return presignedUrl;
    } catch (error) {
      notificationNegative(
        "¡Lo sentimos! Ha ocurrido un error, vuelve a intentarlo."
      );
    }
  };

  const uploadVideoToS3Bucket = async (urlToUpload: any, file: File) => {
    try {
      await axios.put(urlToUpload, file, {
        headers: {
          "content-type": file.type,
        },
      });
    } catch (error) {
      console.log("uploadVideoToS3Bucket error: ", error);
    }
  };

  const completeUpload = async () => {
    if (file) {
      try {
        const presignedUrl = await getPresignedUrl(file);
        if (presignedUrl) {
          await uploadVideoToS3Bucket(presignedUrl.data.url ?? undefined, file);
          createEvidence(Number(homework?.id), presignedUrl.data.key);

          NotificationPositive(
            "¡Estupendo! La evidencia se ha adjuntado con éxito."
          );
          setSaverActive(false);
          dispatch(setDependencyRefresh(!dependencyRefresh));
        }
      } catch (error) {
        notificationNegative(
          "¡Lo sentimos! Ha ocurrido un error, vuelve a intentarlo."
        );
      }
    }
  };

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || !e.target.files[0]) return;
    let file: File = e.target.files[0];
    setFile(file);
    setNameFile(file.name);
    setSaverActive(true);
  };

  return (
    <div className={styles.containerButtons}>
      <label htmlFor="formId" className={styles.attachButton}>
        <h1>Subir Evidencia</h1>
        <input
          onChange={handleFileChange}
          name=""
          type="file"
          id="formId"
          hidden
        />
        <img src={downloadIcon} alt="downloadIcon" />
      </label>

      <button
        onClick={() => {
          saveActive && setOpenModal(true);
        }}
        className={saveActive ? styles.taskButtonMision : styles.taskButtonGrey}
      >
        GUARDAR
      </button>
      <ModalSave
        open={openModal}
        onCloseModalDelete={handleCancel}
        saveEvidenceModal={completeUpload}
      />
    </div>
  );
};

export default ButtonHomeworkEvidence;
