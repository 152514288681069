import React, { useEffect, useState } from "react";
import styles from "./searcher.module.scss";
import settingsIcon from "../../assets/icons/settingsIcon.svg";
import { Searchbar } from "../Commons";
import { CoursesNemSearcher } from "./CoursesNemSearcher";
import { ActiveFilterSearcher } from "./ActiveFilterSearcher";
import { VideosNemSearcher } from "./VideosNemSearcher";
import { useLocation, useNavigate } from "react-router-dom";

const Searcher = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const gradeParams = queryParams.get("grade");
  const subjectParams = queryParams.get("subject");
  const searchParams = queryParams.get("search");
  const [searchInput, setSearchInput] = useState<string>(searchParams ?? "");
  const [openFilter, setOpenFilter] = useState(false);
  const [grade, setGrade] = useState<number[]>(
    Number(gradeParams) !== 0 && gradeParams?.split(",").map((e) => Number(e))
      ? gradeParams?.split(",").map((e) => Number(e))
      : []
  );

  const [subject, setSubject] = useState<number[]>(
    Number(subjectParams) !== 0 &&
      subjectParams?.split(",").map((e) => Number(e))
      ? subjectParams?.split(",").map((e) => Number(e))
      : []
  );

  // changing params
  useEffect(() => {
    const nuevaUrl = `/searcher?grade=${grade}&subject=${subject}&search=${searchInput}`;
    navigate(nuevaUrl);
  }, [navigate, grade, searchInput, subject]);

  return (
    <div className={styles.container}>
      {openFilter && (
        <ActiveFilterSearcher
          subject={subject}
          setSubject={setSubject}
          setOpenFilter={setOpenFilter}
          setGrade={setGrade}
          grade={grade}
        />
      )}
      <div className={styles.contentContainer}>
        <div className={styles.filtersContainer}>
          {!openFilter && (
            <div
              className={styles.items}
              onClick={() => {
                setOpenFilter(!openFilter);
              }}
            >
              <img src={settingsIcon} alt="" className={styles.filterImg} />
              <div>Filtros</div>
            </div>
          )}
          <Searchbar
            setSearchInput={setSearchInput}
            searchInput={searchInput}
          />
        </div>
        <div className={styles.textTitle}>PROGRAMA LUCA Y 2017</div>
        <CoursesNemSearcher
          searchInput={searchInput}
          grade={grade}
          subject={subject}
        />
        <div className={styles.textTitle}>VIDEOS</div>
        <VideosNemSearcher
          searchInput={searchInput}
          grade={grade}
          subject={subject}
        />
      </div>
    </div>
  );
};

export default Searcher;
