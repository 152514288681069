import { FC, useState, useEffect } from "react";
import { Loader, Searchbar } from "../Commons";
import { Filters } from "./NewFilters";
import { Mosaico } from "./Mosaico";
import { DrawerLessonNew } from "./Drawer";
import { ActiveFilters } from "./ActiveFilters";
import { PaginationProgressBar } from "../Shared/PaginationProgressBar";
import styles from "./lessons.module.scss";
import settingsIcon from "../../assets/icons/settingsIcon.svg";
import leftarrow from "../../assets/icons/leftarrow.svg";

import UseGetLessonsById from "../../api/useGetLessonsById";
import UseGetLessonsBySubjectId from "../../api/useGetLessonsBySubjectId";
import { getLessonById_getLessonById } from "../../__generated__/gql-types/getLessonById";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import UseGetLessonsBySubjectIdLength from "../../api/useGetLessonsBySubjectIdLength";
import { getLessonsBySubjectId_getLessonsBySubject } from "../../__generated__/gql-types/getLessonsBySubjectId";
import {
  filterLessonGrades,
  filterPages,
  filterWeeks,
} from "../../utils/functions";
import UseClassroomsByTeacher from "../../api/useGetClassroomsByTeacher";
import { Dosifications } from "./Dosification/Dosifications";
import { functionReturnIdSubject, functionReturnSubject } from "./Utils";
import UseGetCoursesBySubjectIdV2 from "../../api/useGetCoursesBySubjectIdV2";
import { getCoursesBySubjectIdV2_getCoursesBySubjectIdV2 } from "../../__generated__/gql-types/getCoursesBySubjectIdV2";
import useDebounce from "../../hooks/useDebounce";
import useAuth from "../../context/useAuth";
import { ModalError } from "../Students/Missions/NewQuizz/ModalSave";

const Lessons: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  let { parametro1, parametro2, lessonIdParams } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const queryParams = new URLSearchParams(location.search);
  const gradeParams = queryParams.get("grade");
  const pageParams = queryParams.get("page");
  const weekParams = queryParams.get("week");
  const trimesterParams = queryParams.get("trimester");
  const courseParams = queryParams.get("course");
  // Obtener el valor de un parámetro de la consulta
  const getClassroomsByTeacher = UseClassroomsByTeacher();
  const getLessonsById = UseGetLessonsById();
  const getLessonsBySubjectId = UseGetLessonsBySubjectId();
  const getLessonsBySubjectIdLength = UseGetLessonsBySubjectIdLength();
  const getCoursesBySubjectIdV2 = UseGetCoursesBySubjectIdV2();
  const [coursesState, setCoursesState] = useState<
    getCoursesBySubjectIdV2_getCoursesBySubjectIdV2[]
  >([]);

  const [visible, setVisible] = useState<boolean>(
    lessonIdParams ? true : false
  );
  const [lessonsData, setLessonsData] = useState<
    getLessonsBySubjectId_getLessonsBySubject[] | undefined | null
  >(null);
  const [limit, setLimit] = useState(8);
  const [showAssignHomework, setShowAssignHomework] = useState<boolean>(false);
  const [showAssignClassroomLesson, setShowAssignClassroomLesson] =
    useState<boolean>(false);
  const { userToken } = useAuth();
  // Query Lessons States
  const [grade, setGrade] = useState<number>(
    userToken?.schools[0].name === "Colegio Iluminación"
      ? 4
      : userToken?.schools[0].name === "COLEGIO PROGRESO"
      ? 5
      : Number(gradeParams) ?? 0
  );
  const [trimester, setTrimester] = useState<number>(
    trimesterParams ? Number(trimesterParams) : 2
  );
  const [block, setBlock] = useState<number>();
  const [weekList, setWeekList] = useState<string[]>();
  const [subjectId, setSubjectId] = useState<string>(
    functionReturnIdSubject(parametro2)
  );
  const [selectAcordion, setSelectAcordion] = useState<string>(
    courseParams ?? ""
  );
  const [searchInput, setSearchInput] = useState<string>();
  const [sepBookPage, setSepBookPage] = useState<string>(pageParams ?? "");
  const [selectedWeek, setSelectedWeek] = useState<string>(weekParams ?? "");
  const [sepBookPagesList, setSepBookPagesList] = useState<string[]>();
  // Pagination
  const [lessonsLength, setLessonsLength] = useState<number>(0);
  const [open, setOpen] = useState(false);
  const [lessonId, setLessonId] = useState<string>(lessonIdParams ?? "");

  const [individualLesson, setIndividualLesson] =
    useState<getLessonById_getLessonById | null>(
      location.state?.lesson ?? null
    );
  const [openFilter, setOpenFilter] = useState(
    weekParams || pageParams || Number(gradeParams) ? true : false
  );
  const [loading, setIsLoading] = useState(true);
  const [loadingDosification, setLoadingDosification] = useState(false);
  const [lessonViewSelected, setLessonViewSelected] = useState<string>(
    parametro1 ?? "dosification"
  );
  const [openModalError, setOpenModalError] = useState(false);
  const onCloseModalDelete = () => {
    setOpenModalError(false);
  };
  const [loadingLessons, setLoadingLessons] = useState(true);
  const deb = useDebounce(searchInput ?? "", 1200);
  useEffect(() => {
    if (
      lessonViewSelected === "search" &&
      (weekParams || pageParams || Number(gradeParams))
    ) {
      setOpenFilter(true);
    }
  }, [weekParams, pageParams, gradeParams, lessonViewSelected]);
  // Individual Lesson Drawer Functions
  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setSearchParams((prevParams) => {
      const params = new URLSearchParams(prevParams);
      params.delete("key");

      return params.toString();
    });
    setVisible(false);
    setLessonId("");
    setIndividualLesson(null);
  };

  const showDrawerFilter = () => {
    setOpen(true);
  };

  const onCloseFilter = () => {
    setOpen(false);
  };

  const showAssignHomeworkDrawer = () => {
    setShowAssignHomework(true);
  };

  const showAssignClassroomLessonDrawer = () => {
    setShowAssignClassroomLesson(true);
  };
  const onCloseAssignClassroomLessonDrawer = () => {
    setShowAssignClassroomLesson(false);
  };
  const onCloseAssignHomeworkDrawer = () => {
    setShowAssignHomework(false);
  };

  // get Curses from dosification
  useEffect(() => {
    (async () => {
      setLoadingDosification(true);
      try {
        const response = await getCoursesBySubjectIdV2({
          getCoursesBySubjectIdInputV2: {
            grade_id: grade,
            subject_id: Number(subjectId),
            threemester: trimester,
          },
        });
        if (response) {
          setCoursesState(
            response.sort((a, b) => {
              if (a.week && b.week) return a.week - b.week;
              return 0;
            })
          );
        }

        setLoadingDosification(false);
      } catch (error) {
        setOpenModalError(true);

        console.log(error);
        setLoadingDosification(false);
      }
    })();
  }, [subjectId, grade, trimester, getCoursesBySubjectIdV2]);

  // changing params
  useEffect(() => {
    const nuevaUrl =
      lessonId !== ""
        ? `/lessons/${lessonViewSelected}/${functionReturnSubject(
            subjectId
          )}/${lessonId}?grade=${grade}&page=${sepBookPage}&week=${selectedWeek}&trimester=${trimester}&course=${selectAcordion}`
        : `/lessons/${lessonViewSelected}/${functionReturnSubject(
            subjectId
          )}?grade=${grade}&page=${sepBookPage}&week=${selectedWeek}&trimester=${trimester}&course=${selectAcordion}`;
    navigate(nuevaUrl);
  }, [
    navigate,
    subjectId,
    lessonViewSelected,
    lessonId,
    grade,
    sepBookPage,
    selectedWeek,
    trimester,
    selectAcordion,
  ]);

  // To obtain the length of the elements
  useEffect(() => {
    (async () => {
      const lessonsTotal = await getLessonsBySubjectIdLength({
        getLessonsBySubjectInput: {
          grade_id: grade ?? null,
          week: Number(selectedWeek) ?? null,
          subject_id: `${subjectId}`,
          searchArgs: deb ?? null,
          sep_book_pages: sepBookPage ?? null,
        },
      });
      filterWeeks(lessonsTotal, setWeekList);
      filterPages(lessonsTotal, setSepBookPagesList);
      setLessonsLength(filterLessonGrades(lessonsTotal)?.length || 0);
    })();
  }, [
    grade,
    subjectId,
    deb,
    sepBookPage,
    selectedWeek,
    getLessonsBySubjectIdLength,
  ]);

  // Filtros en Lección.
  useEffect(() => {
    (async () => {
      setLoadingLessons(true);
      const lessonsRes = await getLessonsBySubjectId({
        getLessonsBySubjectInput: {
          subject_id: `${subjectId}`,
          paginationArgs: {
            resultsPerPage: limit,
            currentPageNumber: 0,
          },
          grade_id: grade ?? null,
          week: Number(selectedWeek) ?? null,
          searchArgs: deb ?? null,
          sep_book_pages: sepBookPage ?? null,
        },
      });

      if (selectedWeek === "0") {
        const filter = lessonsRes?.filter((lesson: any) => lesson?.week === 0);
        setLessonsData(filter);
        setLessonsLength(filter?.length || 0);
      } else {
        setLessonsData(filterLessonGrades(lessonsRes));
      }
      setLoadingLessons(false);
    })();
  }, [
    grade,
    subjectId,
    sepBookPage,
    deb,
    limit,
    selectedWeek,
    getLessonsBySubjectId,
  ]);

  // Leccion Individual
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        if (lessonId) {
          const individualLessonRes = await getLessonsById({
            lessonId: `${lessonId}`,
          });
          setIndividualLesson(individualLessonRes);
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    })();
  }, [lessonId, getLessonsById, visible]);

  return (
    <div className={styles.containerLesson}>
      {lessonViewSelected !== "dosification" && (
        <Searchbar setSearchInput={setSearchInput} searchInput={searchInput} />
      )}

      <div className={styles.filtersAndTableContainer}>
        {openFilter && lessonViewSelected === "search" && (
          <div className={styles.filtersContainer}>
            <button
              name="filters"
              className={styles.itemsFilter}
              onClick={() => setOpenFilter(false)}
            >
              <span className={styles.itemFilter}>
                <img
                  src={settingsIcon}
                  alt=""
                  style={{ marginRight: "10px" }}
                />
                Filtros
              </span>
              <img src={leftarrow} alt="" />
            </button>
            <ActiveFilters
              sepBookPage={sepBookPage}
              sepBookPagesList={sepBookPagesList}
              setSepBookPage={setSepBookPage}
              setGrade={setGrade}
              grade={grade}
              setBlock={setBlock}
              block={block}
              weekList={weekList}
              setSelectedWeek={setSelectedWeek}
              selectedWeek={selectedWeek}
            />
          </div>
        )}

        {/* VISTA DE LECCIONES */}
        <div className={styles.tableContainer}>
          <Filters
            lessonViewSelected={lessonViewSelected}
            setLessonViewSelected={setLessonViewSelected}
            selectedItem={
              userToken?.schools[0].name === "Legionarios" ? "2" : subjectId
            }
            loadingLessons={loadingLessons}
            setLimit={setLimit}
            setSelectedItem={setSubjectId}
            showDrawer={showDrawerFilter}
            setOpenFilter={setOpenFilter}
            openFilter={openFilter}
          />
          {lessonViewSelected === "search" ? (
            <>
              {!loadingLessons ? (
                <>
                  <Mosaico
                    data={lessonsData ? lessonsData : null}
                    showDrawer={showDrawer}
                    selectedItem={subjectId}
                    setLessonId={setLessonId}
                    block={block}
                    setBlock={setBlock}
                    grade={grade}
                    setGrade={setGrade}
                    sepBookPage={sepBookPage}
                    setSepBookPage={setSepBookPage}
                    sepBookPagesList={sepBookPagesList}
                    weekList={weekList}
                    selectedWeek={selectedWeek}
                    setSelectedWeek={setSelectedWeek}
                    showDrawerFilter={showDrawerFilter}
                    open={open}
                    onCloseFilter={onCloseFilter}
                    setIndividualLesson={setIndividualLesson}
                  />
                  {lessonsData && (
                    <PaginationProgressBar
                      limit={limit}
                      setLimit={setLimit}
                      itemsLength={lessonsData.length}
                      totalLength={lessonsLength}
                    />
                  )}
                </>
              ) : (
                <Loader />
              )}
            </>
          ) : (
            <Dosifications
              selectAcordion={selectAcordion}
              setSelectAcordion={setSelectAcordion}
              loadingDosification={loadingDosification}
              coursesState={coursesState}
              grade_id={grade === 0 ? 6 : grade}
              trimester={trimester}
              showDrawer={showDrawer}
              setTrimester={setTrimester}
              setLessonId={setLessonId}
              setIndividualLesson={setIndividualLesson}
              setGrade={setGrade}
            />
          )}
        </div>
      </div>

      <DrawerLessonNew
        onClose={onClose}
        visible={visible}
        lessonViewSelected={lessonViewSelected}
        back={"lessons"}
        individualLesson={individualLesson}
      />
      <ModalError
        onCloseModalDelete={onCloseModalDelete}
        open={openModalError}
      ></ModalError>
    </div>
  );
};

export default Lessons;
