export const gradeSelectOptions = (subject: string) => {
  return [
    { value: "1", label: "1° Grado" },
    { value: "2", label: "2° Grado" },
    { value: "3", label: "3° Grado" },
    { value: "4", label: "4° Grado" },
    { value: "5", label: "5° Grado" },
    { value: "6", label: "6° Grado" },
  ];
};
export const gradeSelectEnglishOptions = (subject: string) => {
  return [
    { value: "1", label: "Level 1" },
    { value: "2", label: "Level 2" },
    { value: "3", label: "Level 3" },
    { value: "4", label: "Level 4" },
    { value: "5", label: "Level 5" },
    { value: "6", label: "Level 6" },
  ];
};
