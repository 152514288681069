import audioNegative from "../../../../assets/audios/quiz/negativeQuiz.wav";
import audioPositive from "../../../../assets/audios/quiz/positiveQuiz.wav";
import audioPositiveEvaluate from "../../../../assets/audios/quiz/positiveEvaluate.mp3";
import audioNegativeEvaluate from "../../../../assets/audios/quiz/negativeEvaluate.wav";
import audioMedia from "../../../../assets/audios/quiz/mediaQuiz.wav";
import { useEffect, useState } from "react";
import { LessonOrigin } from "../../../../__generated__/gql-types/globalTypes";
import {
  getLessonById_getLessonById,
  getLessonById_getLessonById_lesson_content_quizzes_questions,
} from "../../../../__generated__/gql-types/getLessonById";
import useStudentStartHomeworkActionEvent from "../../../../api/useStudentStartHomeworkActionEvent";
import { Location, useLocation, useParams } from "react-router-dom";
import UseGetLessonsById from "../../../../api/useGetLessonsById";
import useAuth from "../../../../context/useAuth";
import UseGetHomeworkForLessonResolveDeepLink from "../../../../api/useGetHomeworkForLessonResolveDeepLink";
import { getHomeworkForLessonResolveDeepLink_getHomeworkForLessonResolveDeepLink } from "../../../../__generated__/gql-types/getHomeworkForLessonResolveDeepLink";
import UseGetResponseByUserAndHomework from "../../../../api/useGetResponseByUserAndHomeworkV2";
import useGetStudentTries from "../../../../api/useGetStudentTries";
import { getResponseByUserAndHomework_getResponseByUserAndHomework } from "../../../../__generated__/gql-types/getResponseByUserAndHomework";
import axios from "axios";
import { environment } from "../../../../configuration/environment";
import useStudentFinishHomeworkActionEvent from "../../../../api/useStudentFinishHomeworkActionEvent";
import useStudentFinishLessonInLibrary from "../../../../api/useStudentFinishLessonInLibrary";

export interface PropsArrayRelate {
  first: number;
  second: number;
  alpha?: number | null;
}

export interface ScrollPosition {
  x: number;
  y: number;
}
const audioNegativeQuiz = new Audio(audioNegative);
const audioPositiveQuiz = new Audio(audioPositive);
const audioPositiveFinish = new Audio(audioPositiveEvaluate);
const audioNegativeFinish = new Audio(audioNegativeEvaluate);
const audioMediaQuiz = new Audio(audioMedia);

export const handleAudioNegativeQuiz = () => {
  audioNegativeQuiz.play();
};
export const handleAudioPositiveQuiz = () => {
  audioPositiveQuiz.play();
};
export const handleAudioMediaQuiz = () => {
  audioMediaQuiz.play();
};
export const handleAudioPositiveFinish = () => {
  audioPositiveFinish.play();
};
export const handleAudioNegativeFinish = () => {
  audioNegativeFinish.play();
};

export const arrayColumnsInitial = [
  {
    id: 1,
    title: "Column 1",
    taskIds: [],
  },
  {
    id: 2,
    title: "Column 2",
    taskIds: [],
  },
];
export const arrayColumnsInitialMatching = [
  {
    id: 1,
    title: "Column 1",
    taskIds: "1",
  },
  {
    id: 2,
    title: "Column 2",
    taskIds: "2",
  },
  {
    id: 3,
    title: "Column 3",
    taskIds: "3",
  },
  {
    id: 4,
    title: "Column 4",
    taskIds: "4",
  },
  {
    id: 5,
    title: "Column 5",
    taskIds: "",
  },
  {
    id: 6,
    title: "Column 6",
    taskIds: "",
  },
  {
    id: 7,
    title: "Column 7",
    taskIds: "",
  },
  {
    id: 8,
    title: "Column 8",
    taskIds: "",
  },
];

export const useFetchLesson = () => {
  let { id } = useParams();
  const { userToken, token } = useAuth();
  const location: any = useLocation();

  const sectionStudentFrom =
    location?.state?.back === "lessons"
      ? "2017"
      : location?.state?.back === "nem"
      ? "NEM"
      : null;

  const getLessonsById = UseGetLessonsById();
  const studentStartLessonLog = useStudentStartHomeworkActionEvent();
  const getStudentTries = useGetStudentTries();
  const gtHomeworkForLessonResolveDeepLink =
    UseGetHomeworkForLessonResolveDeepLink();
  const getResponseByUserAndHomework = UseGetResponseByUserAndHomework();
  const studentFinishLessonLog = useStudentFinishHomeworkActionEvent();
  const studentFinishLessonInLibrary = useStudentFinishLessonInLibrary();
  const [sound, setSound] = useState(true);
  const [openModalError, setOpenModalError] = useState(false);
  const [openModalLinkWrong, setOpenModalLinkWrong] = useState(false);
  const [openModalRedactionAlreadyDone, setOpenModalRedactionAlreadyDone] =
    useState(false);
  const [individualLesson, setIndividualLesson] =
    useState<getLessonById_getLessonById | null>(null);
  const [totalQuestion, setTotalQuestion] = useState<number>(0);
  const [totalVideoQuestion, setTotalVideoQuestion] = useState<number>(0);
  const [valueQuestion, setValueQuestion] = useState<number>(0);
  const [statusHomework, setStatusHomework] =
    useState<getHomeworkForLessonResolveDeepLink_getHomeworkForLessonResolveDeepLink | null>(
      null
    );
  const [contQuestion, setContQuestion] = useState<number>(0);
  const [contQuestionQuiz, setContQuestionQuiz] = useState<number>(0);
  const [loading, setLoading] = useState(true);
  const [showVideo, setShowVideo] = useState(false);
  const queryParams = new URLSearchParams(location.search);
  const homeworkParams = queryParams.get("homework");
  const [responses, setResponses] = useState<
    getResponseByUserAndHomework_getResponseByUserAndHomework[]
  >([]);
  const [result, setResult] = useState<any | null>(null);
  const onCloseModalDelete = () => {
    setOpenModalError(false);
  };
  const [contResult, setContResult] = useState<number>(0);
  const [studentQuestionResult, setStudentQuestionResult] = useState([{}]);

  const lessonTypeToSend =
    individualLesson?.lesson_type &&
    individualLesson?.lesson_type === "evaluation"
      ? "Evaluacion"
      : individualLesson?.lesson_type &&
        individualLesson?.lesson_type === "videolesson"
      ? "Videoquiz"
      : null;

  const homeworkTypeToSend =
    individualLesson?.lesson_type &&
    individualLesson?.lesson_type === "redaction"
      ? "redaction"
      : individualLesson?.lesson_type &&
        individualLesson?.lesson_type === "conversation"
      ? "convAI"
      : individualLesson?.lesson_type &&
        individualLesson?.lesson_type === "literacy" &&
        individualLesson?.subject_id == 16
      ? "litEnglish"
      : individualLesson?.lesson_type &&
        individualLesson?.lesson_type === "literacy"
      ? "litSpanish"
      : null;

  const callResult = async () => {
    Math.ceil(contResult) > 5
      ? sound && handleAudioPositiveFinish()
      : sound && handleAudioNegativeFinish();

    if (userToken?.role === "teacher" || userToken?.role === "admin") {
      setResult({
        __typename: "EvaluateLessonResponse",
        firstTryWith10: null,
        isCourseApproved: Math.ceil(contResult) > 6 ? true : false,
        result: Math.ceil(contResult),
        userWonMedal: null,
        homework_tries: null,
      });
    } else {
      try {
        const response = await axios.post(
          `${environment.backendUrl}/evaluateLessonV3`,
          {
            evaluateLessonInputV3: {
              quiz_result: statusHomework?.post_homework
                ? null
                : Math.ceil(contResult),
              homework_id: homeworkParams ? Number(homeworkParams) : null,
              lesson_id: Number(individualLesson?.id),
              students_quiz_answers: studentQuestionResult,
              post_homework_result: statusHomework?.post_homework
                ? Math.ceil(contResult)
                : null,
            },
          },
          {
            headers: {
              token,
            },
          }
        );

        if (homeworkParams) {
          studentFinishLessonLog({
            origin: LessonOrigin.homework,
            postHomework: statusHomework?.post_homework,
            itemId: Number(homeworkParams),
            type: homeworkTypeToSend,
          });
        } else {
          userToken?.role === "student" &&
            studentFinishLessonInLibrary({
              typeLesson: lessonTypeToSend,
              lessonId: individualLesson?.id ? individualLesson?.id : null,
              section: sectionStudentFrom,
            });
        }

        setResult(response?.data);
      } catch (error) {
        setOpenModalError(true);
        console.log(error);
      }
    }
  };

  useEffect(() => {
    (async () => {
      if (userToken?.role !== "teacher") {
        try {
          if (homeworkParams) {
            const response = await gtHomeworkForLessonResolveDeepLink({
              homeworkId: homeworkParams,
            });

            if (response?.success) {
              setStatusHomework(response);
            } else {
              throw new Error(`${response?.msg}`);
            }
          }
        } catch (error) {
          const errorMsg = error as Error;
          if (errorMsg.message === "redaction homework is already done") {
            setOpenModalRedactionAlreadyDone(true);
          } else {
            setOpenModalLinkWrong(true);
          }
        }
      }
    })();
  }, [gtHomeworkForLessonResolveDeepLink, homeworkParams, userToken]);

  useEffect(() => {
    (async () => {
      if (id) {
        let tries = 0;
        try {
          const responseTries = await getStudentTries({
            homeworkId: Number(homeworkParams),
          });

          tries = responseTries?.tries ? responseTries?.tries + 1 : 1;
        } catch (error) {
          console.log("getStudentTries error: ", error);
        }

        try {
          const individualLessonRes = await getLessonsById({
            lessonId: id,
          });

          const questions =
            individualLessonRes?.lesson_content.quizzes?.[0]?.questions &&
            individualLessonRes?.lesson_content.quizzes?.[0]?.questions;

          const shuffleArray = (
            array: getLessonById_getLessonById_lesson_content_quizzes_questions[]
          ) => {
            for (let i = array.length - 1; i > 0; i--) {
              const j = Math.floor(Math.random() * (i + 1));
              [array[i], array[j]] = [array[j], array[i]]; // Intercambiar elementos
            }
            // No es necesario retornar el array porque estamos modificando el original
          };

          questions &&
            questions?.length > 0 &&
            individualLessonRes?.lesson_type !== "literacy" &&
            individualLessonRes?.lesson_type !== "conversation" &&
            shuffleArray(questions);

          if (
            individualLessonRes?.lesson_content?.videos &&
            individualLessonRes?.lesson_content?.videos.length &&
            individualLessonRes?.lesson_content?.videos[0] &&
            individualLessonRes?.lesson_content?.videos[0].url
          ) {
            setShowVideo(true);
          }
          setIndividualLesson(individualLessonRes);
          let contQuiz: number = 0;
          let videoQuiz: number = 0;
          if (
            individualLessonRes?.lesson_content?.quizzes &&
            individualLessonRes?.lesson_content?.quizzes?.length > 0 &&
            individualLessonRes?.lesson_content?.quizzes[0]?.questions?.length
          ) {
            contQuiz =
              contQuiz +
              individualLessonRes?.lesson_content?.quizzes[0]?.questions
                ?.length;
          }

          let newResponses: getResponseByUserAndHomework_getResponseByUserAndHomework[] =
            [];
          if (
            individualLessonRes?.lesson_content?.quizzes &&
            individualLessonRes?.lesson_content?.quizzes?.length > 0 &&
            individualLessonRes?.lesson_content?.quizzes[0]?.questions?.length
          ) {
            individualLessonRes?.lesson_content?.quizzes[0]?.questions.forEach(
              (question) => {
                newResponses.push({
                  homework_id: Number(homeworkParams),
                  quiz_id: Number(
                    individualLessonRes?.lesson_content?.quizzes![0].id
                  ),
                  post_homework_response: false,
                  user_id: Number(userToken?.id),
                  lesson_id: Number(id),
                  question_id: Number(question?.id),
                  question_type: Number(question?.type_id),
                  tries: tries,
                  note: 0,
                  answers_responses: [],
                  relation_responses: [],
                  block_responses: [],
                  __typename: "Responses",
                  id: "",
                });
              }
            );
          }

          individualLessonRes?.lesson_content?.videos &&
            individualLessonRes?.lesson_content?.videos.length > 0 &&
            individualLessonRes?.lesson_content?.videos[0]?.quizzes?.forEach(
              (e) => {
                if (e.quiz?.questions?.length) {
                  videoQuiz = videoQuiz + e.quiz?.questions?.length;
                }
              }
            );

          individualLessonRes?.lesson_content?.videos &&
            individualLessonRes?.lesson_content?.videos.length > 0 &&
            individualLessonRes?.lesson_content?.videos[0]?.quizzes?.forEach(
              (quiz) => {
                quiz.quiz?.questions?.forEach((question) => {
                  newResponses.push({
                    homework_id: Number(homeworkParams),
                    quiz_id: Number(
                      individualLessonRes?.lesson_content?.quizzes![0].id
                    ),
                    user_id: Number(userToken?.id),
                    lesson_id: Number(id),
                    question_id: Number(question?.id),
                    question_type: Number(question?.type_id),
                    tries: tries,
                    note: 0,
                    post_homework_response: false,
                    answers_responses: [],
                    relation_responses: [],
                    block_responses: [],
                    __typename: "Responses",
                    id: "",
                  });
                });
              }
            );
          setContResult(0);
          setResponses(newResponses);
          setTotalVideoQuestion(videoQuiz);
          setTotalQuestion(videoQuiz + contQuiz);
          setValueQuestion(10 / (videoQuiz + contQuiz));
          setLoading(false);
        } catch (error) {
          console.log({ error });

          setOpenModalError(true);
          return error;
        }
      }
    })();
  }, [getStudentTries, getLessonsById, id, homeworkParams, userToken?.id]);

  useEffect(() => {
    (async () => {
      if (homeworkParams) {
        await studentStartLessonLog({
          origin: LessonOrigin.homework,
          itemId: Number(homeworkParams),
        });
      } else if (individualLesson?.id) {
        await studentStartLessonLog({
          origin: LessonOrigin.extra_points,
          itemId: Number(individualLesson?.id),
        });
      }
    })();
  }, [homeworkParams, studentStartLessonLog, individualLesson?.id]);

  return {
    individualLesson,
    totalQuestion,
    totalVideoQuestion,
    valueQuestion,
    showVideo,
    setShowVideo,
    loading,
    setLoading,
    openModalError,
    onCloseModalDelete,
    openModalLinkWrong,
    openModalRedactionAlreadyDone,
    statusHomework,
    homeworkParams,
    responses,
    setResponses,
    setContQuestion,
    contQuestion,
    contQuestionQuiz,
    setContQuestionQuiz,
    userToken,
    result,
    setContResult,
    contResult,
    setStudentQuestionResult,
    studentQuestionResult,
    callResult,
    sound,
    setSound,
  };
};

export const voiceLanguageSelector = (subject_id: number) => {
  if (subject_id === 16) {
    return "en-US-Neural2-J";
  } else {
    return "es-US-Wavenet-B";
  }
};

export const playLanguageAudio = (string: string) => {
  const audio = new Audio(string);
  audio.play();
};

export const synthesizeLanguageText = async (
  text: string,
  subject_id: number
) => {
  if (!text) return "";
  try {
    const requestData = {
      input: {
        text: text,
      },
      voice: {
        languageCode: subject_id === 16 ? "en-US" : "es-US",
        name: voiceLanguageSelector(subject_id),
      },
      audioConfig: {
        audioEncoding: "MP3",
        speakingRate: subject_id === 16 ? 0.8 : 1,
      },
    };

    const response = await axios?.post(
      "https://texttospeech.googleapis.com/v1/text:synthesize",
      requestData,
      {
        params: {
          key: process.env.REACT_APP_API_KEY_GOOGLE,
        },
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const audioURL = `data:audio/mp3;base64,${response?.data?.audioContent}`;
    playLanguageAudio(audioURL);
    return audioURL;
  } catch (error) {
    console.error("Error al realizar la solicitud:", error);
  }
};
