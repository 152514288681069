import { FC, useState } from "react";
import styles from "./bannerCardsNew.module.scss";
import { Card } from "./Card";
import Slider from "react-slick";
import bellMission from "../../../../../assets/icons/bellMission.svg";
import { getClassroomsByStudentId_getClassroomsByStudentId_homeworks as homeworkStudenProps } from "../../../../../__generated__/gql-types/getClassroomsByStudentId";
import useIsMobile from "../../../../../hooks/useIsMobile";
import { ModalError } from "../../NewQuizz/ModalSave";
import useIsTablet from "../../../../../hooks/useIsTablet";

interface CourseProps {
  setIdHomework: (e: string) => void;
  setVisibleLesson: (e: boolean) => void;
  itemFiltered: homeworkStudenProps[] | null;
}

const BannerCardsNew: FC<CourseProps> = ({
  itemFiltered,
  setVisibleLesson,
  setIdHomework,
}) => {
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const contBanner = isMobile ? 1 : isTablet ? 2 : 3;
  const [openModalError, setOpenModalError] = useState(false);
  const onCloseModalDelete = () => {
    setOpenModalError(false);
  };

  const SampleNextArrow = (props: any) => {
    const { className, style, onClick } = props;
    return (
      <div
        onClick={onClick}
        className={className}
        style={{ ...style, display: "block", marginRight: "8px" }}
      ></div>
    );
  };

  const SamplePrevArrow = (props: any) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          marginLeft: "-10px",
        }}
        onClick={onClick}
      ></div>
    );
  };

  const settings = {
    dots: false,
    infinite: false,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 3818,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1700,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1156,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1011,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className={styles.container}>
      <div className={styles.sliderContainer}>
        <div className={styles.missionContainer}>
          <img alt="bell" src={bellMission} className={styles.bellImg} />
          <h3 className={styles.missionTitle}>
            {itemFiltered?.length}{" "}
            {itemFiltered?.length === 1
              ? " misión pendiente"
              : "misiones pendientes"}
          </h3>
        </div>

        <div className={styles.sliderSubContainer}>
          {itemFiltered && itemFiltered?.length > contBanner ? (
            <Slider {...settings}>
              {itemFiltered?.map((element, indexCard) => {
                return (
                  <Card
                    homework={element}
                    key={indexCard}
                    setIdHomework={setIdHomework}
                    setVisibleLesson={setVisibleLesson}
                  />
                );
              })}
            </Slider>
          ) : (
            <div className={styles.sliderContentContainer}>
              {itemFiltered?.map((element, indexCard) => {
                return (
                  <Card
                    homework={element}
                    key={indexCard}
                    setIdHomework={setIdHomework}
                    setVisibleLesson={setVisibleLesson}
                  />
                );
              })}
            </div>
          )}
        </div>
      </div>
      <ModalError
        onCloseModalDelete={onCloseModalDelete}
        open={openModalError}
      ></ModalError>
    </div>
  );
};

export default BannerCardsNew;
