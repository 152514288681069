import { Link, useLocation } from "react-router-dom";
import styles from "./siderMobile.module.scss";
import useAuth from "../../../../context/useAuth";

// Teacher Icons
import teacherIcon from "../../../../assets/icons/teacher/graduationCap/graduationCap.svg";
import teacherIconSelected from "../../../../assets/icons/teacher/graduationCap/graduationCap-selected.svg";
import bookIcon from "../../../../assets/icons/teacher/book/book.svg";
import bookIconSelected from "../../../../assets/icons/teacher/book/book-selected.svg";
import pointsMobile from "../../../../assets/icons/siderStudent/test.svg";
import pointsMobileSelected from "../../../../assets/icons/siderStudent/pointsMobileSelected.svg";

import avatarOn from "../../../../assets/icons/siderStudent/avatar_on.png";
import avatarOff from "../../../../assets/icons/siderStudent/avatar_off.png";
import misionesOff from "../../../../assets/icons/siderStudent/misiones_off.png";
import misionesOn from "../../../../assets/icons/siderStudent/misiones_on.png";
import MyProfile from "../../../../assets/icons/MyProfile.svg";
import MyProfileSelected from "../../../../assets/icons/sider/avatarSider.png";
import { useEffect, useState } from "react";
import { getUserByEmail_getUserByEmail } from "../../../../__generated__/gql-types/getUserByEmail";
import { DrawerNavigation } from "./DrawerNavigation";

interface PropsSider {
  path: string;
  setPath: (e: string) => void;
  userDb: getUserByEmail_getUserByEmail | null | undefined;
}
const SiderMobile = ({ path, setPath, userDb }: PropsSider) => {
  const { userToken } = useAuth();

  const location = useLocation();
  const [visible, setVisible] = useState(false);
  const studentShop = userDb?.schools[0].view_shop;
  const activeButton =
    path === "11" ||
    path === "13" ||
    path === "17" ||
    path === "18" ||
    path === "24" ||
    path === "15"
      ? true
      : false;

  const onClose = () => {
    setVisible(false);
  };
  useEffect(() => {
    if (userToken?.role === "teacher" || userToken?.role === "coordinator") {
      location.pathname.includes("/myclasses") && setPath("2");
      location.pathname.includes("/2017") && setPath("13");
      location.pathname.includes("/nem") && setPath("11");
      location.pathname.includes("/2425") && setPath("17");
      location.pathname.includes("/calendary") && setPath("4");
      location.pathname.includes("/means") && setPath("5");
      location.pathname.includes("/points") && setPath("10");
      location.pathname.includes("/help") && setPath("9");
      location.pathname.includes("/english") && setPath("24");
    }
    if (userToken?.role === "student") {
      location.pathname.includes("/mymissions") && setPath("6");
      location.pathname.includes("/store") && setPath("19");
      location.pathname.includes("/2017") && setPath("13");
      location.pathname.includes("/nem") && setPath("11");
      location.pathname.includes("/2425") && setPath("18");
      location.pathname.includes("/myachievements") && setPath("8");
      location.pathname.includes("/english") && setPath("24");
      location.pathname.includes("/means") && setPath("5");
    }
  }, [location.pathname, userToken, setPath]);

  return (
    <div className={styles.siderMobile}>
      {userToken?.role === "student" ? (
        <>
          <Link
            to="/mymissions"
            className={styles.centerImage}
            onClick={() => {
              setPath("6");
              onClose();
            }}
          >
            <div
              className={
                path === "6"
                  ? styles.containerImgSelected
                  : styles.containerNotImgSelected
              }
            >
              <img
                src={path === "6" ? misionesOn : misionesOff}
                alt="Mis misiones"
              />
            </div>
            <div className={path === "6" ? styles.itemSelected : styles.item}>
              Mis misiones
            </div>
          </Link>

          <div className={styles.centerImage} onClick={() => setVisible(true)}>
            <div
              className={
                activeButton
                  ? styles.containerImgSelected
                  : styles.containerNotImgSelected
              }
            >
              <img
                src={activeButton ? bookIconSelected : bookIcon}
                alt="La Nem"
              />
            </div>
            <div className={activeButton ? styles.itemSelected : styles.item}>
              Cursos
            </div>
          </div>

          {studentShop && (
            <Link
              to="/store"
              className={styles.centerImage}
              onClick={() => {
                setPath("19");
                onClose();
              }}
            >
              <div
                className={
                  path === "19"
                    ? styles.containerImgSelected
                    : styles.containerNotImgSelected
                }
              >
                <img
                  src={path === "19" ? avatarOn : avatarOff}
                  alt="Programa 2425"
                />{" "}
              </div>
              <div
                className={path === "19" ? styles.itemSelected : styles.item}
              >
                Avatar
              </div>
            </Link>
          )}

          <Link
            to="/myProfile"
            className={styles.centerImage}
            onClick={() => {
              setPath("12");
              onClose();
            }}
          >
            <div
              className={
                path === "12"
                  ? styles.containerImgSelected
                  : styles.containerNotImgSelected
              }
            >
              <img
                src={path === "12" ? pointsMobileSelected : MyProfile}
                alt="Mis clases"
              />{" "}
            </div>
            <p className={path === "12" ? styles.itemSelected : styles.item}>
              Mi perfil
            </p>
          </Link>
        </>
      ) : (
        <>
          <div
            onClick={() => {
              setPath("2");
              onClose();
            }}
          >
            <Link to="/myclasses" className={styles.centerImage}>
              <div
                className={
                  path === "2"
                    ? styles.containerImgSelected
                    : styles.containerNotImgSelected
                }
              >
                <img
                  src={path === "2" ? teacherIconSelected : teacherIcon}
                  alt="Mis Clases"
                />
              </div>

              <p className={path === "2" ? styles.itemSelected : styles.item}>
                Clases
              </p>
            </Link>
          </div>

          <div
            style={{ backgroundColor: "#fff" }}
            className={styles.centerImage}
            onClick={() => setVisible(true)}
          >
            <div
              className={
                activeButton
                  ? styles.containerImgSelected
                  : styles.containerNotImgSelected
              }
            >
              <img
                src={activeButton ? bookIconSelected : bookIcon}
                alt="La Nem"
              />
            </div>
            <p className={activeButton ? styles.itemSelected : styles.item}>
              Programas
            </p>
          </div>

          <Link
            to="/points"
            className={styles.centerImage}
            onClick={() => {
              setPath("10");
              onClose();
            }}
          >
            <div
              className={
                path === "10"
                  ? styles.containerImgSelected
                  : styles.containerNotImgSelected
              }
            >
              <img
                src={path === "10" ? pointsMobileSelected : pointsMobile}
                alt="Lecciones"
              />
            </div>
            <p className={path === "10" ? styles.itemSelected : styles.item}>
              Puntos
            </p>
          </Link>
        </>
      )}
      {visible && userDb && (
        <DrawerNavigation onClose={onClose} visible={visible} userDb={userDb} />
      )}
    </div>
  );
};

export default SiderMobile;
