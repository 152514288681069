import React, { FC } from "react";
import styles from "./drawerAnswer.module.scss";
import { Modal } from "antd";

interface PropsDrawerAnswer {
  openAnswer: boolean;
  closeAnswer: () => void;
  answer: string;
}

const DrawerAnswer: FC<PropsDrawerAnswer> = ({
  openAnswer,
  closeAnswer,
  answer,
}) => {
  return (
    <Modal
      open={openAnswer}
      closable={true}
      width={"625px"}
      onCancel={closeAnswer}
      footer={""}
      centered
      className={"DrawerAnswer"}
    >
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.popUpContainer}>
            <div className={styles.columnRM}>R.M.</div>
            <div className={styles.columnResponse}>{answer}</div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DrawerAnswer;
