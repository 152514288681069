import { FC, useState, useEffect } from "react";
import styles from "./lessons.module.scss";
import { getLessonById_getLessonById } from "../../__generated__/gql-types/getLessonById";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { Dosifications } from "./Dosification/Dosifications";
import { functionReturnIdSubject } from "./Utils";
import { ModalError } from "../Students/Missions/NewQuizz/ModalSave";
import { DrawerLessonNew } from "../Lessons/Drawer";
import { GetCoursesForNewContent_getCoursesForNewContent } from "../../__generated__/gql-types/GetCoursesForNewContent";
import useGetCoursesLiteracy from "../../api/useGetCoursesLiteracy";

const Reading: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  let { parametro1, parametro2, lessonIdParams } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const queryParams = new URLSearchParams(location.search);
  const gradeParams = queryParams.get("grade");
  const courseParams = queryParams.get("course");
  // Obtener el valor de un parámetro de la consulta
  const getCoursesLiteracy = useGetCoursesLiteracy();
  // const getCoursesForNewContent = useGetCoursesForNewContent();
  const [coursesState, setCoursesState] = useState<
    GetCoursesForNewContent_getCoursesForNewContent[]
  >([]);

  const [visible, setVisible] = useState<boolean>(
    lessonIdParams ? true : false
  );

  // Query Lessons States
  const [grade, setGrade] = useState<number>(
    gradeParams ? Number(gradeParams) : 6
  );

  const [subjectId, setSubjectId] = useState<string>(
    functionReturnIdSubject(parametro2)
  );
  const [selectAcordion, setSelectAcordion] = useState<string>(
    courseParams ?? ""
  );

  // Pagination
  const [lessonId, setLessonId] = useState<string>(lessonIdParams ?? "");

  const [individualLesson, setIndividualLesson] =
    useState<getLessonById_getLessonById | null>(
      location.state?.lesson ?? null
    );

  const [loadingDosification, setLoadingDosification] = useState(false);
  const [lessonViewSelected, setLessonViewSelected] = useState<string>(
    parametro1 ?? "dosification"
  );
  const [openModalError, setOpenModalError] = useState(false);
  const onCloseModalDelete = () => {
    setOpenModalError(false);
  };

  // Individual Lesson Drawer Functions
  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setSearchParams((prevParams) => {
      const params = new URLSearchParams(prevParams);
      params.delete("key");

      return params.toString();
    });
    setVisible(false);
    setLessonId("");
    setIndividualLesson(null);
  };

  // get Curses from dosification
  useEffect(() => {
    (async () => {
      setLoadingDosification(true);
      try {
        const response = await getCoursesLiteracy({
          getCoursesInputLiteracy: { grade_id: grade },
        });
        if (response) {
          const sortCoursesReading = response.sort((a, b) => {
            if (a.week_reading && b.week_reading)
              return a.week_reading - b.week_reading;
            return 0;
          });
          setCoursesState(sortCoursesReading);
        }

        setLoadingDosification(false);
      } catch (error) {
        setOpenModalError(true);

        console.log(error);
        setLoadingDosification(false);
      }
    })();
  }, [subjectId, grade, getCoursesLiteracy]);

  // changing params
  useEffect(() => {
    const nuevaUrl =
      lessonId !== ""
        ? `/reading/${lessonViewSelected}/reading/${lessonId}?grade=${grade}&course=${selectAcordion}`
        : `/reading/${lessonViewSelected}/reading?grade=${grade}&course=${selectAcordion}`;
    navigate(nuevaUrl);
  }, [
    navigate,
    subjectId,
    lessonViewSelected,
    lessonId,
    grade,
    selectAcordion,
  ]);

  return (
    <div className={styles.containerLesson}>
      <div className={styles.filtersAndTableContainer}>
        {/* VISTA DE LECCIONES */}
        <div className={styles.tableContainer}>
          <Dosifications
            selectAcordion={selectAcordion}
            setSelectAcordion={setSelectAcordion}
            loadingDosification={loadingDosification}
            coursesState={coursesState}
            grade_id={grade === 0 ? 6 : grade}
            showDrawer={showDrawer}
            setLessonId={setLessonId}
            setIndividualLesson={setIndividualLesson}
            setGrade={setGrade}
          />
        </div>
      </div>

      <DrawerLessonNew
        onClose={onClose}
        visible={visible}
        lessonViewSelected={lessonViewSelected}
        back={"lessons"}
        individualLesson={individualLesson}
      />
      <ModalError
        onCloseModalDelete={onCloseModalDelete}
        open={openModalError}
      ></ModalError>
    </div>
  );
};

export default Reading;
