import { useCallback } from "react";
import {
  ApolloError,
  ApolloQueryResult,
  useApolloClient,
  gql,
} from "@apollo/client";

import {
  getPDACourses,
  getPDACoursesVariables,
} from "../__generated__/gql-types/getPDACourses";

const UseGetPDACourse = () => {
  const client = useApolloClient();

  return useCallback(
    async (input: getPDACoursesVariables) => {
      const result: ApolloQueryResult<getPDACourses> = await client.query({
        query: GET_PDA_COURSES,
        fetchPolicy: "no-cache",
        variables: input,
      });

      if (result.error) {
        throw new ApolloError({
          graphQLErrors: result.errors,
        });
      }

      return result.data.getPDACourses;
    },
    [client]
  );
};

export default UseGetPDACourse;

const GET_PDA_COURSES = gql`
  query getPDACourses($getCoursesSearchArgs: GetPDACoursesSearchArgs) {
    getPDACourses(getCoursesSearchArgs: $getCoursesSearchArgs) {
      id
      grade_id
      science
      math
      title
      tags {
        name
      }
      content
      pda
      subject
    }
  }
`;
