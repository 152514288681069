import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { DeleteStudentEvidenceEventVariables } from "../__generated__/gql-types/DeleteStudentEvidenceEvent";

const UseDeleteStudentEvidence = () => {
  const client = useApolloClient();

  return useCallback(
    async (homeworkId: DeleteStudentEvidenceEventVariables) => {
      return await client.mutate({
        mutation: DELETE_STUDENT_EVIDENCE,
        fetchPolicy: "no-cache",
        variables: homeworkId,
      });
    },
    [client]
  );
};
export default UseDeleteStudentEvidence;

const DELETE_STUDENT_EVIDENCE = gql`
  mutation DeleteStudentEvidenceEvent($homeworkId: Int!) {
    deleteStudentEvidenceEvent(homework_id: $homeworkId) {
      id
    }
  }
`;
