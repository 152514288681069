import { FC, useState, useEffect } from "react";
import styles from "./nem.module.scss";
import { getLessonById_getLessonById } from "../../__generated__/gql-types/getLessonById";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import UseClassroomsByTeacher from "../../api/useGetClassroomsByTeacher";
import { Dosifications } from "./Dosification/Dosifications";
import { NemFilters } from "./NewFilters";
import { DrawerLessonNew } from "../Lessons/Drawer";
import UseGetCoursesBySubjectIdProgram2425 from "../../api/useGetCoursesBySubjectIdV2Program2425";
import useAuth from "../../context/useAuth";
import { getCoursesBySubjectIdProgram2425_getCoursesBySubjectIdProgram2425 } from "../../__generated__/gql-types/getCoursesBySubjectIdProgram2425";

const Program2425: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  let { parametro1, parametro2, lessonIdParams } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const queryParams = new URLSearchParams(location.search);
  const { userToken } = useAuth();
  const courseParams = queryParams.get("course");
  const gradeParams = queryParams.get("grade");
  const subjectParams = queryParams.get("subject");
  //   // Obtener el valor de un parámetro de la consulta
  const getClassroomsByTeacher = UseClassroomsByTeacher();
  const getCoursesBySubjectIdProgram2425 =
    UseGetCoursesBySubjectIdProgram2425();
  const [coursesState, setCoursesState] = useState<
    getCoursesBySubjectIdProgram2425_getCoursesBySubjectIdProgram2425[]
  >([]);
  const [visible, setVisible] = useState<boolean>(
    lessonIdParams ? true : false
  );

  const [subjectId, setSubjectId] = useState<string[]>(
    subjectParams
      ? subjectParams?.split(",")
        ? subjectParams?.split(",")
        : [subjectParams]
      : ["1"]
  );

  //   // Query Lessons States
  const [grade, setGrade] = useState<number>(
    userToken?.role === "student" &&
      userToken?.groups &&
      userToken?.groups[0] &&
      userToken?.groups[0].grade_id
      ? userToken?.groups[0].grade_id
      : userToken?.schools[0].name === "Colegio Iluminación"
      ? 4
      : userToken?.schools[0].name === "COLEGIO PROGRESO"
      ? 5
      : gradeParams
      ? Number(gradeParams)
      : 6
  );

  const [month, setMonth] = useState<number>(
    parametro2 ? Number(parametro2) : 1
  );
  const [selectAcordion, setSelectAcordion] = useState<string>(
    courseParams ?? ""
  );

  const [individualLesson, setIndividualLesson] =
    useState<getLessonById_getLessonById | null>(null);
  const [lessonId, setLessonId] = useState<string>(lessonIdParams ?? "");
  const [loadingDosification, setLoadingDosification] = useState(false);
  const [lessonViewSelected, setLessonViewSelected] = useState<string>(
    parametro1 ?? "dosification"
  );

  // Individual Lesson Drawer Functions
  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setSearchParams((prevParams) => {
      const params = new URLSearchParams(prevParams);
      params.delete("key");

      return params.toString();
    });
    setVisible(false);
    setLessonId("");
    setIndividualLesson(null);
  };

  // get Curses from dosification
  useEffect(() => {
    (async () => {
      setLoadingDosification(true);
      const arrayDeNumeros = subjectId.map((str) => Number(str));
      try {
        const response = await getCoursesBySubjectIdProgram2425({
          getCoursesBySubjectIdInputProgram2425: {
            grade_id: grade,
            threemester_2425: Number(month),
            subject_ids: arrayDeNumeros,
          },
        });
        if (response) {
          if (userToken?.schools[0].name === "Legionarios") {
            const filteredSpanishCourses = response.filter(
              (course) => course.subject_id === 2
            );
            setCoursesState(
              filteredSpanishCourses.sort((a, b) => {
                if (a.week && b.week) return a.week - b.week;
                return 0;
              })
            );
          } else if (userToken?.schools[0].name === "Colegio Inglaterra") {
            const filteredCourses = response.filter(
              (course) =>
                course.id !== "613" &&
                course.id !== "650" &&
                course.id !== "604"
            );
            setCoursesState(
              filteredCourses.sort((a, b) => {
                if (a.week && b.week) return a.week - b.week;
                return 0;
              })
            );
          } else {
            setCoursesState(
              response.sort((a, b) => {
                if (a.week && b.week) return a.week - b.week;
                return 0;
              })
            );
          }
        }

        setLoadingDosification(false);
      } catch (error) {
        console.log(error);
        setLoadingDosification(false);
      }
    })();
  }, [month, grade, getCoursesBySubjectIdProgram2425, subjectId, userToken]);

  // changing params
  useEffect(() => {
    const subjectAsString = subjectId.join(",");
    const nuevaUrl =
      lessonId !== ""
        ? `/2425/${lessonViewSelected}/${month}/${lessonId}?grade=${grade}&course=${selectAcordion}&subject=${subjectAsString}`
        : `/2425/${lessonViewSelected}/${month}?grade=${grade}&course=${selectAcordion}&subject=${subjectAsString}`;
    navigate(nuevaUrl);
  }, [
    navigate,
    month,
    lessonViewSelected,
    lessonId,
    grade,
    selectAcordion,
    subjectId,
  ]);

  return (
    <div className={styles.containerLesson}>
      <div className={styles.filtersAndTableContainer}>
        <div className={styles.tableContainer}>
          <NemFilters
            setLessonViewSelected={setLessonViewSelected}
            setSubjectId={setSubjectId}
            subjectId={subjectId}
            loadingDosification={loadingDosification}
          />
          <Dosifications
            selectAcordion={selectAcordion}
            setSubjectId={setSubjectId}
            setSelectAcordion={setSelectAcordion}
            loadingDosification={loadingDosification}
            coursesState={coursesState}
            grade_id={grade === 0 ? 6 : grade}
            month={month}
            showDrawer={showDrawer}
            setMonth={setMonth}
            setLessonId={setLessonId}
            setIndividualLesson={setIndividualLesson}
            setGrade={setGrade}
          />
        </div>
      </div>

      <DrawerLessonNew
        onClose={onClose}
        visible={visible}
        lessonViewSelected={lessonViewSelected}
        back={"nem"}
        individualLesson={individualLesson}
      />
    </div>
  );
};

export default Program2425;
