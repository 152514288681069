import { Skeleton } from "antd";
import React from "react";
import styles from "./spiner.module.scss";

const SpinnerMissions = () => {
  return (
    <div className={styles.spinner}>
      <Skeleton active paragraph={{ rows: 1 }} style={{ margin: "24px 0" }} />
      <Skeleton active paragraph={{ rows: 5 }} style={{ margin: "24px 0" }} />
      <Skeleton active paragraph={{ rows: 7 }} style={{ margin: "24px 0" }} />
    </div>
  );
};

export default SpinnerMissions;
