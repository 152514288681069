import React, { useEffect, useState } from "react";
import styles from "./missions.module.scss";
// Components
import { Banner } from "../Shared/Banner";
import { Header } from "../../Layout/Header";

// API && Types
import { getClassroomsByStudentIdV2_getClassroomsByStudentIdV2 as PropsClassRooms } from "./../../../__generated__/gql-types/getClassroomsByStudentIdV2";
import { getHomeworksByClassroom_getHomeworksByClassroom as PropsHomeworks } from "./../../../__generated__/gql-types/getHomeworksByClassroom";
import useIsMobile from "../../../hooks/useIsMobile";
import { ModalError } from "./NewQuizz/ModalSave";
import { BannerCardsNew } from "./SingleCourse/BannerCards";
import { Tabs } from "./SingleCourse/BannerCards/Tabs";
import { useAppSelector } from "../../../redux/hooks";
import { DependencyRefresh } from "../../../redux/store/store";
import useAuth from "../../../context/useAuth";
import { encontrarValorMayor } from "./utils";
import UseClassroomsByStudentIdV2 from "../../../api/useGetClassroomsByStudentIdV2";
import UseGetHomeworksByClassroom from "../../../api/useGetHomeworksByClassroom";
import { SpinnerMissions } from "./SpinnerMissions";
import { DrawerHomework } from "./DrawerHomeworkStudent";
import { useLocation } from "react-router-dom";

const Missions = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const homeworkIdParams = queryParams.get("homeworkId");
  const getClassroomsByStudentIdV2 = UseClassroomsByStudentIdV2();
  const getHomeworksByClassroom = UseGetHomeworksByClassroom();
  const isMobile = useIsMobile();
  const dependencyRefresh = useAppSelector(DependencyRefresh);
  const { userToken } = useAuth();
  const [isLoadingClassrooms, setIsLoadingClassrooms] =
    useState<boolean>(false);
  const [isLoadingHomeworks, setIsLoadingHomeworks] = useState<boolean>(false);
  const [classRooms, setClassRooms] = useState<PropsClassRooms[] | null>();
  const [homeworks, setHomeworks] = useState<(PropsHomeworks | null)[]>([]);
  const [misionPending, setMisionPending] = useState<PropsHomeworks[]>([]);
  const [openModalError, setOpenModalError] = useState(false);
  const [contReviewStudent, setContReviewStudent] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [completedTasks, setCompletedTasks] = useState<PropsHomeworks[] | null>(
    null
  );
  const [visibleLesson, setVisibleLesson] = useState(
    homeworkIdParams ? true : false
  );
  const [idHomework, setIdHomework] = useState(homeworkIdParams ?? "");

  const [needReviewTasks, setNeedReviewTasks] = useState<
    PropsHomeworks[] | null
  >(null);
  const [redactionTasks, setRedactionTasks] = useState<PropsHomeworks[] | null>(
    null
  );
  const onCloseLesson = () => {
    setVisibleLesson(false);
  };

  const onCloseModalDelete = () => {
    setOpenModalError(false);
  };

  useEffect(() => {
    const functionGetClassroom = async (intentoActual = 1, maxIntentos = 5) => {
      setIsLoadingClassrooms(true);
      try {
        const classRoomRes = await getClassroomsByStudentIdV2();

        setClassRooms(classRoomRes);
        setIsLoadingClassrooms(false);
      } catch (error) {
        if (intentoActual < maxIntentos) {
          console.log(`Intento fallido ${intentoActual}, reintentando...`);
          functionGetClassroom(intentoActual + 1, maxIntentos); // Reintenta recursivamente
        } else {
          console.log("Se alcanzó el máximo de intentos");
          setIsLoadingClassrooms(false);
          setErrorMessage("El usuario no tiene ningún grupo asignado.");
          setOpenModalError(true);
        }
      }
    };

    functionGetClassroom(); // Llamada inicial con el primer intento
  }, [getClassroomsByStudentIdV2]);

  useEffect(() => {
    const fetchHomeworksWithRetry = async (
      intentoActual = 1,
      maxIntentos = 5
    ) => {
      setIsLoadingHomeworks(true);
      try {
        if (classRooms) {
          const homeworks = classRooms.map((classroom) => {
            return getHomeworksByClassroom({
              classroomId: Number(classroom.id),
            });
          });

          const homeworksResolveSnap = await Promise.all(homeworks);
          const homeworksResolve = homeworksResolveSnap
            .flat()
            .filter((homework) => homework);

          setHomeworks(homeworksResolve);

          let cont = 0;
          let misionPen: PropsHomeworks[] = [];
          let misionNeed: PropsHomeworks[] = [];
          let misionCompleted: PropsHomeworks[] = [];
          let misionRedactionFinished: PropsHomeworks[] = [];

          homeworksResolve?.forEach((homework) => {
            const maxResult = encontrarValorMayor(
              homework?.result ?? 0,
              homework?.post_homework_result ?? 0
            );
            if (
              homework?.users?.length === 0 ||
              homework?.users?.find(
                (user) => Number(user.id) === Number(userToken?.id)
              )
            ) {
              if (
                homework?.id &&
                homework?.lessons &&
                homework?.lessons[0]?.lesson_type === "redaction" &&
                !homework?.redaction_review_by_student &&
                homework?.redaction_correction
              ) {
                cont++;
              }

              if (
                homework?.lessons &&
                homework.lessons[0].lesson_type === "redaction" &&
                homework.cta_button !== "TERMINAR" &&
                homework.cta_button !== "TERMINAREVIDENCIA" &&
                (homework?.finished || homework.cta_button === "NO ENTREGADA")
              ) {
                misionRedactionFinished.push(homework);
              }

              if (
                homework.cta_button === "TERMINAR" ||
                homework.cta_button === "TERMINAREVIDENCIA" ||
                (homework.status === "inCourse" &&
                  homework.finished !== true &&
                  (!homework?.tries ||
                    (homework?.tries &&
                      homework?.tries - Number(homework?.studentTries) > 0) ||
                    (homework.needs_file_evidence &&
                      !homework.studentEvidenceEvent)))
              ) {
                misionPen.push(homework);
              }

              if (
                (maxResult &&
                  maxResult >= 6 &&
                  homework?.finished && // homework.finished hace referencia al estado de la tarea en relación al estudiante (si la terminó o no)
                  homework.lessons &&
                  homework.lessons[0].lesson_type !== "redaction") ||
                (homework.lessons &&
                  homework.lessons[0].lesson_type !== "redaction" &&
                  homework.status === "finished" && // homework.status hace referencia al estado de la hw (inCourse o finished)
                  maxResult &&
                  maxResult >= 6)
              ) {
                misionCompleted.push(homework);
              }
              if (
                (!maxResult || maxResult < 6) &&
                (homework?.status === "finished" || homework?.finished) &&
                homework.lessons &&
                homework.lessons[0].lesson_type !== "redaction"
              ) {
                misionNeed.push(homework);
              }
            }
          });

          setContReviewStudent(cont);
          setRedactionTasks(
            misionRedactionFinished.sort(
              (a, b) =>
                new Date(b?.completion_date).getTime() -
                new Date(a?.completion_date).getTime()
            )
          );
          setCompletedTasks(
            misionCompleted.sort(
              (a, b) =>
                new Date(b?.completion_date).getTime() -
                new Date(a?.completion_date).getTime()
            )
          );
          setNeedReviewTasks(
            misionNeed.sort(
              (a, b) =>
                new Date(b?.completion_date).getTime() -
                new Date(a?.completion_date).getTime()
            )
          );

          misionPen.sort((a, b) => {
            const dateA = new Date(
              a?.end_date?.split(" ")[0].split("/").reverse().join("-") +
                "T" +
                a?.end_date?.split(" ")[1]
            ).getTime();
            const dateB = new Date(
              b?.end_date?.split(" ")[0].split("/").reverse().join("-") +
                "T" +
                b?.end_date?.split(" ")[1]
            ).getTime();
            return dateA - dateB;
          });

          setMisionPending(misionPen);
        }
        setIsLoadingHomeworks(false);
      } catch (error) {
        if (intentoActual < maxIntentos) {
          console.log(`Intento fallido ${intentoActual}, reintentando...`);
          fetchHomeworksWithRetry(intentoActual + 1, maxIntentos); // Reintenta recursivamente
        } else {
          console.log("Se alcanzó el máximo de intentos");
          setIsLoadingHomeworks(false);
          setOpenModalError(true);
        }
      }
    };

    fetchHomeworksWithRetry(); // Llamada inicial con el primer intento
  }, [getHomeworksByClassroom, userToken, dependencyRefresh, classRooms]);
  return (
    <div className={styles.contentLayout}>
      {classRooms && (
        <React.Fragment>
          {!isMobile && <Header userDb={userToken} title={"Mis Misiones"} />}
          {isLoadingClassrooms ? (
            <SpinnerMissions />
          ) : (
            <Banner homeworks={homeworks} classRooms={classRooms} />
          )}
          {isLoadingHomeworks ? (
            <SpinnerMissions />
          ) : (
            <React.Fragment>
              <BannerCardsNew
                itemFiltered={misionPending}
                setIdHomework={setIdHomework}
                setVisibleLesson={setVisibleLesson}
              />
              <Tabs
                completedTasks={completedTasks}
                needReviewTasks={needReviewTasks}
                redactionTasks={redactionTasks}
                contReviewStudent={contReviewStudent}
                setIdHomework={setIdHomework}
                setVisibleLesson={setVisibleLesson}
              />
            </React.Fragment>
          )}
        </React.Fragment>
      )}
      <ModalError
        onCloseModalDelete={onCloseModalDelete}
        open={openModalError}
        message={errorMessage}
      ></ModalError>
      {visibleLesson && (
        <DrawerHomework
          visibleLesson={visibleLesson}
          onCloseLesson={onCloseLesson}
          homework_id={idHomework}
        />
      )}
    </div>
  );
};

export default Missions;
