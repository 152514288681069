import React, { FC } from "react";
import { getLessonById_getLessonById } from "../../../../../../__generated__/gql-types/getLessonById";
import styles from "./Headerinteligent.module.scss";
import { Tag } from "antd";

import closeIcon from "../../../../../../assets/icons/closeHeaderInteligent.png";
interface PropsHeader {
  individualLesson: getLessonById_getLessonById;
  onCloseDrawer: () => void;
}

const HeaderInteligentTeacher: FC<PropsHeader> = ({
  onCloseDrawer,
  individualLesson,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.titleTask}>{individualLesson?.title ?? ""}</div>
      <div className={styles.secondTaskColumn}>
        <div className={styles.tagsAndTitle}>
          <Tag color="white" className={styles.tagsGrade}>
            {individualLesson?.grade?.grade}°
          </Tag>
          <Tag color="white" className={styles.tagsGrade}>
            Semana {individualLesson?.week}
          </Tag>
          <img src={closeIcon} alt="closeIcon" onClick={onCloseDrawer} />
        </div>
      </div>
    </div>
  );
};

export default HeaderInteligentTeacher;
