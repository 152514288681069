import React, { FC } from "react";
import styles from "./titleInteligentStudent.module.scss";
import { getLessonById_getLessonById } from "../../../../../__generated__/gql-types/getLessonById";
import closeIcon from "../../../../../assets/icons/others/close-icon.svg";
import defaultImage from "../../../../../assets/images/defaultImage.svg";

interface TitleIntegentStudentProps {
  individualLesson: getLessonById_getLessonById;
  onCloseDrawer: () => void;
}
const TitleInteligentStudent: FC<TitleIntegentStudentProps> = ({
  individualLesson,
  onCloseDrawer,
}) => {
  return (
    <div className={styles.task}>
      <div className={styles.firstTaskColumn}>
        <div className={styles.pictureContainer}>
          <img
            src={
              individualLesson &&
              individualLesson.lesson_content?.videos?.length &&
              individualLesson.lesson_content?.videos[0]?.imageUrl
                ? individualLesson.lesson_content?.videos[0].imageUrl
                : defaultImage
            }
            alt="lesson"
          />
        </div>
      </div>
      <div className={styles.secondTaskColumn}>
        <div className={styles.tagsAndTitle}>
          <h3 className={styles.titleTask}>{individualLesson?.title ?? ""}</h3>
        </div>
        <div>
          <h3 className={styles.descriptionLesson}>
            {individualLesson?.description}
          </h3>
        </div>
      </div>
      <div className={styles.thirdyTaskColumn}>
        <img
          src={closeIcon}
          alt=""
          className={styles.crossImg}
          onClick={onCloseDrawer}
        />
      </div>
    </div>
  );
};

export default TitleInteligentStudent;
