import React, { useRef, useEffect, useState } from "react";
import styles from "./calendar.module.scss";
import { Header } from "../Layout/Header";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import timeGridPlugin from "@fullcalendar/timegrid";
import { EventContentArg } from "@fullcalendar/core";
import UseClassroomsByTeacher from "../../api/useGetClassroomsByTeacher";
import {
  getClassroomsByTeacher_getClassroomsByTeacher_classroom_lessons,
  getClassroomsByTeacher_getClassroomsByTeacher_homeworks,
} from "../../__generated__/gql-types/getClassroomsByTeacher";
import moment from "moment";
import esLocale from "@fullcalendar/core/locales/es";
import { toLocalDate } from "../../utils/functions";
import { DetailsHomeworkDrawer } from "../SingleClass/DetailsHomeworkDrawer";
import { DrawerLessonNew } from "../Lessons/Drawer";
import { getLessonById_getLessonById } from "../../__generated__/gql-types/getLessonById";
import UseGetLessonsById from "../../api/useGetLessonsById";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../redux/hooks";
import { DependencyRefresh } from "../../redux/store/store";
interface MyEventData {
  subject: string;
  title: string;
  start: string;
  end?: string;
  [key: string]: any; // permitir otras propiedades personalizadas
}

interface PropsHomeworks
  extends getClassroomsByTeacher_getClassroomsByTeacher_homeworks {
  [key: string]: any;
}
interface PropsHomeworksLessons
  extends getClassroomsByTeacher_getClassroomsByTeacher_classroom_lessons {
  [key: string]: any;
}

interface PropsFilters {
  matematica: boolean;
  ciencias: boolean;
  español: boolean;
  ingles: boolean;
}
interface PropsSubject {
  subject: string;
  grade: string;
  name: string;
}
const Calendar = () => {
  const location = useLocation();
  const getClassroomsByTeacher = UseClassroomsByTeacher();
  const navigate = useNavigate();
  const getLessonsById = UseGetLessonsById();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(
    location?.state?.lesson ? false : true
  );
  const dependencyRefresh = useAppSelector(DependencyRefresh);
  const [visibleDetailLesson, setVisibleDetailLesson] = useState(
    location.state?.openLessonDrawer ?? false
  );
  const calendarRef = useRef<FullCalendar | null>(null);
  const [homeworks, setHomeworks] = useState<
    (PropsHomeworks | PropsHomeworksLessons | null)[]
  >([]);
  const [homeworksFilter, setHomeworksFilter] = useState<
    (PropsHomeworks | PropsHomeworksLessons | null)[]
  >([]);
  const [taskId, setTaskId] = useState<string | null>(null);
  const [subjects, setSubjects] = useState<PropsSubject[]>([]);
  const [individualLesson, setIndividualLesson] =
    useState<getLessonById_getLessonById | null>(
      location.state?.lesson ?? null
    );
  const [videoLessonEndDate, setVideoLessonEndDate] = useState<string>();
  const [objFilter, setObjFilter] = useState<PropsFilters>({
    matematica: true,
    ciencias: true,
    español: true,
    ingles: true,
  });

  const functionSetIndividualLesson = async (lessonId: number) => {
    const individualLessonRes = await getLessonsById({
      lessonId: `${lessonId}`,
    });
    setIndividualLesson(individualLessonRes);
  };

  const onClose = () => {
    setVisible(false);
  };
  const onCloseDetailLesson = () => {
    setVisibleDetailLesson(false);
  };
  useEffect(() => {
    (async () => {
      try {
        let newArr: PropsSubject[] = [];
        const classRoomRes = await getClassroomsByTeacher({
          getClassroomsByTeacherIdInput: {
            searchArgs: null,
          },
        });

        let newHomeworks: (PropsHomeworks | null | PropsHomeworksLessons)[] =
          [];
        classRoomRes?.forEach((e) => {
          newArr.push({
            subject: e.program?.subject?.name!,
            grade: e.program?.grade?.grade!,
            name: e.group?.name!,
          });

          e.homeworks?.forEach((homework) => {
            let newArray: PropsHomeworks = homework!;
            newArray.subject = e.program?.subject?.id;
            newHomeworks.push(newArray);
          });

          e.classroom_lessons?.forEach((homework) => {
            let newArray: PropsHomeworksLessons = homework!;
            newArray.subject = e.program?.subject?.id;
            newHomeworks.push(newArray);
          });
        });
        console.log(newArr);
        setSubjects(newArr);
        setHomeworksFilter(newHomeworks);
        setHomeworks(newHomeworks);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [getClassroomsByTeacher, dependencyRefresh]);

  useEffect(() => {
    let newarray = [...homeworks];
    if (!objFilter?.matematica) {
      newarray = newarray.filter((e) => {
        return e?.subject !== "1";
      });
    }
    if (!objFilter?.ciencias) {
      newarray = newarray.filter((e) => {
        return e?.subject !== "4";
      });
    }
    if (!objFilter?.español) {
      newarray = newarray.filter((e) => {
        return e?.subject !== "2";
      });
    }
    if (!objFilter?.ingles) {
      newarray = newarray.filter((e) => {
        return e?.subject !== "16";
      });
    }
    setHomeworksFilter(newarray);
  }, [objFilter]);

  useEffect(() => {
    if (calendarRef.current) {
      calendarRef.current
        .getApi()
        .setOption("eventBackgroundColor", "transparent");

      calendarRef.current.getApi().setOption("eventBorderColor", "transparent");
    }
  }, []);

  const functionStylesCard = (subject: string) => {
    if (subject === "2") {
      return styles.containerEventLengua;
    } else if (subject === "1") {
      return styles.containerEventMatematica;
    } else if (subject === "4") {
      return styles.containerEventCiencias;
    } else if (subject === "16") {
      return styles.containerEventIngles;
    }
  };
  const functionStylesCardClassroom = (subject: string) => {
    if (subject === "2") {
      return styles.containerEventLenguaClassRoom;
    } else if (subject === "1") {
      return styles.containerEventMatematicaClassRoom;
    } else if (subject === "4") {
      return styles.containerEventCienciasClassRoom;
    } else if (subject === "16") {
      return styles.containerEventInglesClassRoom;
    }
  };

  const renderEventContent = (eventInfo: EventContentArg) => {
    return eventInfo.event.extendedProps.type === "Homework" ? (
      <div
        className={functionStylesCardClassroom(
          eventInfo.event.extendedProps.subject
        )}
        onClick={async () => {
          setTaskId(eventInfo.event.extendedProps.idHomework);

          setVisible(true);
        }}
      >
        {eventInfo.event.title}
        <br />
        <i className={styles.hoursWhiteClassRoom}>
          Fin.{" "}
          {moment(
            eventInfo.event.extendedProps.finaliza,
            "DD/MM/YYYY HH:mm",
            false
          ).format("DD [de] MMMM")}
        </i>
      </div>
    ) : (
      <div
        className={functionStylesCard(eventInfo.event.extendedProps.subject)}
        onClick={async () => {
          setTaskId(eventInfo.event.extendedProps.idHomework);
          setVideoLessonEndDate(eventInfo.event.extendedProps.finaliza);
          await functionSetIndividualLesson(
            eventInfo.event.extendedProps.idLesson
          );
          setLoading(false);
          setVisibleDetailLesson(true);
        }}
      >
        {eventInfo.event.title}
        <br />

        <i className={styles.hoursWhite}>
          {moment(
            eventInfo.event.extendedProps.finaliza,
            "DD/MM/YYYY HH:mm",
            false
          ).format("Ha")}
        </i>
      </div>
    );
  };

  const newHomeworks: MyEventData[] = homeworksFilter.map((homework) => {
    if ("Homework" !== homework?.__typename) {
      return {
        type: homework?.__typename,
        title: homework?.title!,
        start: moment(
          toLocalDate(homework?.start_date!),
          "DD/MM/YYYY HH:mm",
          false
        ).format("YYYY-MM-DDTHH:mm:ss"),
        end: moment(
          toLocalDate(homework?.start_date!),
          "DD/MM/YYYY HH:mm",
          false
        )
          .add(1, "hour")
          .format("YYYY-MM-DDTHH:mm:ss"),
        subject: homework?.subject,
        finaliza: toLocalDate(homework?.start_date!),
        idHomework: homework?.id,
        idLesson: homework?.lesson?.id,
      };
    } else {
      return {
        type: homework?.__typename,
        title: homework?.title!,
        start: moment(
          toLocalDate(homework?.start_date!),
          "DD/MM/YYYY HH:mm",
          false
        ).format("YYYY-MM-DDTHH:mm:ss"),
        end: moment(
          toLocalDate(homework?.start_date!),
          "DD/MM/YYYY HH:mm",
          false
        )
          .add(1, "hour")
          .format("YYYY-MM-DDTHH:mm:ss"),
        subject: homework?.subject,
        finaliza: toLocalDate(homework?.end_date),
        idHomework: homework?.id,
      };
    }
  });

  return (
    <div className={styles.container}>
      <div className={styles.containerQuitMargin}>
        <div className={styles.containerFlag}>
          <div className={styles.containerButtonsFlag}>
            {subjects.map((e) => {
              return (
                <div>
                  {e.subject === "Ciencias" && (
                    <div
                      className={
                        objFilter?.ciencias
                          ? styles.flagCiencias
                          : styles.flagSelected
                      }
                      onClick={() => {
                        setObjFilter({
                          ...objFilter,
                          ciencias: objFilter?.ciencias ? false : true,
                        });
                      }}
                    >
                      Ciencias {e.grade}° {e.name}
                    </div>
                  )}

                  {e.subject === "Español" && (
                    <div
                      className={
                        objFilter?.español
                          ? styles.flagLengua
                          : styles.flagSelected
                      }
                      onClick={() => {
                        setObjFilter({
                          ...objFilter,
                          español: objFilter?.español ? false : true,
                        });
                      }}
                    >
                      Español {e.grade}° {e.name}
                    </div>
                  )}

                  {e.subject === "Matemáticas" && (
                    <div
                      className={
                        objFilter?.matematica
                          ? styles.flagMatematicas
                          : styles.flagSelected
                      }
                      onClick={() => {
                        setObjFilter({
                          ...objFilter,
                          matematica: objFilter?.matematica ? false : true,
                        });
                      }}
                    >
                      Matematicas {e.grade}° {e.name}
                    </div>
                  )}
                  {e.subject === "Inglés" && (
                    <div
                      className={
                        objFilter?.ingles
                          ? styles.flagIngles
                          : styles.flagSelected
                      }
                      onClick={() => {
                        setObjFilter({
                          ...objFilter,
                          ingles: objFilter?.ingles ? false : true,
                        });
                      }}
                    >
                      Inglés {e.grade}° {e.name}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
          <div
            className={styles.redirectCalendar}
            onClick={() => {
              navigate("/myclasses");
            }}
          >
            Regresar a mi Clases{" "}
          </div>
        </div>
        <FullCalendar
          ref={calendarRef}
          plugins={[timeGridPlugin]}
          initialView="timeGridWeek"
          weekends={true}
          events={newHomeworks}
          locale={esLocale}
          expandRows={true}
          eventContent={renderEventContent}
          editable={true}
          allDaySlot={false}
          contentHeight="calc(100vh - 300px);"
          scrollTime="09:00:00"
          slotMinTime="01:00:00" // Establece la hora de inicio del día
          slotMaxTime="24:00:00" // Establece la hora de fin del día
        />
      </div>

      <DrawerLessonNew
        onClose={onCloseDetailLesson}
        visible={visibleDetailLesson}
        taskId={taskId ?? ""}
        back={"calendar"}
        individualLesson={individualLesson}
        videoLessonEndDate={videoLessonEndDate}
      />

      {visible && (
        <DetailsHomeworkDrawer
          back="calendar"
          taskId={taskId}
          onCloseTask={onClose}
          visibleTask={visible}
        />
      )}
    </div>
  );
};

export default Calendar;
