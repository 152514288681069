import React, { useEffect, useState } from "react";
import { Collapse, Select } from "antd";
import { Loader } from "../../Commons";
import { getCoursesBySubjectIdV2_getCoursesBySubjectIdV2 } from "../../../__generated__/gql-types/getCoursesBySubjectIdV2";
import styles from "./dosification.module.scss";
import lucaBot from "../../../assets/images/lucaBot.png";
import comingSoon from "../../../assets/images/mes2.png";
import UseGetLessonsByCourse from "../../../api/useGetLessonByCourse";
import { getLessonsByCourse_getLessonsByCourse } from "../../../__generated__/gql-types/getLessonsByCourse";
import useAuth from "../../../context/useAuth";
import CardDosification from "../../Nem/Dosification/CardDosification";
import { useLocation } from "react-router-dom";
interface DosificationsProps {
  grade_id?: number;
  trimester: number;
  setLessonId: (id: string) => void;
  setSelectAcordion: (id: string) => void;
  setIndividualLesson?: (item: any) => void;
  showDrawer: () => void;
  setTrimester: React.Dispatch<React.SetStateAction<number>>;
  setGrade: (e: number) => void;
  coursesState: getCoursesBySubjectIdV2_getCoursesBySubjectIdV2[];
  loadingDosification: boolean;
  selectAcordion: string;
  subjectId: string;
}

const { Panel } = Collapse;

export const Dosifications = ({
  grade_id,
  loadingDosification,
  coursesState,
  trimester,
  selectAcordion,
  subjectId,
  setLessonId,
  setSelectAcordion,
  setIndividualLesson,
  showDrawer,
  setTrimester,
  setGrade,
}: DosificationsProps) => {
  const { userToken } = useAuth();
  const getLessonsByCourse = UseGetLessonsByCourse();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const courseParams = queryParams.get("course");
  const [CourseLessons, setCourseLessons] = useState<
    getLessonsByCourse_getLessonsByCourse[] | null
  >(null);
  const [loading, setLoading] = useState(false);
  const handleChangeGrade = (values: string[]) => {
    setGrade(Number(values[0]));
  };

  const handleChangeTrimester = (trimesterSelected: number) => {
    setTrimester(trimesterSelected);
  };

  const onChange = async (key: string | string[]) => {
    try {
      let lessonVideo: getLessonsByCourse_getLessonsByCourse[] = [];
      let lessonSlide: getLessonsByCourse_getLessonsByCourse[] = [];
      let lessonGuion: getLessonsByCourse_getLessonsByCourse[] = [];
      let lessonDynamics: getLessonsByCourse_getLessonsByCourse[] = [];
      let lessonEvaluate: getLessonsByCourse_getLessonsByCourse[] = [];
      let lessonRedaccion: getLessonsByCourse_getLessonsByCourse[] = [];
      let lessonBookPage: getLessonsByCourse_getLessonsByCourse[] = [];
      let lessonConversation: getLessonsByCourse_getLessonsByCourse[] = [];
      let lessonWritingExam: getLessonsByCourse_getLessonsByCourse[] = [];
      let lessonAudioExam: getLessonsByCourse_getLessonsByCourse[] = [];

      setSelectAcordion(key[0]);
      setLoading(true);
      const lessons = await getLessonsByCourse(Number(key[0]));
      if (lessons) {
        for (let index = 0; index < lessons.length; index++) {
          const typeLesson = lessons[index].lesson_type;
          if (typeLesson === "videolesson") {
            lessonVideo.push(lessons[index]);
          }
          if (typeLesson === "slides") {
            lessonSlide.push(lessons[index]);
          }
          if (
            typeLesson === "classroomguide" &&
            (userToken?.role === "teacher" || userToken?.role === "coordinator")
          ) {
            lessonGuion.push(lessons[index]);
          }
          if (
            typeLesson === "manipulative_dynamics" &&
            (userToken?.role === "teacher" || userToken?.role === "coordinator")
          ) {
            lessonDynamics.push(lessons[index]);
          }
          if (typeLesson === "book_pages") {
            lessonBookPage.push(lessons[index]);
          }
          if (
            typeLesson === "redaction" &&
            (userToken?.role === "teacher" || userToken?.role === "coordinator")
          ) {
            lessonRedaccion.push(lessons[index]);
          }
          if (typeLesson === "evaluation") {
            lessonEvaluate.push(lessons[index]);
          }
          if (typeLesson === "conversation") {
            lessonConversation.push(lessons[index]);
          }
          if (typeLesson === "writing_exam") {
            lessonWritingExam.push(lessons[index]);
          }
          if (typeLesson === "audio_exam") {
            lessonAudioExam.push(lessons[index]);
          }
        }
        const lessonOrder = lessonGuion
          .concat(lessonDynamics)
          .concat(lessonVideo)
          .concat(lessonSlide)
          .concat(lessonBookPage)
          .concat(lessonRedaccion)
          .concat(lessonConversation)
          .concat(lessonEvaluate)
          .concat(lessonWritingExam)
          .concat(lessonAudioExam);
        setCourseLessons(lessonOrder);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    (async () => {
      if (courseParams) {
        const response = onChange([courseParams]);
      }
    })();
  }, []);

  return (
    <>
      <div className={styles.tableContainer}>
        <Collapse
          defaultActiveKey={selectAcordion}
          accordion
          bordered={false}
          className={styles.collapseContainerNem}
          onChange={onChange}
        >
          <div className={styles.rowContainer}>
            <Select
              disabled={
                userToken?.schools[0].name === "Colegio Iluminación" ||
                userToken?.schools[0].name === "COLEGIO PROGRESO"
                  ? true
                  : false
              }
              defaultValue={[String(grade_id)]}
              className={styles.selectGradeNem}
              onChange={handleChangeGrade}
              options={[
                { value: "1", label: "1° Grado" },
                { value: "2", label: "2° Grado" },
                { value: "3", label: "3° Grado" },
                { value: "4", label: "4° Grado" },
                { value: "5", label: "5° Grado" },
                { value: "6", label: "6° Grado" },
              ]}
            />
            <div className={styles.containerTrimester}>
              <div
                className={
                  trimester === 1
                    ? styles.textTrimester
                    : styles.textTrimesterNotUnderline
                }
                onClick={() => handleChangeTrimester(1)}
              >
                Primer Trimestre
              </div>
              <div
                className={
                  trimester === 2
                    ? styles.textTrimester
                    : styles.textTrimesterNotUnderline
                }
                onClick={() => handleChangeTrimester(2)}
              >
                Segundo Trimestre
              </div>
              <div
                className={
                  trimester === 3
                    ? styles.textTrimester
                    : styles.textTrimesterNotUnderline
                }
                onClick={() => handleChangeTrimester(3)}
              >
                Tercer Trimestre
              </div>
            </div>
            <div className={styles.containerTrimesterMobile}>
              <div
                className={
                  trimester === 1
                    ? styles.textTrimester
                    : styles.textTrimesterNotUnderline
                }
                onClick={() => handleChangeTrimester(1)}
              >
                Trimestre 1
              </div>
              <div
                className={
                  trimester === 2
                    ? styles.textTrimester
                    : styles.textTrimesterNotUnderline
                }
                onClick={() => handleChangeTrimester(2)}
              >
                Trimestre 2
              </div>
              <div
                className={
                  trimester === 3
                    ? styles.textTrimester
                    : styles.textTrimesterNotUnderline
                }
                onClick={() => handleChangeTrimester(3)}
              >
                Trimestre 3
              </div>
            </div>
          </div>
          {coursesState.length > 0 && (
            <div className={styles.tableHeaderContainer}>
              {userToken?.role === "student" &&
              (trimester === 1 || trimester === 3) ? (
                <></>
              ) : (
                <>
                  <p className={styles.courseHeader}>Curso</p>
                  <p className={styles.weekHeader}>Semana SEP</p>
                </>
              )}
            </div>
          )}
          {loadingDosification ? (
            <Loader />
          ) : userToken?.role === "student" &&
            (trimester === 1 || trimester === 3) ? (
            <div className={styles.comingSoonImgContainer}>
              <div className={styles.comingSoonInnerTextContainer}>
                <h3
                  style={{
                    fontFamily: "Poppins-Bold",
                    fontSize: "25px",
                    marginBottom: "20px",
                    color: "#cc7700",
                    textAlign: "left",
                    paddingLeft: "5px",
                  }}
                >
                  Próximamente
                </h3>
                <p
                  style={{
                    fontSize: "14px",
                    fontFamily: "Poppins-Regular",
                    color: "#cc7700",
                    textAlign: "left",
                    width: "100%",
                  }}
                >
                  Estamos desarrollando el contenido completo de éste trimestre,
                  en breve estará disponible.
                </p>
              </div>
              <div
                style={{
                  width: "60%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  paddingRight: 30,
                }}
              >
                <img
                  className={styles.comingSoonImg}
                  src={lucaBot}
                  alt="Proximamente"
                />
              </div>
            </div>
          ) : coursesState.length === 0 ? (
            <div className={styles.comingSoonImgContainer}>
              <div className={styles.comingSoonInnerTextContainer}>
                <h3
                  style={{
                    fontFamily: "Poppins-Bold",
                    fontSize: "25px",
                    marginBottom: "20px",
                    color: "#cc7700",
                    textAlign: "left",
                    paddingLeft: "5px",
                  }}
                >
                  Próximamente
                </h3>
                <p
                  style={{
                    fontSize: "14px",
                    fontFamily: "Poppins-Regular",
                    color: "#cc7700",
                    textAlign: "left",
                    width: "100%",
                  }}
                >
                  Luca se caracteriza por sus contenidos de calidad y, como
                  siempre, queremos proporcionarles el contenido más
                  actualizado. Debido a los cambios generados por la SEP los
                  contenidos de Luca se irán desbloqueando conforme avancemos en
                  las semanas del ciclo escolar.
                  <br /> <br /> Contaremos en todo momento con al menos dos
                  semanas de anticipación, para apoyarles en la planeación de
                  acuerdo con la dosificación de la SEP.
                </p>
                <p
                  style={{
                    fontFamily: "Poppins-Regular",
                    fontSize: "14px",
                    color: "#cc7700",
                    textAlign: "left",
                  }}
                >
                  Agradecemos su comprensión.
                </p>
              </div>
              <img
                className={styles.comingSoonImg}
                src={comingSoon}
                alt="Proximamente"
              />
            </div>
          ) : (
            <>
              {coursesState.map((course) => {
                return (
                  <Panel
                    key={course.id}
                    showArrow={false}
                    header={
                      <>
                        <div className={styles.panelContainer}>
                          <div className={styles.panelTitle}>
                            {course.title}
                          </div>
                          <div className={styles.panelSepBookPages}>
                            Semana {course.week_2017}
                          </div>
                        </div>
                        <div className={styles.panelContainerMobile}>
                          <div className={styles.containerPanelText}>
                            <div>Semana {course.week_2017}</div>
                            <div className={styles.pagsResponsives}>
                              Págs {course.sep_book_pages_2017}
                            </div>
                          </div>
                          <div
                            className={
                              course.id === selectAcordion
                                ? styles.panelTitleSelect
                                : styles.panelTitle
                            }
                          >
                            {course.title}
                          </div>
                        </div>
                      </>
                    }
                    style={{
                      background: "#F3F3F6",
                      marginBottom: 10,
                      borderRadius: 12,
                      backgroundColor: "#F3F3F6",
                      fontStyle: "normal",
                      width: "auto",
                      fontWeight: "600",
                      fontSize: "14px",
                      lineHeight: "140%",
                      boxSizing: "border-box",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "#F3F3F6",
                        marginRight: -16,
                        borderRadius: 12,
                      }}
                    >
                      <div
                        className={
                          CourseLessons && CourseLessons.length > 3
                            ? styles.cardsContainer
                            : styles.cardsContainerWithoutScroll
                        }
                        style={{
                          backgroundColor: "#F3F3F6",
                          width: "100%",
                        }}
                      >
                        {loading ? (
                          <Loader />
                        ) : (
                          CourseLessons?.map((lesson, index) => {
                            return (
                              <CardDosification
                                lesson={lesson}
                                index={index}
                                showDrawer={showDrawer}
                                setLessonId={setLessonId}
                                key={lesson?.id + lesson?.title}
                                setIndividualLesson={setIndividualLesson}
                              />
                            );
                          })
                        )}
                      </div>
                    </div>
                  </Panel>
                );
              })}
            </>
          )}
        </Collapse>
      </div>
    </>
  );
};
