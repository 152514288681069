import { getClassroomsByStudentId_getClassroomsByStudentId_homeworks } from "../../../../../../__generated__/gql-types/getClassroomsByStudentId";
import thumbnailCiencias from "../../../../../../assets/images/thumbnail/thumbnailCiencias.png";
import thumbnailMate from "../../../../../../assets/images/thumbnail/thumbnailMate.png";
import thumbnailEspañol from "../../../../../../assets/images/thumbnail/thumbnailEspañol.png";
import defaultImage from "../../../../../../assets/images/defaultImage.svg";

import thumbnailClase from "../../../../../../assets/images/thumbnail/thumbnailOrientacionDidactica.png";
import CoverReading from "../../../../../../assets/images/thumbnail/CoverReading.png";
import CoverLectura from "../../../../../../assets/images/thumbnail/CoverLectura.png";
import CoverEvaluation from "../../../../../../assets/images/thumbnail/CoverEvaluation.png";
import CoverWriting from "../../../../../../assets/images/thumbnail/CoverWriting.png";
import ensayoMatematica from "../../../../../../assets/images/thumbnail/ensayoMatematica.png";
import ensayoEspañol from "../../../../../../assets/images/thumbnail/ensayoEspañol.png";
import ensayoCiencias from "../../../../../../assets/images/thumbnail/ensayoCiencias.png";
export const functionReturnTag = (type: string, subject_id: number) => {
  if (subject_id === 16) {
    return type === "videolesson"
      ? "Video lesson"
      : type === "redaction"
      ? "Essay"
      : type === "literacy"
      ? "Reading"
      : type === "conversation"
      ? "Conversation"
      : "Evaluation";
  } else {
    return type === "videolesson"
      ? "Video Lección"
      : type === "redaction"
      ? "Redaccion"
      : type === "literacy"
      ? "Lectura"
      : type === "conversation"
      ? "Conversation"
      : "Evaluación";
  }
};
export const functionReturnSubject = (subject_id: number) => {
  if (subject_id === 1) {
    return "Matemáticas";
  }
  if (subject_id === 2 || subject_id === 17) {
    return "Español";
  }
  if (subject_id === 4) {
    return "Ciencias";
  }
  if (subject_id === 16) {
    return "English";
  }
};

export const functionReturnTextCTA = (text: string, subject_id: number) => {
  if (subject_id !== 16) {
    return text ? (text === "TERMINAREVIDENCIA" ? "TERMINAR" : text) : "";
  } else {
    switch (text) {
      case "TERMINAREVIDENCIA":
        return "FINISH";
      case "COMENZAR":
        return "START";
      case "REVISAR":
        return "CHECK";
      case "NO ENTREGADA":
        return "NOT DELIVERED";
      default:
        return "ARREGLAR";
    }
  }
};
export const functionReturnTextALLCTA = (
  homework: getClassroomsByStudentId_getClassroomsByStudentId_homeworks,
  maxResult: number | null
) => {
  return homework?.lessons?.[0].subject_id === 16
    ? !homework?.needs_file_evidence
      ? maxResult === null
        ? "START"
        : "REPEAT"
      : maxResult === null
      ? homework?.studentEvidenceEvent
        ? "FINISH"
        : "START"
      : homework?.studentEvidenceEvent
      ? "REPEAT"
      : "FINISH"
    : !homework?.needs_file_evidence
    ? maxResult === null
      ? "Comenzar"
      : "Repetir"
    : maxResult === null
    ? homework?.studentEvidenceEvent
      ? "Continuar"
      : "Comenzar"
    : homework?.studentEvidenceEvent
    ? "Repetir"
    : "Continuar";
};

export const encontrarValorMayor = (
  variable1: number | null,
  variable2: number | null
) => {
  if (variable1 !== null && variable2 !== null) {
    return Math.max(variable1, variable2);
  } else if (variable1 !== null) {
    return variable1;
  } else if (variable2 !== null) {
    return variable2;
  } else {
    return null;
  }
};

export const functionReturnImg = (
  homework: getClassroomsByStudentId_getClassroomsByStudentId_homeworks | null
) => {
  if (
    homework?.lessons &&
    homework?.lessons?.length >= 0 &&
    homework?.lessons[0].lesson_type === "classroomguide"
  ) {
    return thumbnailClase;
  }

  if (
    homework?.lessons &&
    homework?.lessons?.length >= 0 &&
    homework?.lessons[0].lesson_type === "evaluation"
  ) {
    if (homework.lessons[0].subject_id === 1) {
      return thumbnailMate;
    }
    if (
      homework.lessons[0].subject_id === 2 ||
      (homework?.lessons && homework?.lessons[0].subject_id === 17)
    ) {
      return thumbnailEspañol;
    }
    if (homework.lessons[0].subject_id === 4) {
      return thumbnailCiencias;
    }
    if (homework.lessons[0].subject_id === 16) {
      return CoverEvaluation;
    }
  }

  if (
    homework?.lessons &&
    homework?.lessons?.length >= 0 &&
    homework?.lessons[0].lesson_type === "redaction"
  ) {
    if (homework.lessons[0].subject_id === 1) {
      return ensayoMatematica;
    }
    if (
      homework.lessons[0].subject_id === 2 ||
      (homework?.lessons && homework?.lessons[0].subject_id === 17)
    ) {
      return ensayoEspañol;
    }
    if (homework.lessons[0].subject_id === 4) {
      return ensayoCiencias;
    }
    if (homework.lessons[0].subject_id === 16) {
      return CoverWriting;
    }
  }

  if (
    homework?.lessons &&
    homework.lessons.length &&
    homework?.lessons[0]?.lesson_content?.videos?.length &&
    homework?.lessons[0]?.lesson_content?.videos[0]?.imageUrl &&
    homework?.lessons[0].lesson_type === "videolesson"
  ) {
    return homework?.lessons[0]?.lesson_content?.videos[0].imageUrl;
  }

  if (
    homework?.lessons &&
    homework.lessons.length &&
    homework?.lessons[0]?.lesson_content?.literacy &&
    homework?.lessons[0]?.lesson_content?.literacy?.cover
  ) {
    return homework?.lessons[0]?.lesson_content?.literacy?.cover;
  }
  if (
    homework?.lessons &&
    homework.lessons.length &&
    homework?.lessons[0]?.lesson_content?.aiconversations?.card_image
  ) {
    return homework?.lessons[0]?.lesson_content?.aiconversations?.card_image;
  }

  if (
    homework?.lessons &&
    homework.lessons.length &&
    homework?.lessons[0]?.lesson_type === "literacy"
  ) {
    if (homework?.lessons[0]?.subject_id === 16) {
      return CoverReading;
    }
    return CoverLectura;
  }
  if (
    homework?.lessons &&
    homework.lessons.length &&
    homework?.lessons[0]?.lesson_content?.literacy
  ) {
    console.log(homework?.lessons);
    // return homework?.lessons[0]?.lesson_content?.literacy?.cover;
  }

  return defaultImage;
};
