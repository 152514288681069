import React, { FC, useState } from "react";
import styles from "./listCourse.module.scss";
import settingsIcon from "../../../../assets/icons/settingsIcon.svg";
import { ActiveFilterSearcher } from "../../../Searcher/ActiveFilterSearcher";
import { Loader, Searchbar } from "../../../Commons";
import { getPDACourses_getPDACourses } from "../../../../__generated__/gql-types/getPDACourses";
import { NotCourse } from "./NotCourse";
import { TableCourse } from "./TableCourse";
import { Select } from "antd";
import useAuth from "../../../../context/useAuth";
interface PropsListCourse {
  totalCourse: getPDACourses_getPDACourses[] | null;
  setCheckedCourse: (e: getPDACourses_getPDACourses[] | null) => void;
  checkedCourse: getPDACourses_getPDACourses[] | null;
  setGrade: (e: number[]) => void;
  setField: (e: string[]) => void;
  grade: number[];
  loading: boolean;
  field: string[];
  searchInput: string;
  statusList: string;
  setSearchInput: (e: string) => void;
  setStatusList: (e: string) => void;
}

const ListCourse: FC<PropsListCourse> = ({
  totalCourse,
  checkedCourse,
  setCheckedCourse,
  setStatusList,
  statusList,
  grade,
  field,
  loading,
  searchInput,
  setGrade,
  setField,
  setSearchInput,
}) => {
  const { userToken } = useAuth();
  const userGrades = userToken?.user_grade;
  let gradesSorted: number[] = [];
  if (userGrades && userGrades.length > 0) {
    userGrades.map((a) => gradesSorted.push(a));
  } else {
    gradesSorted.push(6);
  }
  gradesSorted.sort((a, b) => a - b);

  const [openFilter, setOpenFilter] = useState(false);

  const handleChangeGrade = (values: string[]) => {
    const arrayNumber = Number(values[0]);
    setGrade([arrayNumber]);
  };
  const handleChangeField = (fieldLocal: string) => {
    setField([fieldLocal]);
  };
  const handleStatusList = (status: string) => {
    setStatusList(status);
    setSearchInput("");
    setGrade([]);
    if (status === "dosification") {
      setField(["Saberes"]);
      setGrade([gradesSorted[0]]);
    } else {
      setField([]);
    }
  };
  return (
    <div className={styles.container}>
      <div className={styles.containerTitle}>
        <div className={styles.title}>SELECCIONA TEMAS PARA TU PROYECTO</div>
        <div className={styles.titleButton}>
          <div
            className={
              statusList === "searcher"
                ? styles.selectedButton
                : styles.normalButton
            }
            onClick={() =>
              statusList === "dosification" && handleStatusList("searcher")
            }
          >
            Buscador
          </div>
          <div
            className={
              statusList === "dosification"
                ? styles.selectedButton
                : styles.normalButton
            }
            onClick={() => {
              statusList === "searcher" && handleStatusList("dosification");
              setOpenFilter(false);
            }}
          >
            Dosificación
          </div>
        </div>
      </div>
      <div className={styles.contentContainer}>
        {openFilter && (
          <ActiveFilterSearcher
            setOpenFilter={setOpenFilter}
            setGrade={setGrade}
            setField={setField}
            grade={grade}
            field={field}
          />
        )}
        <div className={styles.listContainer}>
          {statusList === "dosification" && (
            <div className={styles.filterDosification}>
              <Select
                className={styles.selectGradeNem}
                onChange={handleChangeGrade}
                defaultValue={[`${gradesSorted[0]}`]}
                style={{ borderRadius: 12 }}
                options={[
                  { value: "1", label: "1° Grado" },
                  { value: "2", label: "2° Grado" },
                  { value: "3", label: "3° Grado" },
                  { value: "4", label: "4° Grado" },
                  { value: "5", label: "5° Grado" },
                  { value: "6", label: "6° Grado" },
                ]}
              />
              <div
                className={
                  field[0] === "Saberes"
                    ? styles.selectedButton
                    : styles.normalButton
                }
                onClick={() => handleChangeField("Saberes")}
              >
                Saberes
              </div>
              <div
                className={
                  field[0] === "Ética, Naturaleza y Sociedades"
                    ? styles.selectedButton
                    : styles.normalButton
                }
                onClick={() =>
                  handleChangeField("Ética, Naturaleza y Sociedades")
                }
              >
                Ética
              </div>
              <div
                className={
                  field[0] === "De lo Humano y lo Comunitario"
                    ? styles.selectedButton
                    : styles.normalButton
                }
                onClick={() =>
                  handleChangeField("De lo Humano y lo Comunitario")
                }
              >
                De lo Humano
              </div>
              <div
                className={
                  field[0] === "Lenguajes"
                    ? styles.selectedButton
                    : styles.normalButton
                }
                onClick={() => handleChangeField("Lenguajes")}
              >
                Lenguajes
              </div>
            </div>
          )}
          {statusList === "searcher" && (
            <div className={styles.filtersContainer}>
              {!openFilter && (
                <div
                  className={styles.items}
                  onClick={() => {
                    setOpenFilter(!openFilter);
                  }}
                >
                  <img src={settingsIcon} alt="" className={styles.filterImg} />
                  <div>Filtros</div>
                </div>
              )}
              <Searchbar
                setSearchInput={setSearchInput}
                searchInput={searchInput}
              />
            </div>
          )}
          {loading ? (
            <Loader />
          ) : totalCourse && totalCourse?.length < 1 ? (
            <NotCourse searchInput={searchInput} />
          ) : (
            <TableCourse
              checkedCourse={checkedCourse}
              setCheckedCourse={setCheckedCourse}
              totalCourse={totalCourse}
              field={field}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ListCourse;
