import React, { FC } from "react";
import { getLessonById_getLessonById_lesson_content_quizzes_questions } from "../../../../../../__generated__/gql-types/getLessonById";
import styles from "./fraseDrag.module.scss";

interface PropsFrase {
  index: number;
  question: getLessonById_getLessonById_lesson_content_quizzes_questions;
  subject_id?: number | null;
}
const FraseDrag: FC<PropsFrase> = ({ index, question, subject_id }) => {
  const orderedAnswers = question.answers?.sort((a, b) => a.order! - b.order!);
  const partes = question.question!.split("__");
  const answerCorrect = orderedAnswers!.filter((x) => x.correct === 1);
  const answerIncorrect = orderedAnswers!.filter((x) => x.correct !== 1);
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.title}>
          {index + 1}.{" "}
          {subject_id === 16
            ? "Fill in the spaces with the right word(s)"
            : "Coloca las fichas en su lugar correspondiente."}
        </div>
        {question.url && (
          <div className={styles.containerImage}>
            <img
              className={styles.imgQuestions}
              src={question.url ?? ""}
              alt="asdas"
            />
          </div>
        )}
        <div className={styles.textFill}>
          {partes![0]}&nbsp;
          <span className={styles.correctAnswer}>
            {answerCorrect[0] && answerCorrect[0]?.answer}
          </span>
          &nbsp;
          {partes[1]}
          &nbsp;
          {answerCorrect[1] && (
            <span className={styles.correctAnswer}>
              {answerCorrect[1] && answerCorrect[1].answer}
            </span>
          )}
          &nbsp;
          {partes[2]}
        </div>

        <div className={styles.containerIncorrectAnswer}>
          {answerIncorrect.map((e) => {
            return (
              <span
                className={styles.incorrectAnswer}
                style={{
                  background: "#e6e7ef",
                  textAlign: "center",
                  borderRadius: "4px",
                  minWidth: "68px",
                  padding: "2px 12px",
                }}
              >
                {e.answer}
              </span>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default FraseDrag;
