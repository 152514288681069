import { useCallback } from "react";
import {
  ApolloError,
  ApolloQueryResult,
  useApolloClient,
  gql,
} from "@apollo/client";

import {
  getClassroomLessons,
  getClassroomLessonsVariables,
} from "../__generated__/gql-types/getClassroomLessons";

const UseGetClassroomLessons = () => {
  const client = useApolloClient();

  return useCallback(
    async (input: getClassroomLessonsVariables) => {
      const result: ApolloQueryResult<getClassroomLessons> = await client.query(
        {
          query: GET_CLASSROOM_LESSONS,
          fetchPolicy: "no-cache",
          variables: input,
        }
      );

      if (result.error) {
        throw new ApolloError({
          graphQLErrors: result.errors,
        });
      }

      return result.data.getClassroomLessons;
    },
    [client]
  );
};

export default UseGetClassroomLessons;

const GET_CLASSROOM_LESSONS = gql`
  query getClassroomLessons($classroomLessonId: ID!) {
    getClassroomLessons(classroomLessonId: $classroomLessonId) {
      id
      start_date
      classroom_id
      title
      lesson {
        id
      }
    }
  }
`;
