import { FC } from "react";
import styles from "./cardDosification.module.scss";
import defaultImage from "../../../../assets/images/defaultImage.svg";
import videoLight from "../../../../assets/icons/Video_light.svg";
import evaluationIcon from "../../../../assets/icons/evaluationIcon.svg";
import audio from "../../../../assets/icons/audio.png";
import conversationIcon from "../../../../assets/icons/conversation.svg";
import lecturaIcon from "../../../../assets/icons/lectura.svg";
import guionIcon from "../../../../assets/icons/guionIcon.svg";
import pizza from "../../../../assets/dataMock/Pizza_portada.png";
import thumbnailClase from "../../../../assets/images/thumbnail/teacherGuide.png";
import thumbnailCiencias from "../../../../assets/images/thumbnail/thumbnailCiencias.png";
import thumbnailMate from "../../../../assets/images/thumbnail/thumbnailMate.png";
import CoverEvaluation from "../../../../assets/images/thumbnail/CoverEvaluation.png";
import coverListening from "../../../../assets/images/thumbnail/coverListening.png";
import CoverWriting from "../../../../assets/images/thumbnail/CoverWriting.png";
import thumbnailEspañol from "../../../../assets/images/thumbnail/thumbnailEspañol.png";
import thumbnailDiapositivas from "../../../../assets/images/thumbnail/slidesEnglish.png";
import thumbnailBookPages from "../../../../assets/images/thumbnail/bookEnglish.png";
import thumbnailAudio from "../../../../assets/images/thumbnail/thumbnailAudio.png";
import ensayoMatematica from "../../../../assets/images/thumbnail/ensayoMatematica.png";
import writingExamSpain from "../../../../assets/images/thumbnail/coverExamSpain.png";
import ensayoEspañol from "../../../../assets/images/thumbnail/ensayoEspañol.png";
import ensayoCiencias from "../../../../assets/images/thumbnail/ensayoCiencias.png";
import listeningExam from "../../../../assets/images/thumbnail/listening_exam.png";
import writingExam from "../../../../assets/images/thumbnail/writing_exam.png";
import { getLessonsByCourse_getLessonsByCourse } from "../../../../__generated__/gql-types/getLessonsByCourse";

interface CardProps {
  setLessonId: (id: string) => void;
  showDrawer: () => void;
  lesson: getLessonsByCourse_getLessonsByCourse;
  index: number;
  setIndividualLesson?: (lesson: any) => void;
}

const CardDosification: FC<CardProps> = ({
  lesson,
  showDrawer,
  setLessonId,
  setIndividualLesson,
}) => {
  const functionReturnImg = () => {
    if (lesson.lesson_type === "classroomguide") {
      return thumbnailClase;
    }
    if (lesson.lesson_type === "slides") {
      return thumbnailDiapositivas;
    }
    if (lesson.lesson_type === "book_pages") {
      return thumbnailBookPages;
    }
    if (lesson.lesson_type === "audio") {
      return thumbnailAudio;
    }
    if (lesson.lesson_type === "evaluation") {
      if (lesson.subject?.id === "1") {
        return thumbnailMate;
      }
      if (lesson.subject?.id === "2") {
        return thumbnailEspañol;
      }
      if (lesson.subject?.id === "4") {
        return thumbnailCiencias;
      }
      if (lesson.subject?.id === "16") {
        return CoverEvaluation;
      }
    }
    if (lesson.lesson_type === "redaction") {
      if (lesson.subject?.id === "1") {
        return ensayoMatematica;
      }
      if (lesson.subject?.id === "2") {
        return ensayoEspañol;
      }
      if (lesson.subject?.id === "4") {
        return ensayoCiencias;
      }
      if (lesson.subject?.id === "16") {
        return CoverWriting;
      }
    }

    if (lesson.lesson_type === "writing_exam") {
      if (lesson.subject?.id === "16") {
        return writingExam;
      } else {
        return writingExamSpain;
      }
    }
    if (lesson.lesson_type === "audio_exam") {
      return listeningExam;
    }
    if (lesson.lesson_type === "listening") {
      return coverListening;
    }
  };

  const LESSON_TYPE_OPTIONS: Record<
    string,
    { labelEs: string; labelEn: string; icon: string }
  > = {
    videolesson: {
      labelEs: "Lección en Video",
      labelEn: "Video Lesson",
      icon: videoLight,
    },
    audio: { labelEs: "Audio", labelEn: "Listening", icon: videoLight },
    classroomguide: {
      labelEs: "Guía de Clase",
      labelEn: "Learning Plan",
      icon: guionIcon,
    },
    redaction: { labelEs: "Redacción", labelEn: "Essay", icon: guionIcon },
    evaluation: {
      labelEs: "Evaluación",
      labelEn: "Test",
      icon: evaluationIcon,
    },
    audio_exam: {
      labelEs: "Examen de Audio",
      labelEn: "Listening Exam",
      icon: evaluationIcon,
    },
    writing_exam: {
      labelEs: "Examen de Escritura",
      labelEn: "Writing Exam",
      icon: evaluationIcon,
    },
    listening: { labelEs: "Escucha", labelEn: "Audios", icon: audio },
    literacy: {
      labelEs: "Alfabetización",
      labelEn: "Reading",
      icon: lecturaIcon,
    },
    conversation: {
      labelEs: "Conversación",
      labelEn: "Conversation",
      icon: conversationIcon,
    },
    slides: { labelEs: "Diapositivas", labelEn: "Slides", icon: videoLight },
    book_pages: {
      labelEs: "Páginas del Libro",
      labelEn: "Book",
      icon: videoLight,
    },
  };

  // Función que devuelve el icono según el tipo de lección
  const getLessonIcon = (lessonType: string) =>
    LESSON_TYPE_OPTIONS[lessonType]?.icon || videoLight;
  const getLessonLabelEn = (lessonType: string) =>
    LESSON_TYPE_OPTIONS[lessonType]?.labelEn || videoLight;

  return (
    <div
      className={styles.cardContainer}
      onClick={() => {
        setIndividualLesson && setIndividualLesson(lesson);
        setLessonId(lesson?.id);
        showDrawer();
      }}
    >
      <div className={styles.videoQuizContainer}>
        <img
          alt="icon"
          src={getLessonIcon(lesson.lesson_type ?? "videolesson")}
          style={{ width: "100%" }}
        />
        {getLessonLabelEn(lesson.lesson_type ?? "")}
      </div>
      <img
        src={
          Number(lesson?.id) === 1812
            ? pizza
            : functionReturnImg()
            ? functionReturnImg()
            : lesson.lesson_content?.videos?.length &&
              lesson.lesson_content?.videos[0]?.imageUrl
            ? lesson.lesson_content?.videos[0].imageUrl
            : lesson.lesson_content?.aiconversations &&
              lesson.lesson_content?.aiconversations.card_image
            ? lesson.lesson_content?.aiconversations?.card_image
            : lesson?.lesson_type === "literacy" &&
              lesson?.lesson_content?.literacy?.cover
            ? lesson?.lesson_content?.literacy?.cover
            : defaultImage
        }
        style={{ width: "100%" }}
        alt=""
      />

      <div className={styles.cardContent}>
        <div className={styles.titleCard}>{lesson?.title}</div>

        <div>
          <h3>{lesson?.sep_name}</h3>

          <div className={styles.containerPages}>
            <div className={styles.pages}>
              {(lesson?.lesson_type === "videolesson" ||
                lesson?.lesson_type === "classroomguide" ||
                lesson?.lesson_type === "manipulative_dynamics" ||
                lesson?.lesson_type === "slides") &&
                "Didáctica"}
              {(lesson?.lesson_type === "book_pages" ||
                lesson?.lesson_type === "audio_exam" ||
                lesson?.lesson_type === "writing_exam" ||
                lesson?.lesson_type === "evaluation" ||
                lesson?.lesson_type === "redaction" ||
                lesson?.lesson_type === "literacy" ||
                lesson?.lesson_type === "listening" ||
                lesson?.lesson_type === "conversation") &&
                "Práctica"}
            </div>
            <div className={styles.trim}>
              <div className={styles.bubble}>{lesson?.grade_id}°</div>
              <div className={styles.bubble}>Semana {lesson?.week}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardDosification;
