import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { teacherAssignHomeworkConvIAVariables } from "../__generated__/gql-types/teacherAssignHomeworkConvIA";

const useTeacherAssignHomeworkConvAIActionEvent = () => {
  const client = useApolloClient();

  return useCallback(
    async (input: teacherAssignHomeworkConvIAVariables) => {
      return await client.mutate({
        mutation: TEACHER_ASSIGN_HOMEWORK_CONVAI_ACTION_EVENT,
        fetchPolicy: "no-cache",
        variables: input,
      });
    },
    [client]
  );
};
export default useTeacherAssignHomeworkConvAIActionEvent;

const TEACHER_ASSIGN_HOMEWORK_CONVAI_ACTION_EVENT = gql`
  mutation teacherAssignHomeworkConvIA(
    $contentId: Int
    $startDate: String
    $reasign: Boolean
  ) {
    teacherAssignHomeworkConvIA(
      contentId: $contentId
      start_date: $startDate
      reasign: $reasign
    )
  }
`;
