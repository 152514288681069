import { FC, useState } from "react";
import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import styles from "./studentStatusTable.module.scss";
import download from "../../../../assets/icons/teacher/download_table.svg";
import noDownloader from "../../../../assets/icons/teacher/noDownloader.svg";
import cross from "../../../../assets/icons/close.svg";
import {
  getHomeworkById_getHomeworkById,
  getHomeworkById_getHomeworkById_students_grades,
} from "../../../../__generated__/gql-types/getHomeworkById";
import { resolveAvatar, resolveColorAvatar } from "../../../../utils/functions";
import useIsMobile from "../../../../hooks/useIsMobile";
import { environment } from "../../../../configuration/environment";
import nextArrow from "../../../../assets/icons/teacher/next_arrow_blue.svg";

interface Props {
  task?: getHomeworkById_getHomeworkById | null;
  setUserIdSetted: (e: number | null) => void;
  setVisibleDrawer: (e: boolean) => void;
  setVisibleDrawerConversation: (e: boolean) => void;
  setVisibleModalStudentResponse: React.Dispatch<React.SetStateAction<boolean>>;
}

const StudentsStatusTable: FC<Props> = ({
  task,
  setUserIdSetted,
  setVisibleDrawer,
  setVisibleDrawerConversation,
  setVisibleModalStudentResponse,
}) => {
  const [visibleMessage, setVisibleMessage] = useState(false);
  const isMobile = useIsMobile();
  const individualHomework: boolean = task?.individualHomework
    ? task.individualHomework
    : false;

  const checkStatus = (record: any) => {
    return record.status === "finished"
      ? "Completada"
      : record.status === "inCourse"
      ? "En Curso"
      : "No entregada";
  };

  let columns: ColumnsType<getHomeworkById_getHomeworkById_students_grades> = [
    {
      title: "ALUMNOS",
      dataIndex: "student_name",
      key: "student_name",
      align: "left",
      sorter: {
        compare: (a, b) =>
          a?.student_last_name!.localeCompare(b?.student_last_name!, "es"),
        multiple: 1,
      },
      defaultSortOrder: "ascend",
      sortDirections: ["ascend", "descend", "ascend"],
      width: 400,
      render: (text, _) => {
        return (
          <div className={styles.userContainer}>
            <span
              className={styles.studentAvatar}
              style={{
                background: `${resolveColorAvatar(
                  _?.student_avatar ?? "polux"
                )}`,
              }}
            >
              <img
                src={resolveAvatar(_?.student_avatar ?? "polux", "5")}
                alt="avatar"
              />
            </span>
            <h1> {_.student_name}</h1>
          </div>
        );
      },
    },
    {
      title: () => <h1 className={styles.tableTitles}>ESTADO</h1>,
      dataIndex: "status",
      key: "status",
      align: "center",
      width: 150,
      render: (i, record) => {
        return (
          <div className={styles.deliveredContent}>
            <p
              className={
                checkStatus(record) === "No entregada"
                  ? styles.notDelivered
                  : checkStatus(record) === "Completada"
                  ? styles.delivered
                  : styles.inCourse
              }
            >
              {checkStatus(record)}
            </p>
          </div>
        );
      },
    },
    {
      title: () => <h1 className={styles.tableTitles}>INTENTOS</h1>,
      dataIndex: "tries",
      key: "tries",
      align: "center",
      width: 100,
      render: (i, _) => {
        return !task?.tries || !_?.tries ? (
          _?.tries ? (
            <div className={styles.tries}> {_?.tries?.toString()}</div>
          ) : (
            <div className={styles.textStyles}>-</div>
          )
        ) : (
          <div className={styles.tries}>
            {_?.tries?.toString()}/{task?.tries}
          </div>
        );
      },
    },
    {
      title: () => <h1 className={styles.tableTitles}>MEJOR</h1>,
      dataIndex: "high_score",
      key: "high_score",
      width: 100,
      align: "center",
      sorter: { compare: (a, b) => a.high_score! - b.high_score!, multiple: 2 },
      sortDirections: ["ascend", "descend", "ascend"],
      render: (i, _) => {
        return (
          <div
            className={styles.tabScoreContainer}
            onClick={() => {
              setVisibleModalStudentResponse(true);
              setUserIdSetted(_?.student_id);
            }}
          >
            {_.high_score || _.high_score === 0 ? (
              _.high_score > 5 ? (
                <div className={styles.tableHighScore}>{_.high_score}</div>
              ) : (
                <div className={styles.tableLowScore}>{_.high_score}</div>
              )
            ) : (
              <div className={styles.textStyles}>-</div>
            )}
          </div>
        );
      },
    },
    {
      title: () => <h1 className={styles.tableTitles}>EVIDENCIA</h1>,
      key: "evidence",
      align: "center",
      width: 100,
      render: (record: any) => {
        const url = `${environment.awsBucketVideos}${record.studentEvidenceUrl}`;
        return record.studentEvidenceUrl ? (
          <a
            href={url}
            target="_blank"
            rel="noreferrer"
            style={{ cursor: "pointer" }}
          >
            <img src={download} alt="download" />
          </a>
        ) : (
          <div className={styles.noDownloaderDisable}>
            <img src={noDownloader} alt="download" />
          </div>
        );
      },
    },
    {
      width: 8,
      render: (record: any) => {
        const status = checkStatus(record);
        if (status === "Completada") {
          return (
            <img
              src={nextArrow}
              alt=""
              className={styles.arrow}
              onClick={() => {
                setVisibleModalStudentResponse(true);
                setUserIdSetted(record?.student_id);
              }}
            />
          );
        } else {
          return null;
        }
      },
    },
  ];

  let columnsGPT: ColumnsType<getHomeworkById_getHomeworkById_students_grades> =
    [
      {
        title: "ALUMNO",
        dataIndex: "student_name",
        key: "student_name",
        align: "left",
        sorter: {
          compare: (a, b) =>
            a?.student_name!.localeCompare(b?.student_name!, "es"),
          multiple: 1,
        },
        defaultSortOrder: "ascend",
        sortDirections: ["ascend", "descend", "ascend"],
        width: 400,
        render: (text, _) => {
          return (
            <div className={styles.userContainer}>
              <span
                className={styles.studentAvatar}
                style={{
                  background: `${resolveColorAvatar(
                    _?.student_avatar ?? "polux"
                  )}`,
                }}
              >
                <img
                  src={resolveAvatar(_?.student_avatar ?? "polux", "5")}
                  alt="avatar"
                />
              </span>
              <h1> {_.student_name}</h1>
            </div>
          );
        },
      },
      {
        title: () => <h1 className={styles.tableTitles}>ESTADO</h1>,
        dataIndex: "status",
        key: "status",
        align: "center",
        width: 100,
        render: (i, record) => {
          return (
            <div className={styles.deliveredContent}>
              <p
                className={
                  checkStatus(record) === "No entregada"
                    ? styles.notDelivered
                    : checkStatus(record) === "Completada"
                    ? styles.delivered
                    : styles.inCourse
                }
              >
                {checkStatus(record)}
              </p>
            </div>
          );
        },
      },
      {
        title: () => <h1 className={styles.tableTitles}>Calificacion</h1>,
        dataIndex: "delivered",
        key: "delivered",
        align: "center",
        width: 100,
        render: (i, _) => {
          return _.tries ? (
            _.high_score === null || _.high_score === undefined ? (
              <div
                className={styles.fixedStyles}
                onClick={() => {
                  setUserIdSetted(Number(_.student_id) ?? null);
                  setVisibleDrawer(true);
                }}
              >
                corregir
              </div>
            ) : (_.delivered || _.high_score) &&
              (_.high_score !== null || _.high_score !== undefined) ? (
              _.high_score && _.high_score > 5 ? (
                <div className={styles.tabScoreContainer}>
                  <div
                    className={styles.tableHighScore}
                    onClick={() => {
                      setUserIdSetted(Number(_.student_id) ?? null);
                      setVisibleDrawer(true);
                    }}
                  >
                    {_.high_score}
                  </div>
                </div>
              ) : (
                <div className={styles.tabScoreContainer}>
                  <div
                    className={styles.tableLowScore}
                    onClick={() => {
                      setUserIdSetted(Number(_.student_id) ?? null);
                      setVisibleDrawer(true);
                    }}
                  >
                    {_.high_score}
                  </div>
                </div>
              )
            ) : (
              <div className={styles.textStyles}>-</div>
            )
          ) : (
            <div className={styles.textStyles}>-</div>
          );
        },
      },
      {
        title: () => <h1 className={styles.tableTitles}>EVIDENCIA</h1>,
        key: "evidence",
        align: "center",
        width: 100,
        render: (record: any, e, event) => {
          const url = `${environment.awsBucketVideos}${record.studentEvidenceUrl}`;
          return record.studentEvidenceUrl ? (
            <a
              href={url}
              target="_blank"
              rel="noreferrer"
              style={{ cursor: "pointer" }}
              onClick={(event) => {
                event.stopPropagation();
                event.isPropagationStopped();
              }}
            >
              <img src={download} alt="download" />
            </a>
          ) : (
            <div className={styles.noDownloaderDisable}>
              <img src={noDownloader} alt="download" />
            </div>
          );
        },
      },
      {
        key: "action",
        align: "center",
        width: 8,
        render: (i, _) => {
          return !_.tries ? (
            <div className={styles.textStyles}></div>
          ) : (
            <img
              src={nextArrow}
              alt=""
              className={styles.arrow}
              onClick={() => {
                setUserIdSetted(Number(_.student_id) ?? null);
                setVisibleDrawer(true);
              }}
            />
          );
        },
      },
    ];

  let columnsConversation: ColumnsType<getHomeworkById_getHomeworkById_students_grades> =
    [
      {
        title: "ALUMNO",
        dataIndex: "student_name",
        key: "student_name",
        sorter: {
          compare: (a, b) =>
            a?.student_name!.localeCompare(b?.student_name!, "es"),
          multiple: 1,
        },
        defaultSortOrder: "ascend",
        sortDirections: ["ascend", "descend", "ascend"],
        width: 400,
        render: (text, _) => {
          return (
            <div className={styles.userContainer}>
              <span
                className={styles.studentAvatar}
                style={{
                  background: `${resolveColorAvatar(
                    _?.student_avatar ?? "polux"
                  )}`,
                }}
              >
                <img
                  src={resolveAvatar(_?.student_avatar ?? "polux", "5")}
                  alt="avatar"
                />
              </span>
              <h1> {_.student_name}</h1>
            </div>
          );
        },
      },
      {
        title: () => <h1 className={styles.tableTitles}>ESTADO</h1>,
        dataIndex: "status",
        key: "status",
        align: "center",
        width: 100,
        render: (i, record) => {
          return (
            <div className={styles.deliveredContent}>
              <p
                className={
                  checkStatus(record) === "No entregada"
                    ? styles.notDelivered
                    : checkStatus(record) === "Completada"
                    ? styles.delivered
                    : styles.inCourse
                }
              >
                {checkStatus(record)}
              </p>
            </div>
          );
        },
      },
      {
        title: () => <h1 className={styles.tableTitles}>Calificacion</h1>,
        dataIndex: "delivered",
        key: "delivered",
        align: "center",
        width: 100,
        render: (i, _) => {
          return _.high_score || _.high_score === 0 ? (
            _.high_score > 5 ? (
              <div className={styles.tabScoreContainer}>
                <div className={styles.tableHighScore}>{_.high_score}</div>
              </div>
            ) : (
              <div className={styles.tabScoreContainer}>
                <div className={styles.tableLowScore}>{_.high_score}</div>
              </div>
            )
          ) : (
            <div className={styles.textStyles}>{_.high_score ?? "-"}</div>
          );
        },
      },

      {
        key: "action",
        align: "center",
        width: 8,
        render: (i, _) => {
          return !_.delivered ? (
            <div className={styles.textStyles}>- -</div>
          ) : (
            <img src={nextArrow} alt="" className={styles.arrow} />
          );
        },
      },
    ];

  let columnsMobile: ColumnsType<getHomeworkById_getHomeworkById_students_grades> =
    [
      {
        title: () => <div className={styles.tableTitles}>ALUMNO</div>,
        dataIndex: "student_name",
        key: "student_name",
        sorter: {
          compare: (a, b) =>
            a?.student_name!.localeCompare(b?.student_name!, "es"),
          multiple: 1,
        },
        defaultSortOrder: "ascend",
        sortDirections: ["ascend", "descend", "ascend"],
        width: 400,
        render: (text, _) => {
          return (
            <div className={styles.userContainer}>
              <span
                className={styles.studentAvatar}
                style={{
                  background: `${resolveColorAvatar(
                    _?.student_avatar ?? "polux"
                  )}`,
                }}
              >
                <img
                  src={resolveAvatar(_?.student_avatar ?? "polux", "5")}
                  alt="avatar"
                />
              </span>
              <div className={styles.nameContainer}>
                <h1> {_.student_name}</h1>
                <div
                  className={
                    checkStatus(_) === "No entregada"
                      ? styles.notDelivered
                      : checkStatus(_) === "Completada"
                      ? styles.delivered
                      : styles.inCourse
                  }
                >
                  {checkStatus(_)}
                </div>
              </div>
            </div>
          );
        },
      },
      {
        title: () => <h1 className={styles.tableTitles}>INTENTOS</h1>,
        dataIndex: "tries",
        key: "tries",
        align: "center",
        width: 100,
        render: (i, _) => {
          return !task?.tries || !_?.tries ? (
            <div className={styles.textStyles}>-</div>
          ) : (
            <div className={styles.tries}>
              {_?.tries?.toString()}/{task?.tries}
            </div>
          );
        },
      },
      {
        title: () => <h1 className={styles.tableTitles}>MEJOR</h1>,
        dataIndex: "high_score",
        key: "high_score",
        align: "center",
        render: (i, _) => {
          return (
            <div className={styles.numberText}>
              {_.high_score ? _.high_score : "0"}
            </div>
          );
        },
      },
    ];

  let columnsGPTMobile: ColumnsType<getHomeworkById_getHomeworkById_students_grades> =
    [
      {
        title: () => <div className={styles.tableTitles}>ALUMNO</div>,
        dataIndex: "student_name",
        key: "student_name",
        sorter: {
          compare: (a, b) =>
            a?.student_name!.localeCompare(b?.student_name!, "es"),
          multiple: 1,
        },
        defaultSortOrder: "ascend",
        sortDirections: ["ascend", "descend", "ascend"],
        width: 400,
        render: (text, _) => {
          return (
            <div className={styles.userContainer}>
              <span
                className={styles.studentAvatar}
                style={{
                  background: `${resolveColorAvatar(
                    _?.student_avatar ?? "polux"
                  )}`,
                }}
              >
                <img
                  src={resolveAvatar(_?.student_avatar ?? "polux", "5")}
                  alt="avatar"
                />
              </span>
              <div className={styles.nameContainer}>
                <h1> {_.student_name}</h1>

                <div
                  className={
                    checkStatus(_) === "No entregada"
                      ? styles.notDelivered
                      : checkStatus(_) === "Completada"
                      ? styles.delivered
                      : styles.inCourse
                  }
                >
                  {checkStatus(_)}
                </div>
              </div>
            </div>
          );
        },
      },
      {
        title: () => <h1 className={styles.tableTitles}>CALIFICACIÓN</h1>,
        dataIndex: "tries",
        key: "tries",
        align: "center",
        width: 100,
        render: (i, _) => {
          return _.tries ? (
            _.high_score === null || _.high_score === undefined ? (
              <div
                className={styles.fixedStyles}
                onClick={(e) => {
                  setVisibleMessage(true);
                  e.stopPropagation();
                }}
              >
                corregir
              </div>
            ) : (_.delivered || _.high_score) &&
              (_.high_score !== null || _.high_score !== undefined) ? (
              _.high_score && _.high_score > 5 ? (
                <div className={styles.tabScoreContainer}>
                  <div
                    className={styles.tableHighScore}
                    onClick={(e) => {
                      setVisibleMessage(true);
                      e.stopPropagation();
                    }}
                  >
                    {_.high_score}
                  </div>
                </div>
              ) : (
                <div className={styles.tabScoreContainer}>
                  <div
                    className={styles.tableLowScore}
                    onClick={(e) => {
                      setVisibleMessage(true);
                      e.stopPropagation();
                    }}
                  >
                    {_.high_score}
                  </div>
                </div>
              )
            ) : (
              <div className={styles.textStyles}>-</div>
            )
          ) : (
            <div className={styles.textStyles}>-</div>
          );
        },
      },
    ];

  const finalStudentsToRender = () => {
    let individualStudents = task?.users?.map((user) =>
      Number(user.id)
    ) as unknown as number[];
    let finalIndividualStudents: any[] = [];
    if (individualHomework) {
      task?.students_grades?.forEach((student) => {
        return individualStudents?.includes(
          student.student_id ? student.student_id : 0
        )
          ? finalIndividualStudents.push(student)
          : undefined;
      });

      return finalIndividualStudents;
    }
    return task?.students_grades !== null ? task?.students_grades : undefined;
  };

  const filterEvidence = (
    e: ColumnsType<getHomeworkById_getHomeworkById_students_grades>
  ) => {
    return e.filter((column) => {
      if (task?.needs_file_evidence) {
        return column;
      } else {
        if (column.key !== "evidence") {
          return column;
        }
      }
    });
  };

  const onRow = (record: any) => {
    return {
      onClick: () => {
        if (
          !(
            (task?.lessons && task.lessons[0].lesson_type === "redaction") ||
            (task?.lessons && task.lessons[0].lesson_type === "conversation")
          )
        ) {
          if (checkStatus(record) === "Completada") {
            setVisibleModalStudentResponse(true);
            setUserIdSetted(record?.student_id);
          }
        } else {
          if (record?.delivered || record?.tries) {
            setUserIdSetted(Number(record?.student_id) ?? null);
            task?.lessons && task.lessons[0].lesson_type === "conversation"
              ? setVisibleDrawerConversation(true)
              : setVisibleDrawer(true);
          }
        }
      },
      style:
        checkStatus(record) !== "No entregada"
          ? { cursor: "pointer" }
          : undefined,
    };
  };

  return (
    <div className={styles.tableUser}>
      {visibleMessage ? (
        <div className={styles.containerMessage}>
          <div
            className={styles.crossMessage}
            onClick={() => {
              setVisibleMessage(false);
            }}
          >
            <img src={cross} alt="cross" />
          </div>
          Accede a la plataforma web para ver las respuestas de tus alumnos a la
          actividad, y realizar las correcciones que sean necesarias.
        </div>
      ) : (
        <Table
          columns={
            isMobile
              ? (task?.lessons &&
                  task.lessons[0].lesson_type === "redaction") ||
                (task?.lessons &&
                  task.lessons[0].lesson_type === "conversation")
                ? columnsGPTMobile
                : columnsMobile
              : task?.lessons && task.lessons[0].lesson_type === "redaction"
              ? filterEvidence(columnsGPT)
              : task?.lessons && task.lessons[0].lesson_type === "conversation"
              ? filterEvidence(columnsConversation)
              : filterEvidence(columns)
          }
          onRow={onRow}
          dataSource={finalStudentsToRender()}
          pagination={false}
        />
      )}
    </div>
  );
};

export default StudentsStatusTable;
