import React, { FC, useEffect, useState } from "react";
import styles from "./resultConversation.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
import useGetHomeworkTriesForFinishedHomeworkAction from "../../../../../../api/useGetHomeworkTriesForFinishedHomeworkAction";
import cross from "../../../../../../assets/images/cross.svg";
import lucaBot from "../../../../../../assets/pages/quiz/lucaBot.png";
import arrow from "../../../../../../assets/icons/arrowReturn.svg";
import blueDiamond from "../../../../../../assets/icons/students/blueDiamond.svg";
import useAuth from "../../../../../../context/useAuth";
import { Progress } from "antd";
import MessageList from "../ChatConversation/MessageList/MessageList";
import { getLessonById_getLessonById } from "../../../../../../__generated__/gql-types/getLessonById";
import { PropsMessage } from "../utils";

interface PropsResultConversation {
  result: any;
  messages: PropsMessage[];
  individualLesson: getLessonById_getLessonById;
  activeSound: boolean;
}
const ResultConversation: FC<PropsResultConversation> = ({
  result,
  messages,
  individualLesson,
  activeSound,
}) => {
  const { userToken } = useAuth();
  const navigate = useNavigate();
  const location: any = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const homeworkParams = queryParams.get("homework");
  const [newStudentTries, setNewStudentTries] = useState<number>();
  let aprobado = (result?.result ?? 1) >= 6;
  const getHomeworkTriesForFinished =
    useGetHomeworkTriesForFinishedHomeworkAction();

  useEffect(() => {
    (async () => {
      if (homeworkParams) {
        const response = await getHomeworkTriesForFinished({
          homeworkId: homeworkParams,
        });
        (response || response === 0) && setNewStudentTries(response);
      }
    })();
  }, [getHomeworkTriesForFinished, homeworkParams]);

  const handleRedirection = () => {
    return navigate(-1);
  };
  return (
    <div className={styles.container}>
      {newStudentTries !== 0 ||
      userToken?.role === "teacher" ||
      userToken?.role === "coordinator" ? (
        <div className={styles.section}>
          <a className={styles.try} href={`${window.location.href}`}>
            <img src={arrow} alt="" />
            <h1> INTENTAR DE NUEVO</h1>
          </a>
          <img
            src={cross}
            alt=""
            style={{ cursor: "pointer" }}
            onClick={() => handleRedirection()}
          />
        </div>
      ) : (
        <div className={styles.section}>
          <img
            src={cross}
            alt=""
            style={{ cursor: "pointer" }}
            onClick={() => handleRedirection()}
          />
        </div>
      )}

      <div className={styles.content}>
        <div className={styles.center}>
          <img src={lucaBot} alt="aprobado" className={styles.imageAprobado} />

          <div className={styles.nota}>
            {aprobado ? "¡Muy bien! ¡Misión completada!" : "MISIÓN FINALIZADA"}
          </div>
          <div className={styles.progress}>
            <Progress
              percent={(result?.result ?? 1) * 10}
              showInfo={false}
              trailColor={"#CBCEDE"}
              strokeColor={aprobado ? "#3843D0" : "#E33B57"}
              className="progressFinishLesson"
            />
            <p
              style={{
                color: result?.result === 10 ? "white" : "#474747",
              }}
            >
              {result?.result}/10
            </p>
          </div>
          {result?.maxResultHomework && (
            <div className={styles.subNota}>
              Tu mejor puntuación anterior fue de {result?.maxResultHomework}
              /10.
            </div>
          )}
        </div>
        <div className={styles.containerFooter}>
          {(userToken?.role === "teacher" ||
            userToken?.role === "coordinator") && (
            <div className={styles.firstBox}>
              {result?.maxResultHomework ? (
                result?.maxResultHomework < result.result ? (
                  <>
                    <div>Conseguiste </div>
                    <div>
                      <img
                        src={blueDiamond}
                        alt="zafire"
                        className={styles.imageGem}
                      />
                      <span className={styles.textPoints}>
                        + {(result?.result - result?.maxResultHomework) * 10}{" "}
                      </span>
                    </div>
                  </>
                ) : (
                  <>
                    <div>No conseguiste más cristales </div>
                    <div className={styles.secondColumn}>
                      <img
                        src={blueDiamond}
                        alt="zafire"
                        className={styles.imageGem}
                      />
                      <span className={styles.textPoints}>0</span>
                    </div>
                  </>
                )
              ) : (
                <>
                  <div>Conseguiste </div>
                  <div>
                    <img
                      src={blueDiamond}
                      alt="zafire"
                      className={styles.imageGem}
                    />
                    <span className={styles.textPoints}>
                      {result?.result ? "+" : ""}{" "}
                      {result?.result ? result?.result * 10 : 0}
                    </span>
                  </div>
                </>
              )}
            </div>
          )}
          <MessageList
            isTyping={false}
            readOnly={true}
            activeSound={activeSound}
            individualLesson={individualLesson}
            messages={messages}
          />
        </div>
      </div>
    </div>
  );
};

export default ResultConversation;
