import React, { useEffect, useState } from "react";
import useAuth from "../../../context/useAuth";
import { Collapse, Select } from "antd";
import { Loader } from "../../Commons";
import styles from "./dosification.module.scss";
import comingSoon from "../../../assets/images/comingSoonImage.png";
import UseGetLessonsByCourse from "../../../api/useGetLessonByCourse";
import { getLessonsByCourse_getLessonsByCourse } from "../../../__generated__/gql-types/getLessonsByCourse";
import { CardDosification } from "./CardDosification";
import { GetCoursesForNewContent_getCoursesForNewContent } from "../../../__generated__/gql-types/GetCoursesForNewContent";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useLocation } from "react-router-dom";

interface DosificationsProps {
  grade_id?: number;
  trimester: number;
  setLessonId: (id: string) => void;
  setSelectAcordion: (id: string) => void;
  setIndividualLesson?: (item: any) => void;
  showDrawer: () => void;
  setTrimester: React.Dispatch<React.SetStateAction<number>>;
  setGrade: (e: number) => void;
  coursesState: GetCoursesForNewContent_getCoursesForNewContent[];
  loadingDosification: boolean;
  selectAcordion: string;
  isDemo: boolean;
}

const { Panel } = Collapse;

export const Dosifications = ({
  grade_id,
  loadingDosification,
  coursesState,
  trimester,
  selectAcordion,
  setLessonId,
  setSelectAcordion,
  setIndividualLesson,
  showDrawer,
  setTrimester,
  setGrade,
  isDemo,
}: DosificationsProps) => {
  const getLessonsByCourse = UseGetLessonsByCourse();
  const [CourseLessons, setCourseLessons] = useState<
    getLessonsByCourse_getLessonsByCourse[] | null
  >(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const courseParams = queryParams.get("course");

  const [loading, setLoading] = useState(false);
  const handleChangeGrade = (values: string[]) => {
    setGrade(Number(values[0]));
  };
  const { userToken } = useAuth();
  const handleChangeTrimester = (trimesterSelected: number) => {
    setTrimester(trimesterSelected);
  };
  const flags = useFlags();
  const onChange = async (key: string | string[]) => {
    try {
      let lessonListening: getLessonsByCourse_getLessonsByCourse[] = [];
      let lessonVideo: getLessonsByCourse_getLessonsByCourse[] = [];
      let lessonSlide: getLessonsByCourse_getLessonsByCourse[] = [];
      let lessonGuion: getLessonsByCourse_getLessonsByCourse[] = [];
      let lessonEvaluate: getLessonsByCourse_getLessonsByCourse[] = [];
      let lessonBookPage: getLessonsByCourse_getLessonsByCourse[] = [];
      let lessonLiteracy: getLessonsByCourse_getLessonsByCourse[] = [];
      let lessonRedaccion: getLessonsByCourse_getLessonsByCourse[] = [];
      let lessonAudio: getLessonsByCourse_getLessonsByCourse[] = [];
      let lessonAIConversation: getLessonsByCourse_getLessonsByCourse[] = [];
      let lessonWritingExam: getLessonsByCourse_getLessonsByCourse[] = [];
      let lessonAudioExam: getLessonsByCourse_getLessonsByCourse[] = [];
      setSelectAcordion(key[0]);
      setLoading(true);

      const lessons = await getLessonsByCourse(Number(key[0]));
      if (lessons) {
        for (let index = 0; index < lessons.length; index++) {
          const typeLesson = lessons[index].lesson_type;
          if (typeLesson === "videolesson") {
            lessonVideo.push(lessons[index]);
          }
          if (typeLesson === "slides") {
            lessonSlide.push(lessons[index]);
          }
          if (typeLesson === "classroomguide") {
            lessonGuion.push(lessons[index]);
          }
          if (typeLesson === "redaction") {
            lessonRedaccion.push(lessons[index]);
          }
          if (typeLesson === "book_pages") {
            lessonBookPage.push(lessons[index]);
          }
          if (typeLesson === "evaluation") {
            lessonEvaluate.push(lessons[index]);
          }
          if (typeLesson === "literacy") {
            lessonLiteracy.push(lessons[index]);
          }
          if (
            typeLesson === "listening" &&
            (userToken?.role === "teacher" || userToken?.role === "coordinator")
          ) {
            lessonListening.push(lessons[index]);
          }
          if (typeLesson === "audio" && flags.moduloListening) {
            lessonAudio.push(lessons[index]);
          }
          if (typeLesson === "conversation") {
            lessonAIConversation.push(lessons[index]);
          }
          if (typeLesson === "writing_exam") {
            lessonWritingExam.push(lessons[index]);
          }
          if (typeLesson === "audio_exam") {
            lessonAudioExam.push(lessons[index]);
          }
        }
        const lessonOrder = lessonGuion
          .concat(lessonListening)
          .concat(lessonLiteracy)
          .concat(lessonVideo)
          .concat(lessonSlide)
          .concat(lessonBookPage)
          .concat(lessonRedaccion)
          .concat(lessonAudio)
          .concat(lessonAIConversation)
          .concat(lessonEvaluate)
          .concat(lessonWritingExam)
          .concat(lessonAudioExam);
        setCourseLessons(lessonOrder);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    (async () => {
      if (courseParams) {
        const response = onChange([courseParams]);
      }
    })();
  }, []);

  return (
    <>
      <div className={styles.tableContainer}>
        <Collapse
          defaultActiveKey={selectAcordion}
          accordion
          bordered={false}
          className={styles.collapseContainerNem}
          onChange={onChange}
        >
          <div className={styles.rowContainer}>
            <Select
              disabled={
                userToken?.schools[0].name === "Colegio Iluminación" ||
                userToken?.schools[0].name === "COLEGIO PROGRESO"
                  ? true
                  : false
              }
              defaultValue={grade_id ? [grade_id!.toString()] : ["6"]}
              className={styles.selectGradeNem}
              onChange={handleChangeGrade}
              options={[
                { value: "1", label: "Level 1" },
                { value: "2", label: "Level 2" },
                { value: "3", label: "Level 3" },
                { value: "4", label: "Level 4" },
                { value: "5", label: "Level 5" },
                { value: "6", label: "Level 6" },
              ]}
            />
            <div className={styles.containerTrimester}>
              <div
                className={
                  trimester === 1
                    ? styles.textTrimester
                    : styles.textTrimesterNotUnderline
                }
                onClick={() => handleChangeTrimester(1)}
              >
                1st trimester
              </div>
              <div
                className={
                  trimester === 2
                    ? styles.textTrimester
                    : styles.textTrimesterNotUnderline
                }
                onClick={() => handleChangeTrimester(2)}
              >
                2nd trimester
              </div>
              <div
                className={
                  trimester === 3
                    ? styles.textTrimester
                    : styles.textTrimesterNotUnderline
                }
                onClick={() => handleChangeTrimester(3)}
              >
                3rd trimester
              </div>
            </div>
            <div className={styles.containerTrimesterMobile}>
              <div
                className={
                  trimester === 1
                    ? styles.textTrimester
                    : styles.textTrimesterNotUnderline
                }
                onClick={() => handleChangeTrimester(1)}
              >
                1st trimester
              </div>
              <div
                className={
                  trimester === 2
                    ? styles.textTrimester
                    : styles.textTrimesterNotUnderline
                }
                onClick={() => handleChangeTrimester(2)}
              >
                2nd trimester
              </div>
              <div
                className={
                  trimester === 3
                    ? styles.textTrimester
                    : styles.textTrimesterNotUnderline
                }
                onClick={() => handleChangeTrimester(3)}
              >
                3rd trimester
              </div>
            </div>
          </div>
          {coursesState.length > 0 && trimester !== 3 && (
            <div className={styles.tableHeaderContainer}>
              <p className={styles.courseHeader}>Course</p>
              <p className={styles.weekHeader}>Week</p>
              <p className={styles.pagesHeader}>Pages</p>
            </div>
          )}
          {loadingDosification ? (
            <Loader />
          ) : (
            coursesState.map((course) => {
              return (
                <Panel
                  key={course.id}
                  showArrow={false}
                  header={
                    <>
                      <div className={styles.panelContainer}>
                        <div className={styles.panelTitle}>{course.title}</div>
                        <div className={styles.panelSemana}>
                          week {course.week_english}
                        </div>
                        <div className={styles.panelSepBookPages}>
                          {course.sep_book_pages_english
                            ? course.sep_book_pages_english
                            : course.sep_book_pages}
                        </div>
                      </div>
                      <div className={styles.panelContainerMobile}>
                        <div className={styles.containerPanelText}>
                          <div>week {course.week_english}</div>
                          <div>
                            pages{" "}
                            {course.sep_book_pages_english
                              ? course.sep_book_pages_english
                              : course.sep_book_pages}
                          </div>
                        </div>
                        <div
                          className={
                            course.id === selectAcordion
                              ? styles.panelTitleSelect
                              : styles.panelTitle
                          }
                        >
                          {course.title}
                        </div>
                      </div>
                    </>
                  }
                  style={{
                    background: "#F3F3F6",
                    marginBottom: 10,
                    borderRadius: 12,
                    backgroundColor: "#F3F3F6",
                    fontStyle: "normal",
                    width: "auto",
                    fontWeight: "600",
                    fontSize: "14px",
                    lineHeight: "140%",
                    boxSizing: "border-box",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#F3F3F6",
                      marginRight: -16,
                      borderRadius: 12,
                    }}
                  >
                    <div
                      className={
                        CourseLessons && CourseLessons.length > 3
                          ? styles.cardsContainer
                          : styles.cardsContainerWithoutScroll
                      }
                      style={{
                        backgroundColor: "#F3F3F6",
                        width: "100%",
                      }}
                    >
                      {loading ? (
                        <Loader />
                      ) : (
                        CourseLessons?.map((lesson, index) => {
                          return (
                            <CardDosification
                              lesson={lesson}
                              index={index}
                              showDrawer={showDrawer}
                              setLessonId={setLessonId}
                              key={lesson?.id + lesson?.title}
                              setIndividualLesson={setIndividualLesson}
                            />
                          );
                        })
                      )}
                    </div>
                  </div>
                </Panel>
              );
            })
          )}
        </Collapse>
      </div>
    </>
  );
};
