import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import { Loader } from "../../../../Commons";
import { getLessonById_getLessonById } from "../../../../../__generated__/gql-types/getLessonById";
import useAuth from "../../../../../context/useAuth";

interface PropsWatchVideo {
  visible: boolean;
  setVisible: (e: boolean) => void;
  play: boolean;
  setPlay: (e: boolean) => void;
  setVideoContinuar: (e: boolean) => void;
  individualLesson: getLessonById_getLessonById;
  qualitySelected: number;
  seconds: number;
  setSeconds: React.Dispatch<React.SetStateAction<number>>;
}

const NewWatchVideo = ({
  play,
  visible,
  seconds,
  individualLesson,
  qualitySelected,
  setPlay,
  setSeconds,
  setVisible,
  setVideoContinuar,
}: PropsWatchVideo) => {
  const { userToken } = useAuth();
  const playerRef = useRef<ReactPlayer | null>(null);
  const fullscreenRef = useRef(false);
  const [finish, setFinish] = useState(0);
  const activeControls =
    userToken?.role === "teacher" ||
    userToken?.role === "coordinator" ||
    window.location.href?.includes("frontend") ||
    window.location.href?.includes("localhost");

  const [contTimeOut, setContTimeOut] = useState<number>(0);
  const [loading, setLoading] = useState(true);

  const handlePause = () => {
    setPlay(!play);
  };

  useEffect(() => {
    // Listener de fullscreen en diferentes browsers
    document.addEventListener("fullscreenchange", handleFullscreenChange);
    document.addEventListener("webkitfullscreenchange", handleFullscreenChange);
    document.addEventListener("mozfullscreenchange", handleFullscreenChange);
    document.addEventListener("msfullscreenchange", handleFullscreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
      document.removeEventListener(
        "webkitfullscreenchange",
        handleFullscreenChange
      );
      document.removeEventListener(
        "mozfullscreenchange",
        handleFullscreenChange
      );
      document.removeEventListener(
        "msfullscreenchange",
        handleFullscreenChange
      );
    };
  }, []);

  const handleFullscreenChange = () => {
    fullscreenRef.current = !!(
      document.fullscreenElement ||
      (document as any).webkitFullscreenElement ||
      (document as any).mozFullScreenElement ||
      (document as any).msFullscreenElement
    );
  };

  //funcion HORRIBLE para salir del fullscreen

  const exitFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if ((document as any).webkitExitFullscreen) {
      (document as any).webkitExitFullscreen();
    } else if ((document as any).mozCancelFullScreen) {
      (document as any).mozCancelFullScreen();
    } else if ((document as any).msExitFullscreen) {
      (document as any).msExitFullscreen();
    }
  };
  const handlePlayerReady = () => {
    setLoading(false);
  };
  const [urlVideo, setUrlVideo] = useState("");

  const generateUrlVideo = (url: string, quality: number) => {
    if (quality !== 1080) return `${url.split(".mp")[0]}_${quality}.mp4`;
    return url;
  };

  const handleProgress = (secs: number) => {
    const timeVideo = (finish ?? 0) * 0.9;
    setSeconds(secs);
    if (timeVideo < secs) {
      setVideoContinuar && setVideoContinuar(true);
    } else {
      setVideoContinuar && setVideoContinuar(false);
    }

    if (
      individualLesson!.lesson_content!.videos![0].quizzes!.length! >
        contTimeOut &&
      Math.trunc(secs) ===
        individualLesson!.lesson_content!.videos![0].quizzes![contTimeOut]
          .time &&
      !visible
    ) {
      exitFullscreen();
      setVisible && setVisible(true);
    }
    if (
      individualLesson!.lesson_content!.videos![0].quizzes!.length! >
        contTimeOut &&
      Math.trunc(secs) ===
        individualLesson!.lesson_content!.videos![0].quizzes![contTimeOut]
          .time_out
    ) {
      setContTimeOut && setContTimeOut(contTimeOut + 1);
      if (visible) {
        setPlay && setPlay(false);
      }
    }
  };

  const handleSeek = (seconds: number) => {
    playerRef &&
      playerRef?.current &&
      playerRef.current.seekTo(seconds, "seconds");
  };

  useEffect(() => {
    if (
      individualLesson &&
      individualLesson?.lesson_content &&
      individualLesson?.lesson_content.videos &&
      individualLesson?.lesson_content.videos[0] &&
      individualLesson?.lesson_content.videos[0].url
    ) {
      const updatedUrl = generateUrlVideo(
        individualLesson.lesson_content.videos[0].url,
        qualitySelected
      );
      setUrlVideo(updatedUrl);
      setTimeout(() => {
        handleSeek(seconds);
      }, 10);
    }
  }, [qualitySelected]);

  return (
    <div
      onClick={() => {
        !activeControls && handlePause();
      }}
    >
      {loading && !activeControls && <Loader></Loader>}
      <ReactPlayer
        ref={playerRef}
        controls={activeControls}
        onDuration={(e) => setFinish && setFinish(e)}
        playing={play}
        onReady={handlePlayerReady}
        style={
          !play && !activeControls
            ? {
                opacity: 0.4,
              }
            : {
                minWidth: "70vw",
                minHeight: "70vh",
              }
        }
        url={urlVideo ?? ""}
        onProgress={(e) => handleProgress(e.playedSeconds)}
        progressInterval={1000}
        playbackRate={1}
        width="100%"
        height="100%"
        config={{
          file: {
            attributes: {
              playsInline: true,
              controlsList: "nodownload",
              disableRemotePlayback: true,
              disablePictureInPicture: true,
            },
          },
        }}
      />
    </div>
  );
};

export default NewWatchVideo;
