import { getLessonById_getLessonById_lesson_content_quizzes_questions } from "../../../../__generated__/gql-types/getLessonById";

export interface PropsOrderFrase {
  setStateColumns: (e: InitialData) => void;
  stateColumns: InitialData | null;
  stateNext: boolean;
  alertFalse: boolean;
  alertTrue: boolean;
  content: getLessonById_getLessonById_lesson_content_quizzes_questions;
  subject: number | null;
}
// Define types
export interface Task {
  id: string;
  content: string;
  order: number;
  idTask: string;
}

export interface Column {
  id: string;
  title: string;
  taskIds: string[];
}

export interface InitialData {
  tasks: Record<string, Task>;
  columns: Record<string, Column>;
  columnOrder: string[];
}

export const compareArrays = (arr1: Task[], arr2: Task[]) => {
  if (arr1.length !== arr2.length) return false;
  for (let i = 0; i < arr1.length; i++) {
    if (
      arr1[i].id !== arr2[i].id ||
      arr1[i].order !== arr2[i].order ||
      arr1[i].content !== arr2[i].content
    ) {
      return false;
    }
  }
  return true;
};
