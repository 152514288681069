import React from "react";
import styles from "./screenIsVertical.module.scss";
import turnMobile from "../../../../../assets/pages/quiz/isVertical/turnMobile.png";
import disbloqued from "../../../../../assets/pages/quiz/isVertical/disbloqued.png";
import littleTurnMobile from "../../../../../assets/pages/quiz/isVertical/littleTurnMobile.png";
const ScreenIsVertical = () => {
  return (
    <div className={styles.container}>
      <div className={styles.containerTurnMobile}>
        <div className={styles.textTurnMobile}>
          Gira tu celular para poder realizar esta misión
        </div>
        <img src={turnMobile} alt="turnMobile" />
      </div>

      <div className={styles.containerText}>
        <div className={styles.title}>
          Recuerda activar el giro en los ajustes de tu celular{" "}
        </div>
        <div className={styles.description}>
          para que pueda mostrarse la vista horizontal de las misiones.{" "}
        </div>
      </div>
      <div className={styles.containerImg}>
        <img src={disbloqued} alt="disbloqued" />
        <div className={styles.imgText}>Dispositivo Apple</div>
      </div>
      <div className={styles.containerImg}>
        <img src={littleTurnMobile} alt="disbloqued" />
        <div className={styles.imgText}>Dispositivo Android</div>
      </div>
    </div>
  );
};

export default ScreenIsVertical;
