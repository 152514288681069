import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";

interface EditorComponentProps {
  text: string;
  onContentChange?: (content: string) => void;
  readOnly?: boolean;
}

const EditorComponent = ({
  text,
  onContentChange,
  readOnly = false,
}: EditorComponentProps) => {
  const [editorState, setEditorState] = useState<EditorState>();

  const onEditorStateChange = (editorState: EditorState) => {
    setEditorState(editorState);
    const content = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    onContentChange && onContentChange(content);
  };

  useEffect(() => {
    setEditorState(() => {
      if (text) {
        const contentBlock = htmlToDraft(text);
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        return EditorState.createWithContent(contentState);
      } else {
        return EditorState.createEmpty();
      }
    });
  }, [text]);

  return (
    <Editor
      editorState={editorState}
      readOnly={readOnly}
      placeholder="Escribe aquí tu respuesta"
      wrapperStyle={{ height: "calc(100% - 36px)" }}
      editorStyle={{
        backgroundColor: "white",
        padding: 10,
        height: "100%",
        borderRadius: "20px 20px 0px 0px",
        overflowY: "auto",
      }}
      wrapperClassName="wrapper-class"
      editorClassName="editor-class"
      toolbarClassName="toolbar-class"
      toolbarHidden={readOnly}
      localization={{
        locale: "es",
      }}
      onEditorStateChange={onEditorStateChange}
    />
  );
};

export default EditorComponent;
